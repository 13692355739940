import React, { ComponentType, ReactNode } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { Backdrop, Fade, Modal } from '@material-ui/core';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import CloseButton from 'src/components/buttons/CloseButton/CloseButton';
import clsx from 'clsx';
import 'src/components/modals/BaseModal/BaseModal.css';

export interface BaseModalProps extends FunctionComponentProps {
    CloseButtonComponent?: ComponentType<CloseButtonComponentProps>;
    ConfirmButtonComponent?: ComponentType<ConfirmButtonComponentProps>;
    description: ReactNode;
    isOpen: boolean;
    title: string;
    onClose: () => void;
    onConfirm?: () => void;
    className?: string;
}

interface CloseButtonComponentProps extends FunctionComponentProps {
    className?: string;
    onClick: () => void;
}

export interface ConfirmButtonComponentProps extends FunctionComponentProps {
    onClick: () => void;
}

function BaseModal(
    {
        className,
        CloseButtonComponent = CloseButton,
        ConfirmButtonComponent,
        description,
        isOpen,
        title,
        onClose,
        onConfirm,
        children
    }: BaseModalProps
): FunctionComponentReturnType {
    return (
        <Modal
            className={clsx(className, 'base-modal')}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={isOpen}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={isOpen}>
                <div className="modal-body grv-padding--small">
                    {
                        title && 
                        <h2 id="modal-title" className="modal-title">
                            {title}
                        </h2>
                    }

                    {
                        description &&
                        <div id="modal-description" className="modal-description">
                        {
                            (typeof description) === 'string' || (typeof description) === 'number' ?
                                <p>{description}</p> :
                                description
                        }
                        </div>
                    }

                    {children}

                    <ActionTray className="modal-action-tray">
                        <CloseButtonComponent
                            className={clsx(ConfirmButtonComponent && 'grv-margin__right--tiny')}
                            onClick={onClose}
                        />
                        {
                            ConfirmButtonComponent && onConfirm &&
                            <ConfirmButtonComponent onClick={onConfirm}/>
                        }
                    </ActionTray>
                </div>
            </Fade>
        </Modal>
    );
}

export default BaseModal;
