import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import LinkedButton from 'src/components/buttons/LinkedButton/LinkedButton';
import DeleteButton from 'src/components/buttons/DeleteButton/DeleteButton';
import 'src/components/tables/AdminTableActionTray/AdminTableActionTray.css';

export interface AdminTableActionTrayProps extends FunctionComponentProps {
    editButtonLinkTo: string;
    onDelete: () => void;
    disableEdit?: boolean;
}

function AdminTableActionTray(
    {
        editButtonLinkTo,
        onDelete,
        disableEdit
    }: AdminTableActionTrayProps
): FunctionComponentReturnType {

    return (
        <ActionTray className="admin-table-action-tray">
            {
                disableEdit ?
                    <></> 
                : 
                    <LinkedButton
                        linkTo={editButtonLinkTo}
                        buttonType={['progressive', 'compact']}
                        marginSize="tiny"
                    >
                        {'Edit'}
                    </LinkedButton> 
            }
            <DeleteButton
                onClick={onDelete}
                marginSize="tiny"
            />
        </ActionTray>
    );
}

export default AdminTableActionTray;
