import { Button, Datepicker } from '@c1/gravity-react';
import { useCallback, useEffect, useState } from 'react';
import Tile from 'src/containers/Tile/Tile';
import { WalmartDispute } from 'src/types/formRequest';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { CLIENT_PATHS } from 'src/utils/routing/routes';
import DropdownSelect from '../formElements/DropdownSelect/DropdownSelect';
import Form from '../formElements/Form/Form';
import FormActionTray from '../formElements/FormActionTray/FormActionTray';
import TextInput from '../formElements/TextInput/TextInput';
import 'src/components/WalmartDisputesForm/WalmartDisputesForm.css';
import axios, { AxiosResponse } from 'axios';
import { navigate } from '@reach/router';
import _ from 'lodash';
import WalmartDisputesFormSection from './WalmartDisputesFormSection';
import { futureDateUnavailable } from 'src/utils/datetime';

interface WalmartDisputesFormProps extends FunctionComponentProps {
    disputes: WalmartDispute[];
    dispute?: WalmartDispute;
    readOnly?: boolean;
}

/* Temporary hardcode selections */
export enum MessageType {
    'Claim Request' = 'claim-request',
    'Outcome Chargeback' = 'outcome-chargeback',
}

export enum Bin {
    WalmartCommercial = '630220',
}

function WalmartDisputesForm({ dispute, readOnly = false }: WalmartDisputesFormProps): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [formSubmissionInProgress, setFormSubmissionInProgress] = useState<boolean>(false);
    const [messageType, setMessageType] = useState<string | null>(dispute?.messageType ?? null);
    const [customerId, setCustomerId] = useState<string | null>(dispute?.customerId ?? null);
    const [bin, setBin] = useState<string | null>(dispute?.bin ?? null);
    const [messageDate, setMessageDate] = useState<number | null>(dispute?.messageDate ?? null);

    // Some field like chargebackReferenceId and acquirerClaimId only required for message type Outcome Chargeback
    const [isOutcomeChargeback, setIsOutcomeChargeback] = useState<boolean>(dispute?.messageType === MessageType['Outcome Chargeback'] ?? false);
    useEffect(() => {
        setIsOutcomeChargeback(messageType === MessageType['Outcome Chargeback']);
    }, [messageType]);

    const submitButtonText = dispute ? 'Edit Record' : 'Add Record';

    const [walmartDisputes, setWalmartDisputes] = useState<Partial<WalmartDispute>[]>([dispute ?? newDispute()]);
    const [formSubmissionAttempt, setFormSubmissionAttempt] = useState<boolean>(false);

    // Clear the Outcome Chargeback fields if not outcome chargeback message type
    useEffect(() => {
        if (!isOutcomeChargeback) {
            walmartDisputes.forEach(dispute => {
                delete dispute.acquirerClaimId;
                delete dispute.chargebackReferenceId;
            });
        }
    }, [isOutcomeChargeback, walmartDisputes]);

    // TODO: Handle submit only after dispute state updated. Should not call API if required fields not filled

    async function handleSubmit(): Promise<void> {
        const trimmedMessageType = messageType?.trim();
        const trimmedBin = bin?.trim();
        const trimmedCustomerId = customerId?.trim();

        setFormSubmissionAttempt(true);

        if (
            !messageDate ||
            !trimmedMessageType ||
            !trimmedBin ||
            !trimmedCustomerId
        ) {
            setMessageType(trimmedMessageType || '');
            setBin(trimmedBin || '');
            setCustomerId(trimmedCustomerId || '');
            setFormSubmissionAttempt(false);
            return; // Abort submission
        }

        setFormSubmissionInProgress(true);

        const upsert: Promise<AxiosResponse> = dispute ?
            axios.put(routeBuilder.api.editWalmartDispute(),
                {
                    ...walmartDisputes.pop(),
                    messageType,
                    customerId,
                    bin,
                    messageDate
                } as WalmartDispute
            ) :
            axios.post(routeBuilder.api.createWalmartDispute(),
                {
                    disputes: walmartDisputes.map(dispute => (
                        {
                            messageType,
                            customerId,
                            bin,
                            messageDate,
                            ...dispute
                        }  as WalmartDispute
                    ))
                }
            );

        await upsert
            .then(() => navigate(CLIENT_PATHS.WALMART_DISPUTE_FORM_PAGE))
            .catch(console.error)
            .finally(() => {
                setFormSubmissionInProgress(false);
                setFormSubmissionAttempt(false);
            });
    }

    function newDispute(): Partial<WalmartDispute> {
        return {}
    }

    function deleteDispute(id: number): void {
        setWalmartDisputes(walmartDisputes.filter((_, i) => i !== id));
    }

    function updateDispute(id: number, dispute: Partial<WalmartDispute>): void {
        const disputes = [...walmartDisputes];
        disputes[id] = dispute;
        setWalmartDisputes(disputes);
    }

    return (
        <Tile>
            {
                readOnly ? null :
                    <b className="walmart-dispute-message-title">All fields are required</b>
            }
            <Form className="walmart-disputes-form" onSubmit={handleSubmit} name={_.camelCase(submitButtonText)}>
                <DropdownSelect
                    label="Message Type"
                    options={Object.entries(MessageType).map(([label, value]) => ({ label, value }))}
                    value={messageType}
                    onChange={setMessageType}
                    required
                />
                <TextInput
                    label="Customer ID"
                    value={customerId}
                    onChange={setCustomerId}
                    pattern="\d+"
                    tooltip="Customer ID [from NuBITS Transaction Viewer]"
                    readOnly={readOnly}
                    required
                />
                <DropdownSelect
                    label="BIN"
                    options={Object.values(Bin).map(b => ({ label: b, value: b }))}
                    value={bin}
                    onChange={setBin}
                    required
                />
                <Datepicker
                    label="Message Date"
                    value={messageDate ? new Date(messageDate * 1000) : null}
                    unavailable={futureDateUnavailable}
                    onSelect={(date: Date) => setMessageDate(date.getTime() / 1000)} // Need to handle this in parseDate util
                    required
                />
                {
                    walmartDisputes.map((dispute, id) =>
                        <WalmartDisputesFormSection
                            key={id}
                            disputeId={id}
                            dispute={dispute}
                            readOnly={readOnly}
                            multipleDisputes={walmartDisputes.length > 1}
                            isOutcomeChargeback={isOutcomeChargeback}
                            setDispute={d => updateDispute(id, d)}
                            deleteDispute={id => deleteDispute(id)}
                            onSubmit={formSubmissionAttempt}
                        />
                    )
                }
                {
                    dispute ? null :
                        <div className="another-dispute-button">
                        <Button
                                type="text-directional-right"
                                id={'copp-button-another-dispute'}
                                onClick={() => setWalmartDisputes([...walmartDisputes, newDispute()])}
                                compact
                            >
                                Another Dispute
                            </Button>
                        </div>
                }
                {
                    readOnly ? null :
                        <FormActionTray
                            cancelButtonLinkTo={CLIENT_PATHS.WALMART_DISPUTE_FORM_PAGE}
                            submitButtonText={submitButtonText}
                            submitButtonLoading={formSubmissionInProgress}
                        />
                }
            </Form>
        </Tile>
    );
}

export default WalmartDisputesForm;
