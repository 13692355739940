import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { ReactNode } from 'react';
import { HasNameAndCode } from 'src/types/shared';
import 'src/components/ReportViewer/ReportViewerTable.css';

interface ReportViewerTableProps extends FunctionComponentProps {
    id: string;
    data: string[][];
    reportColumns: HasNameAndCode<number>[];
}

function ReportViewerTable({ id, data, reportColumns }: ReportViewerTableProps): FunctionComponentReturnType {
    return (
        <div id={id} className="report-viewer-table-wrapper">
            <table className="grv-table report-viewer-table">
                <thead className="grv-table__head">
                <tr className="grv-table__row">
                    <th className="grv-table__header" scope="col"/>
                    {
                        reportColumns
                            .map((reportColumn: HasNameAndCode<number>): ReactNode => (
                                <th
                                    key={reportColumn.code}
                                    className="grv-table__header"
                                    scope="col"
                                >
                                    {reportColumn.name}
                                </th>
                            ))
                    }
                </tr>
                </thead>
                <tbody className="grv-table__body">
                {
                    data
                        .map((reportRow: string[], i: number): ReactNode => (
                            <tr key={i} className="grv-table__row">
                                <th className="grv-table__header grv-table__header--center-align" scope="row">
                                    {i + 1}
                                </th>
                                {
                                    reportColumns
                                        .map((reportColumn: HasNameAndCode<number>): ReactNode => (
                                            <td
                                                key={reportColumn.code}
                                                className="grv-table__cell"
                                            >
                                                {reportRow[reportColumn.code]}
                                            </td>
                                        ))
                                }
                            </tr>
                        ))
                }
                </tbody>
            </table>
        </div>
    );
}

export default ReportViewerTable;
