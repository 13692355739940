import React, { useEffect, useState } from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import ReportAnIssueForm from 'src/components/ReportAnIssueForm/ReportAnIssueForm';
import { GetReportableIssuesResponse } from 'src/types/apiResponse';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { useAuthenticationContext } from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';
import { AuthenticatedUser } from 'src/utils/AuthenticatedUser';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';

function ReportAnIssuePage(): FunctionComponentReturnType {
    const user: AuthenticatedUser | null = useAuthenticationContext().user;
    const [data, setData] = useState<GetReportableIssuesResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getReportableIssues();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    return data && <ReportAnIssueForm
        reportableIssues={data.reasons}
        user={user}
    />;
}

export default ReportAnIssuePage;
