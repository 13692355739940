import React from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import 'src/pages/NotFoundPage/NotFoundPage.css';

function UnderConstructionPage(): FunctionComponentReturnType {
    return (
      <div className="under-construction-page">
        <h2>{'Page Under Construction'}</h2>
      </div>
  );
}

export default UnderConstructionPage;
