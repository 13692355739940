import { Datepicker, Divider, Icon } from '@c1/gravity-react';
import { useCallback, useEffect, useState } from 'react';
import { WalmartDispute } from 'src/types/formRequest';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { futureDateUnavailable } from 'src/utils/datetime';
import DropdownSelect from '../formElements/DropdownSelect/DropdownSelect';
import TextInput from '../formElements/TextInput/TextInput';

interface WalmartDisputesFormSectionProps extends FunctionComponentProps {
    disputeId: number;
    dispute: Partial<WalmartDispute>;
    readOnly: boolean;
    multipleDisputes: boolean;
    isOutcomeChargeback: boolean;
    setDispute: (dispute: Partial<WalmartDispute>) => void;
    deleteDispute: (disputeId: number) => void;
    onSubmit: boolean;
}

export enum DisputeReason {
    'No cardholder authorization' = 'no-cardholder-authorization',
    'Fraudulent processing of transactions' = 'fraudulent-processing-of-transactions',
    'Questionable merchant activity' = 'questionable-merchant-activity',
    'Cardholder does not recognize potential fraud' = 'cardholder-does-not-recognize-potential-fraud',
    'Chip liability shift' = 'chip-liability-shift',
    'Chip PIN liability shift' = 'chip-pin-liability-shift',
    'Warning bulletin file' = 'warning-bulletin-file',
    'Authorization-related chargeback' = 'authorization-related-chargeback',
    'Account number not on file' = 'account-number-not-on-file',
    'Point of interaction error' = 'point-of-interaction-error',
    'Transaction amount differs' = 'transaction-amount-differs',
    'Late presentment' = 'late-presentment',
    'Correct transaction currency code not provided' = 'correct-transaction-currency-code-not-provided',
    'Installment billing dispute' = 'installment-billing-dispute',
    'Domestic chargeback dispute' = 'domestic-chargeback-dispute'
}

function WalmartDisputesFormSection({
    disputeId,
    dispute,
    readOnly,
    multipleDisputes,
    isOutcomeChargeback,
    setDispute,
    deleteDispute,
    onSubmit
}: WalmartDisputesFormSectionProps): FunctionComponentReturnType {
    const [disputeAmount, setDisputeAmount] = useState<string | null>(dispute.disputeAmount?.toFixed(2) ?? null);
    const [transactionAmount, setTransactionAmount] = useState<string | null>(dispute.transactionAmount?.toFixed(2) ?? null);

    useEffect(() => {
        if (onSubmit) {
            handleSubmit() // Probably another hook that can achieve this
        }
    }, [onSubmit]);

    function handleSubmit() {
        // Check that all required fields are filled
        // Fill any null required fields with empty string
        setDispute({
            ...dispute,
            disputeReasonDesc: dispute.disputeReasonDesc || '',
            lastFourCardNum: dispute.lastFourCardNum || '',
            issuerClaimId: dispute.issuerClaimId || '',
            issuerClaimTransactionId: dispute.issuerClaimTransactionId || '',
            merchantId: dispute.merchantId || '',
            acquirerRefId: dispute.acquirerRefId || '',
            acquirerClaimId: isOutcomeChargeback ? dispute.acquirerClaimId || '' : undefined,
            chargebackReferenceId: isOutcomeChargeback ? dispute.chargebackReferenceId || '' : undefined
        });
        setDisputeAmount(disputeAmount || '');
        setTransactionAmount(transactionAmount || '');
    };

    return <>
        <div className="walmart-dispute-divider">
            <Divider />
            {
                multipleDisputes ? 
                    <span onClick={() => deleteDispute(disputeId)}>
                        <Icon type="CloseLined" />
                    </span>
                    : null
            }
        </div>
        <DropdownSelect
            className="walmart-dispute-reason"
            label="Dispute Reason Description"
            options={Object.entries(DisputeReason).map(([label, value]) => ({ label, value }))}
            value={dispute.disputeReasonDesc ?? null}
            onChange={disputeReasonDesc => setDispute({ ...dispute, disputeReasonDesc })}
            required
        />
        <Datepicker
            label="Transaction Date"
            value={dispute.transactionDate ? new Date(dispute.transactionDate * 1000) : null}
            unavailable={futureDateUnavailable}
            onSelect={(date: Date) => setDispute({ ...dispute, transactionDate: (date.getTime() / 1000)})} // Need to handle this in parseDate util
            required
        />
        <TextInput
            label="Last Four Card Number"
            value={dispute.lastFourCardNum ?? null}
            onChange={lastFourCardNum => setDispute({ ...dispute, lastFourCardNum })}
            pattern="\d{4}"
            tooltip="Last 4 digits of card used for transaction [from NuBITS Transaction Viewer]"
            readOnly={readOnly}
            required
        />
        <TextInput
            label="Dispute Amount"
            value={disputeAmount}
            onChange={value => setDisputeAmount(value)}
            onBlur={value => setDispute({ ...dispute, disputeAmount: parseFloat(value) })}
            tooltip="Dispute amount [from NuBITS Transaction Viewer]"
            readOnly={readOnly}
            required
        />
        <TextInput
            label="Transaction Amount"
            value={transactionAmount}
            onChange={value => setTransactionAmount(value)}
            onBlur={value => setDispute({ ...dispute, transactionAmount: parseFloat(value) })}
            tooltip="Transaction amount [from NuBITS Transaction Viewer]"
            readOnly={readOnly}
            required
        />
        <TextInput
            label="Issuer Claim ID"
            value={dispute.issuerClaimId ?? null}
            onChange={issuerClaimId => setDispute({ ...dispute, issuerClaimId })}
            tooltip="Auth seq [from NuBITS Transaction Viewer]"
            readOnly={readOnly}
            required
        />
        <TextInput
            label="Issuer Claim Transaction ID"
            value={dispute.issuerClaimTransactionId ?? null}
            onChange={issuerClaimTransactionId => setDispute({ ...dispute, issuerClaimTransactionId })}
            tooltip="Swipe/Transaction ID [from NuBITS Transaction Viewer]"
            readOnly={readOnly}
            required
        />
        <TextInput
            label="Merchant ID"
            value={dispute.merchantId ?? null}
            onChange={merchantId => setDispute({ ...dispute, merchantId })}
            helper="Limit of 13 characters"
            maxLength={13}
            tooltip="Merchant ID [from NuBITS Transaction Viewer]"
            readOnly={readOnly}
            required
        />
        <TextInput
            label="Acquirer Reference ID"
            value={dispute.acquirerRefId ?? null}
            onChange={acquirerRefId => setDispute({ ...dispute, acquirerRefId })}
            helper="Limit of 23 characters"
            tooltip="Acquirer reference ID [from NuBITS Transaction Viewer]"
            readOnly={readOnly}
            required
            maxLength={23}
        />
        {
            isOutcomeChargeback ?
            <>
                <TextInput
                    label="Acquirer Claim ID"
                    value={dispute.acquirerClaimId ?? null}
                    onChange={acquirerClaimId => setDispute({ ...dispute, acquirerClaimId })}
                    readOnly={readOnly}
                    required={isOutcomeChargeback}
                />
                <TextInput
                    label="Charge Back Reference ID"
                    value={dispute.chargebackReferenceId ?? null}
                    onChange={chargebackReferenceId => setDispute({ ...dispute, chargebackReferenceId })}
                    tooltip="Auth seq [from NuBITS Transaction Viewer]"
                    readOnly={readOnly}
                    required={isOutcomeChargeback}
                />
            </> : null
        }
    </>
}

export default WalmartDisputesFormSection;