import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import Form from 'src/components/formElements/Form/Form';
import TextInput from 'src/components/formElements/TextInput/TextInput';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { navigate } from '@reach/router';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { SaasInvitationRequest, SaasRoleSortOrder, SaasUser, SaasUserRole, SaasUserRoleId } from 'src/types/saas';
import FormActionTray from 'src/components/formElements/FormActionTray/FormActionTray';
import RadioButtonGroup from '../formElements/RadioButtonGroup/RadioButtonGroup';
import Tile from 'src/containers/Tile/Tile';
import FormField from 'src/components/formElements/FormField/FormField';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import 'src/components/SaasUserForm/SaasUserForm.css';
import _ from 'lodash';
import { useAuthenticationContext } from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';
import AlertBanner from '../AlertBanner/AlertBanner';
import { Checkbox, Fieldset, Spinner } from "@c1/gravity-react";
import { HasLabelAndValue } from 'src/types/shared';
import { getFromApi } from 'src/utils/getFromApi';
import { convertNameAndCodeToLabelAndValue } from 'src/utils/typeConversions';

interface SaasUserFormProps extends FunctionComponentProps {
    userToEdit?: SaasUser;
}

function SaasUserForm(
    {
        userToEdit
    }: SaasUserFormProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const tenantId: string | undefined = routeBuilder.getTenantId();
    const partnerCode: string = tenantId ? tenantId.split("-")[1] : "";
    const { user } = useAuthenticationContext();

    const [email, setEmail] = useState<string | null>(userToEdit ? userToEdit.emailAddress : null);
    const [featureRole, setFeatureRole] = useState<string>(userToEdit ?
        userToEdit.roles.filter(r => r.roleName.startsWith('Partnershipsdata-') && r.roleName.includes('-Ext-') && r.roleName.endsWith('-Role'))
        .map(r => r.roleId)[0] : "");
    const [hasThoughtSpotRole, setHasThoughtSpotRole] = useState<boolean>(userToEdit ?
        userToEdit.roles.filter(r => r.roleName.startsWith('ThoughtSpot-Card-Tech-DFS')).length > 0 : false);

    const [allRoleOptions, setAllRoleOptions] = useState<SaasUserRole[] | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);

    const [bannerType, setBannerType] = useState<("success" | "error")>("success");
    const [bannerMessage, setBannerMessage] = useState<string>("");
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const apiRoute: string = routeBuilder.api.getAllSaasUserRoles();
    useEffect(getFromApi(apiRoute, setAllRoleOptions, setError), [apiRoute]);

    const submitButtonText = userToEdit ? 'Update User' : 'Invite User';

    async function handleInviteUser(): Promise<void> {
        setShowBanner(false);

        const trimmedEmail = email && email.trim();
        const trimmedFeatureRole = featureRole && featureRole.trim();

        if (allRoleOptions == null) return;
        if (!trimmedEmail || !trimmedFeatureRole) {
            setEmail(trimmedEmail || '');
            setBannerType("error");
            setBannerMessage("Please enter a valid email and select a role.");
            setShowBanner(true);

            return;
        }

        let allUsersRoles: string[] = [featureRole];
        let thoughtSpotRoleId = allRoleOptions.filter(r => r.roleName.includes('ThoughtSpot-Card-Tech-DFS')).map(r => r.roleId)[0];
        if (hasThoughtSpotRole) {
            allUsersRoles.push(thoughtSpotRoleId)
        };
        let partnerDataRoleId = allRoleOptions.filter(r => r.roleName.startsWith('Partnershipsdata-')
            && r.roleName.endsWith(partnerCode + '-Data')).map(r => r.roleId)[0];
        allUsersRoles.push(partnerDataRoleId);

        const newSaasUserInvitation: SaasInvitationRequest = {
            inviterName: `${user?.firstName} ${user?.lastName}`,
            emailAddress: trimmedEmail,
            userRoleIds: allUsersRoles
        };

        setLoading(true);
        axios.post(routeBuilder.api.inviteSaasUser({ isThoughtSpotUser: hasThoughtSpotRole.toString() }), newSaasUserInvitation)
            .then(() => {
                setBannerMessage("User Invitation sent.");
                setLoading(false);
                setBannerType("success");
                setShowBanner(true);
                setTimeout(() => {
                    navigate(routeBuilder.client.toManageSaasUsersPage());
                }, 2000);  
            })
            .catch(e => {
                console.error(e);
                setLoading(false);
                setBannerType("error");
                setBannerMessage(`There was an issue sending User Invitation. -- ` + e?.response?.data?.error);
                setShowBanner(true);
            });
    }

    async function handleUpdateUser(): Promise<void> {
        setShowBanner(false);
        if (userToEdit == null) return;
        if (allRoleOptions == null) return;

        if (featureRole === "") {
            setBannerType("error");
            setBannerMessage("Please select a role.");
            setShowBanner(true);

            return;
        }

        let allUsersRoles: string[] = [featureRole];
        let thoughtSpotRoleId = allRoleOptions.filter(r => r.roleName.includes('ThoughtSpot-')).map(r => r.roleId)[0];
        if (hasThoughtSpotRole) {
            allUsersRoles.push(thoughtSpotRoleId)
        };
        let partnerDataRoleId = allRoleOptions.filter(r => r.roleName.startsWith('Partnershipsdata-')
            && r.roleName.endsWith(partnerCode + '-Data')).map(r => r.roleId)[0];
        allUsersRoles.push(partnerDataRoleId);
        
        const updatedUserRoles: SaasUserRoleId[] = allUsersRoles.map(r => {
            return { roleId: r } as SaasUserRoleId;
        });

        setLoading(true);
        axios.post(routeBuilder.api.editSaasUserRoles({
            userId: userToEdit.userId,
            isThoughtSpotUser: hasThoughtSpotRole.toString(),
            email: userToEdit.emailAddress
        }), updatedUserRoles)
            .then(() => {
                setBannerMessage("User Roles were updated successfully.");
                setLoading(false);
                setBannerType("success");
                setShowBanner(true);
                setTimeout(() => {
                    navigate(routeBuilder.client.toManageSaasUsersPage());
                }, 2000);  
            })
            .catch(e => {
                console.error(e);
                setLoading(false);
                setBannerType("error");
                setBannerMessage(`There was an issue updating the roles. -- ${e}`);
                setShowBanner(true);
            });
    }

    function isDefaultExtPartnershipsRole(roleName: string): boolean {
        if (roleName.startsWith('Partnershipsdata-') && roleName.includes('-Ext-') && !roleName.includes('_ONLY_') && roleName.endsWith('-Role')) {
            return true;
        }
        return false;
    }

    function isExternalPartnerSpecificRole(roleName: string): boolean {
        if (roleName.startsWith('Partnershipsdata-') && roleName.includes('-Ext-') && roleName.includes('_ONLY_') && roleName.includes(partnerCode) && roleName.endsWith('-Role')) {
            return true;
        }
        return false;
    }

    let defaultRoleOptions = allRoleOptions ? allRoleOptions
        .filter(r => isDefaultExtPartnershipsRole(r.roleName))
        .map(r => { return { name: r.roleDescription, code: r.roleId }})
        .sort((a, b) => SaasRoleSortOrder[a.name] - SaasRoleSortOrder[b.name])
        : [];

    let ptrSpecificRoleOptions = allRoleOptions ? allRoleOptions
        .filter(r => isExternalPartnerSpecificRole(r.roleName))
        .map(r => { return { name: r.roleDescription, code: r.roleId }})
        : [];
    
    let featureRoleOptions = [...defaultRoleOptions, ...ptrSpecificRoleOptions];

    const roleCheckOptions = allRoleOptions && allRoleOptions
        .filter(r => r.roleName.startsWith('ThoughtSpot-Card-Tech-DFS') && r.roleName.endsWith('-Consumer'))
        .map(r => { return { label: r.roleDescription, value: r.roleId }});

    return (
        <Tile>
            <AlertBanner
                type={bannerType}
                message={bannerMessage}
                active={showBanner}
            />
            <Form onSubmit={userToEdit ? handleUpdateUser : handleInviteUser} name={_.camelCase(submitButtonText)}>
                <FormField className="inline-form-fields">
                    <TextInput
                        label="Email"
                        value={email}
                        onChange={setEmail}
                        disabled={userToEdit != null}
                        required
                    />
                </FormField>
                {
                    featureRoleOptions &&
                    <RadioButtonGroup
                        label="Select a User Role"
                        options={featureRoleOptions.map(convertNameAndCodeToLabelAndValue)}
                        value={featureRole}
                        onChange={setFeatureRole}
                        required
                    />
                }
                {
                    roleCheckOptions &&
                    <div className="checkbox-group">
                        <Fieldset className="checkbox-group-fieldset">
                            <i>Optional</i>
                            <div className="user-role-checkbox-tray">
                                {
                                    roleCheckOptions.map((option: HasLabelAndValue) => (
                                        <Checkbox
                                            key={`${option.label}-${option.value}`}
                                            name={`thoughtspot-user-role`}
                                            label={option.label}
                                            value={option.value}
                                            checked={hasThoughtSpotRole}
                                            onChange={setHasThoughtSpotRole}
                                        />
                                    ))
                                }
                            </div>
                        </Fieldset>
                    </div>
                }
                <FormActionTray
                    cancelButtonLinkTo={routeBuilder.client.toManageSaasUsersPage()}
                    submitButtonText={submitButtonText}
                />
            </Form>
            <Spinner active={loading || (allRoleOptions === null)} />
        </Tile>
    );
}

export default SaasUserForm;
