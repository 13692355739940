import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import Icon from 'src/components/icons/Icon/Icon';
import { Dictionary } from 'ts-essentials';
import 'src/components/tables/SortableColumnHeader/SortableColumnHeader.css';

export interface SortableColumnHeaderProps<ItemType extends Dictionary<any>> extends FunctionComponentProps {
    title: ReactNode;
    dataIndex: Exclude<keyof ItemType, number | symbol>;
    sortIndex: Exclude<keyof ItemType, number | symbol>;
    sortBy: Exclude<keyof ItemType, number | symbol> | null;
    sortDirection: number;
    setSortBy: Dispatch<SetStateAction<Exclude<keyof ItemType, number | symbol> | null>>;
    setSortDirection: Dispatch<SetStateAction<number>>;
}

function SortableColumnHeader<ItemType extends Dictionary<any>>(
    {
        title,
        dataIndex,
        sortIndex,
        sortBy,
        sortDirection,
        setSortBy,
        setSortDirection
    }: SortableColumnHeaderProps<ItemType>
): FunctionComponentReturnType {
    function handleSort(): void {
        if (sortBy === dataIndex) {
            setSortDirection((oldSortDirection: number) => -1 * oldSortDirection);
            return;
        }

        setSortBy(dataIndex);
        setSortDirection(1);
    }

    return (
        <div className="sortable-column">
            {
                (typeof title) === 'string' || (typeof title) === 'number' ?
                    <span>{title}</span> :
                    title
            }
            <Icon
                type={
                    sortBy === sortIndex ?
                        (sortDirection > 0 ? 'ArrowUpLined' : 'ArrowDownLined') :
                        'ArrowSortLined'
                }
                onClick={handleSort}
                alt={`Sort ${title}`}
            />
        </div>
    );
}

export default SortableColumnHeader;
