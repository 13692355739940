import SelectableCard from 'src/components/SelectableCard/SelectableCard';
import { Partner } from 'src/types/partner';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';

interface PartnerCardProps extends FunctionComponentProps {
    partner: Partner;
    linkTo: string;
}

function PartnerCard({ partner, linkTo }: PartnerCardProps): FunctionComponentReturnType {
    return <SelectableCard
        item={partner}
        linkTo={linkTo}
        defaultIconType="BillLined"
    />;
}

export default PartnerCard;
