import React, { ReactNode } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { FrequentlyAskedQuestion } from 'src/types/frequentlyAskedQuestion';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import AdminTable, { ColumnTitleAndDataIndex } from 'src/components/tables/AdminTable/AdminTable';

export interface FrequentlyAskedQuestionsAdminTableProps extends FunctionComponentProps {
    frequentlyAskedQuestions: FrequentlyAskedQuestion[];
    refreshData: () => void;
}

function FrequentlyAskedQuestionsAdminTable(
    {
        frequentlyAskedQuestions,
        refreshData
    }: FrequentlyAskedQuestionsAdminTableProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    const columns: ColumnTitleAndDataIndex<FrequentlyAskedQuestion>[] = [
        { title: 'Question', dataIndex: 'question' },
        { title: 'Answer', dataIndex: 'answer' },
    ];

    function getKey(item: FrequentlyAskedQuestion): string {
        return `frequently-asked-questions-admin-table-row-${item.id}`;
    }

    function getEditButtonLinkTo(item: FrequentlyAskedQuestion): string {
        return routeBuilder.client.toEditFrequentlyAskedQuestionPage({
            frequentlyAskedQuestionId: item.id
        });
    }

    const modalTitle = 'Confirm Delete Frequently Asked Question';

    function getModalDescription(item: FrequentlyAskedQuestion): ReactNode {
        return (
            <p>
                {'Are you sure you want to delete the following FAQ?'}
                <br/>
                <br/>
                {`${item.question}`}
                <br/>
                <br/>
                {`${item.answer}`}
            </p>
        );
    }

    function getApiRouteToDelete(item: FrequentlyAskedQuestion): string {
        return routeBuilder.api.deleteFrequentlyAskedQuestion({ frequentlyAskedQuestionId: item.id });
    }

    return (
        <>
            <AdminTable
                columns={columns}
                items={frequentlyAskedQuestions}
                getKey={getKey}
                getEditButtonLinkTo={getEditButtonLinkTo}
                modalTitle={modalTitle}
                getModalDescription={getModalDescription}
                getApiRouteToDelete={getApiRouteToDelete}
                refreshData={refreshData}
            />
        </>
    );
}

export default FrequentlyAskedQuestionsAdminTable;
