import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import clsx from 'clsx';
import { Fieldset as GravityFieldset } from '@c1/gravity-react';

interface FieldsetProps extends FunctionComponentProps {
    className?: string;
    displayLegendAsLabel?: boolean;
    legendText: string;
}

function Fieldset(
    {
        className,
        displayLegendAsLabel,
        legendText,
        children
    }: FieldsetProps
): FunctionComponentReturnType {
    return (
        <GravityFieldset className={clsx(className)}>
        <>
            <legend
                className={clsx(
                    displayLegendAsLabel ?
                        'grv-textfield__label' :
                        'grv-fieldset__legend'
                )}
            >
                {legendText}
            </legend>
            {children || null}
        </>
        </GravityFieldset>
    );
}

export default Fieldset;
