import React, { useEffect, useState } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import EnvironmentContext from 'src/contextProviders/EnvironmentProvider/EnvironmentContext';
import { Environment } from 'src/types/environment';
import axios, { AxiosResponse } from 'axios';
import { API_PATHS } from 'src/utils/routing/routes';
import { GetEnvironmentResponse } from 'src/types/apiResponse';
import { CLIENT_PATHS } from 'src/utils/routing/routes';
import * as ReactDeviceDetect from 'react-device-detect';

function EnvironmentProvider({children}: FunctionComponentProps): FunctionComponentReturnType {
    const [isEnvironmentResolved, setEnvironmentResolved] = useState<boolean>(false)
    const [environment, setEnvironment] = useState<Environment>({
        environmentType: 'local',
        apiUrlBase: window.location.origin,
        googleRecaptchaPublicKey: '',
        thoughtspotHostURL: ''
    });

    useEffect(() => {
        if (window.location.pathname !== CLIENT_PATHS.LOGOUT) {
            axios.get(API_PATHS.GET_ENVIRONMENT_CONTEXT)
                .then(({data}: AxiosResponse<GetEnvironmentResponse>) => {
                    setEnvironment((prevState: Environment) => ({
                        environmentType: data.environmentType || prevState.environmentType,
                        apiUrlBase: data.apiUrlBase || prevState.apiUrlBase,
                        googleRecaptchaPublicKey: data.googleRecaptchaPublicKey || prevState.googleRecaptchaPublicKey,
                        thoughtspotHostURL: data.thoughtspotHostURL || prevState.thoughtspotHostURL
                    }));

                })
                .finally(()=>{
                    setEnvironmentResolved(true);
                });
        }
    }, []);

    return (
        <EnvironmentContext.Provider value={{environment, setEnvironment}}>
            {isEnvironmentResolved? children || null : <div className={"grv-spinner grv-spinner--active" } id="buttonSpinnerLight"></div>}
        </EnvironmentContext.Provider>
    );
}

export default EnvironmentProvider;
