import { Button, Dialog } from "@c1/gravity-react";
import { FunctionComponentProps, FunctionComponentReturnType } from "src/types/sharedReact";
import './UpdateDeleteModal.css';

interface UpdateDeleteModalProps extends FunctionComponentProps {
    title: string;
    active: boolean;
    formId: string;
    size?: 'normal' | 'large' | 'xlarge';
    onCancel?: () => void;
    onDelete: () => void;
    deleteIsLoading: boolean;
}

/**
 * This wrapper component allows for prop overrides to the underlying
 * c1-gravity Dialog component
 * Reference here for additional props:
 * https://github-pages.cloud.capitalone.com/Gravity/gravity/docs/gravity-react/catalog/#/dialog
 */
function UpdateDeleteModal({
    title,
    active,
    formId,
    onCancel,
    onDelete,
    deleteIsLoading,
    children,
    ...props
}: UpdateDeleteModalProps
    & { [prop: string]: any }
): FunctionComponentReturnType {

    let okButtonProps = {
        "form": formId,
        "htmlButtonType": "submit",
    }

    return (
        <Dialog
            active={active}
            title={title}
            hideCancel={true}
            theme="dark"
            onCancel={onCancel}
            customFooter={
                <div className="update-delete-modal-footer">
                    <Button
                        type={"destructive"}
                        onClick={onDelete}
                        disabledOnLoading
                        loading={deleteIsLoading}
                    >
                        Delete
                    </Button>
                    <Button
                        {...okButtonProps}
                    >
                        {title}
                    </Button>
                </div>
            }
            {...props}
        >
            {children}
        </Dialog>
    )
}

export default UpdateDeleteModal;