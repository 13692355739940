import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { ReportCategory } from 'src/types/reportCategory';
import { FrequencyType, FrequencyTypeCode } from 'src/types/frequencyType';
import AddReportCategoryCheckboxGroup
    from 'src/components/AddReportCategoryCheckboxGroup/AddReportCategoryCheckboxGroup';
import { HasLabelAndValue } from 'src/types/shared';
import { convertNameAndCodeToLabelAndValue } from 'src/utils/typeConversions';
import FormField from 'src/components/formElements/FormField/FormField';
import { ScheduledReportMonitorDTO } from 'src/types/scheduledReportMonitor';
import { report } from 'process';

export interface AddReportCategoryCheckboxListProps extends FunctionComponentProps {
    frequencyTypes: FrequencyType[]
    selectedReportCategories: ReportCategory[];
    monitors?: ScheduledReportMonitorDTO[];
    addMonitorToCreate?: (monitor: ScheduledReportMonitorDTO) => void;
    addMonitorToUpdate?: (monitor: ScheduledReportMonitorDTO) => void;
    addMonitorToDelete?: (monitor: ScheduledReportMonitorDTO) => void;
    onChange: (newReportCategories: ReportCategory[]) => void;
    isExternal: boolean;
}

function AddReportCategoryCheckboxList(
    {
        frequencyTypes,
        selectedReportCategories,
        monitors,
        addMonitorToCreate,
        addMonitorToUpdate,
        addMonitorToDelete,
        onChange,
        isExternal,
    }: AddReportCategoryCheckboxListProps
): FunctionComponentReturnType {
    const options: HasLabelAndValue<FrequencyTypeCode>[] = frequencyTypes.map(convertNameAndCodeToLabelAndValue);

    function handleChange(changedReportCategory: ReportCategory): void {
        const indexOfChangedReportCategory: number = getIndexOfChangedReportCategory(changedReportCategory);

        const changedReportCategories: ReportCategory[] = [...selectedReportCategories];
        changedReportCategories[indexOfChangedReportCategory] = changedReportCategory;

        onChange(changedReportCategories);
    }

    function getIndexOfChangedReportCategory(newValue: ReportCategory): number {
        return selectedReportCategories.findIndex((x: ReportCategory): boolean => x.code === newValue.code);
    }

    function handleRemove(reportCategoryCode: string): void {
        onChange(selectedReportCategories.filter((x: ReportCategory): boolean => x.code !== reportCategoryCode));
    }

    return (
        <FormField>
            {
                selectedReportCategories.map((reportCategory: ReportCategory) => {
                    const monitorForCategoryCode: (ScheduledReportMonitorDTO | null) = monitors?.filter(e => e.categoryCode == reportCategory.code).length > 0 ?
                        monitors.filter(e => e.categoryCode == reportCategory.code)[0] : null;

                    return (
                        <AddReportCategoryCheckboxGroup
                            key={`add-report-category-checkbox-group-${reportCategory.code}`}
                            reportCategory={reportCategory}
                            monitor={monitorForCategoryCode}
                            addMonitorToCreate={addMonitorToCreate}
                            addMonitorToUpdate={addMonitorToUpdate}
                            addMonitorToDelete={addMonitorToDelete}
                            options={options}
                            onChange={handleChange}
                            onRemove={handleRemove}
                            isExternal={isExternal}
                        />
                    );
                })
            }
        </FormField>
    );
}

export default AddReportCategoryCheckboxList;
