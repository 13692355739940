export enum FeatureType {
    INTERNAL_ADMIN = "INTERNAL_ADMIN",

    VIEW_REPORTS = "VIEW_REPORTS",
    DELETE_REPORTS = "DELETE_REPORTS",
    MANAGE_REPORT_CATEGORIES = "MANAGE_REPORT_CATEGORIES",

    VIEW_TS_DASHBOARDS = "VIEW_TS_DASHBOARDS",
    VIEW_ALL_DASHBOARDS = "VIEW_ALL_DASHBOARDS",
    MANAGE_DASHBOARDS = "MANAGE_DASHBOARDS",

    MANAGE_PARTNERS = "MANAGE_PARTNERS",

    MANAGE_EXTERNAL_USERS = "MANAGE_EXTERNAL_USERS",

    MANAGE_MARKETING_CONTENT = "MANAGE_MARKETING_CONTENT"
}