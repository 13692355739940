import React, { ReactElement, ReactNode } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import RequiredFieldError from 'src/components/errors/RequiredFieldError';
import { Input, Tooltip } from '@c1/gravity-react';
import DuplicateValueError from 'src/components/errors/DuplicateValueError';
import { Falsy } from 'src/types/falsy';
import clsx from 'clsx';
import _ from 'lodash';

export interface TextInputProps extends FunctionComponentProps {
    label: string;
    value: string | null;
    onChange: (newValue: string) => void;
    disabled?: boolean;
    errors?: (ReactElement | Falsy)[];
    isDuplicate?: boolean;
    required?: boolean;
    textarea?: boolean;
    hideLabel?: boolean;
    placeholder?: string;
    className?: string;
    helper?: string | ReactNode;
    maxLength?: number;
    pattern?: string;
    onBlur?: (value: string) => void;
    tooltip?: string;
    readOnly?: boolean;
}

function TextInput(
    {
        label,
        value,
        onChange,
        disabled = false,
        errors = [],
        isDuplicate = false,
        required = false,
        textarea = false,
        hideLabel = false,
        placeholder,
        className,
        helper,
        maxLength,
        pattern,
        onBlur,
        tooltip,
        readOnly = undefined,
    }: TextInputProps
): FunctionComponentReturnType {
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        onChange(event.target.value);
    }

    function handleBlur(event: React.FocusEvent<HTMLInputElement>): void {
        if (onBlur) onBlur(event.target.value);
    }

    const isRequiredFieldError = required && value?.trim() === '';

    const parsedErrors = [
        isRequiredFieldError && <RequiredFieldError key={'required-field-error'}/>,
        isDuplicate && <DuplicateValueError key={'duplicate-value-error'}/>,
        ...errors
    ].filter(x => x);

    function createTooltipLabel(): JSX.Element {
        return (
            <Tooltip
                content={tooltip}
                info
                placement="bottom-left"
                tabIndex="-1"
            >{label}</Tooltip>
        );
    }

    return (
        <Input
            className={clsx(className)}
            name={_.camelCase(label)}
            label={tooltip ? createTooltipLabel() : label}
            value={value || ''}
            error={parsedErrors.length ? parsedErrors : null}
            onChange={handleChange}
            textarea={textarea}
            disabled={disabled}
            hideLabel={hideLabel}
            placeholder={placeholder}
            helper={helper}
            maxLength={maxLength}
            pattern={pattern}
            onBlur={handleBlur}
            readOnly={readOnly}
        />
    );
}

export default TextInput;
