import React, { useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import FrequencyTypeForm from 'src/components/FrequencyTypeForm/FrequencyTypeForm';
import { GetFrequencyTypesAdminResponse } from 'src/types/apiResponse';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';

function CreateFrequencyTypePage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetFrequencyTypesAdminResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getAllFrequenciesAdmin();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    return data && <FrequencyTypeForm
        frequencyTypes={data.frequencyTypes}
    />;
}

export default CreateFrequencyTypePage;
