import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import CustomerInvoiceForm from 'src/components/CustomerInvoiceForm/CustomerInvoiceForm';
import { GetCustomerInvoicesResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { getFromApi } from 'src/utils/getFromApi';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';

export default function CreateCustomerInvoicePage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetCustomerInvoicesResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getCustomerInvoices();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    return <CustomerInvoiceForm invoices={data?.invoices ?? []} />;
}
