import { Alert } from '@c1/gravity-react';
import { FunctionComponentProps, FunctionComponentReturnType } from "src/types/sharedReact";
import 'src/components/AlertBanner/AlertBanner.css';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';

export interface AlertBannerProps extends FunctionComponentProps {
    active: boolean;
    type: AlertBannerType;
    message: string;
}

export type AlertBannerType = 'informational' | 'warning' | 'error' | 'success';

/**
 * This wrapper component allows for prop overrides to the underlying
 * c1-gravity Alert component
 * Reference here for additional props:
 * https://github-pages.cloud.capitalone.com/Gravity/gravity/docs/gravity-react/catalog/#/alert
 */
function AlertBanner({
        active,
        type,
        message,
        ...props
    }: AlertBannerProps
    & {[key: string]: any}
): FunctionComponentReturnType {

    return (
        <Alert
            className={clsx(isMobile ? "alert-wrapper-mobile" : "alert-wrapper")}
            type={type}
            active={active}
            message={message}
            { ...props }
        />
    )
}

export default AlertBanner;
