import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import Link from 'src/components/Link/Link';

export interface TechnicalDifficultiesPageProps extends FunctionComponentProps {
    errorMessage?: string;
}

function TechnicalDifficultiesPage({ errorMessage }: TechnicalDifficultiesPageProps): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    console.error(errorMessage);

    return (
        <div>
            <h1>{'Technical Difficulties'}</h1>
            <p>{'We apologize, but we seem to be experiencing some technical difficulties.'}</p>
            {errorMessage && <p>{errorMessage}</p>}
            <p>
                {'If this problem persists, please '}
                <Link to={routeBuilder.client.toReportAnIssuePage()}>
                    {'report an issue'}
                </Link>
                {'.'}
            </p>
            <p>
                {'If your issue is urgent, please call customer service at '}
                <a href="tel:+1-800-344-4153">{'(800) 344-4153'}</a>
                {'.'}
            </p>
        </div>
    );
}

export default TechnicalDifficultiesPage;
