import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import AddItemLinkedButton from 'src/components/buttons/AddItemLinkedButton/AddItemLinkedButton';
import React, { useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { SaasUser } from 'src/types/saas';
import LinkedButtonWithIcon from 'src/components/buttons/ButtonWithIcon/LinkedButtonWithIcon';

interface ManageSaasUsersPageActionTrayProps extends FunctionComponentProps {
    data: SaasUser[] | null;
}

const ManageSaasUsersPageActionTray: React.FC<ManageSaasUsersPageActionTrayProps> = ({data}) => {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [downloadLink, setDownloadLink] = useState<string>("");

    useEffect(() => {
        if (!data) return;

        const dataAsCsvString: string = 'Email,Roles\n' +
            data
                .map((user: SaasUser): string => {
                    return [
                        user.emailAddress,
                        user.roles.map(role => role.roleDescription).join('|')
                    ].join(',');
                })
                .join('\n');

        const dataAsBlob: Blob = new Blob([dataAsCsvString], { type: 'text/csv' });
        const blobUrl = (window.URL && window.URL.createObjectURL) ?
            window.URL.createObjectURL(dataAsBlob) :
            window.webkitURL.createObjectURL(dataAsBlob);

        setDownloadLink(blobUrl);

        return () => {
            window.URL.revokeObjectURL(blobUrl);
        };
    }, [data]);

    return (
        <ActionTray>
            {
                <LinkedButtonWithIcon
                    iconType="LinkDownloadLined"
                    buttonType="action"
                    download="users.csv"
                    linkTo={downloadLink}
                >
                    {'Download All Users'}
                </LinkedButtonWithIcon>
            }
            <LinkedButtonWithIcon linkTo={routeBuilder.client.toManageSaasUserInvitationsPage()} iconType="MailEnvelopeLined" buttonType="progressive">
                {'View Pending Invitations'}
            </LinkedButtonWithIcon>
            <AddItemLinkedButton linkTo={routeBuilder.client.toInviteSaasUserPage()}>
                {'Invite a New User'}
            </AddItemLinkedButton>
        </ActionTray>
    );
}

export default ManageSaasUsersPageActionTray;
