import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import clsx from 'clsx';
import 'src/containers/ActionTray/ActionTray.css';

export interface ActionTrayProps extends FunctionComponentProps {
    className?: string;
}

function ActionTray({ className, children }: ActionTrayProps): FunctionComponentReturnType {
    return (
        <div className={clsx(className, 'action-tray')}>
            {children || null}
        </div>
    );
}

export default ActionTray;
