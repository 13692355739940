import React from 'react';
import LinkedButton from 'src/components/buttons/LinkedButton/LinkedButton';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import clsx from 'clsx';

export interface CancelLinkedButtonProps extends FunctionComponentProps {
    className?: string
    linkTo: string;
}

function CancelLinkedButton({ className, linkTo }: CancelLinkedButtonProps): FunctionComponentReturnType {
    return (
        <LinkedButton
            className={clsx(className)}
            linkTo={linkTo}
            buttonType="regressive"
            id={'copp-button-linked-cancel'}
        >
            {'Cancel'}
        </LinkedButton>
    );
}

export default CancelLinkedButton;
