import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import Icon from 'src/components/icons/Icon/Icon';
import React from 'react';
import { MenuItem } from 'src/components/NavigationPane/NavMenuItem';
import CompoundIcon from 'src/components/icons/CompoundIcon/CompoundIcon';
import { PageMetadata } from 'src/utils/routing/PageMetadata';

export interface NavMenuItemTitleProps extends FunctionComponentProps {
    menuItem: MenuItem;
    parentMenuItemIcon?: string;
}

function NavMenuItemTitle(
    {
        menuItem,
        parentMenuItemIcon
    }: NavMenuItemTitleProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const metadata: PageMetadata = menuItem.metadata;
    const menuItemName: string = metadata.menuItemName;
    const menuIcon: string | undefined = metadata.menuIcon;

    const primaryIconType = routeBuilder.client.getMenuItemNameForCurrentPage() === menuItemName ?
        `${menuIcon}Filled` :
        `${menuIcon}Lined`;

    return (
        <>
            {
                parentMenuItemIcon ?
                    <CompoundIcon
                        primaryIconType={primaryIconType}
                        secondaryIconType={`${parentMenuItemIcon}Lined`}
                    /> :
                    <Icon type={primaryIconType}/>
            }
            <span className="nav-menu-item-page-name">
                {menuItemName}
            </span>
        </>
    );
}

export function MobileMenuItemTitle(
    {
        menuItem
    }: NavMenuItemTitleProps
): FunctionComponentReturnType {
    return (
        <>
            <span className="nav-menu-item-page-name">
                {menuItem.metadata.menuItemName.toUpperCase()}
            </span>
        </>
    );
}

export default NavMenuItemTitle;
