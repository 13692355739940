import { Datepicker } from '@c1/gravity-react';
import { navigate } from '@reach/router';
import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import Tile from 'src/containers/Tile/Tile';
import { CustomerInvoice } from 'src/types/formRequest';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { parseDate } from 'src/utils/datetime';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { CLIENT_PATHS } from 'src/utils/routing/routes';
import Form from '../formElements/Form/Form';
import FormActionTray from '../formElements/FormActionTray/FormActionTray';
import TextInput from '../formElements/TextInput/TextInput';

interface CustomerInvoiceFormProps extends FunctionComponentProps {
    invoices: CustomerInvoice[];
    invoice?: CustomerInvoice;
}

function CustomerInvoiceForm({
    invoices,
    invoice
}: CustomerInvoiceFormProps): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [accountNumber, setAccountNumber] =
        useState<string | null>(invoice?.accountNumber ?? null);
    const [startDate, setStartDate] = 
        useState<number | null>(invoice?.startDate ?? null);
    const [endDate, setEndDate] =
        useState<number | null>(invoice?.endDate ?? null);

    const submitButtonText = invoice ? 'Edit Request' : 'Add Request';
    
    async function handleSubmit(): Promise<void> {
        const trimmedAccountNumber = accountNumber && accountNumber?.trim();

        if (
            !trimmedAccountNumber
        ) {
            setAccountNumber(trimmedAccountNumber);
            setStartDate(startDate);
            setEndDate(endDate);
            return; // Abort submission
        }

        const newCustomerInvoice: CustomerInvoice = {
            recordId: invoice?.recordId ?? undefined,
            accountNumber: trimmedAccountNumber,
            startDate: startDate!,
            endDate: endDate!
        };

        const upsert: Promise<AxiosResponse> = invoice ?
            axios.put(routeBuilder.api.editCustomerInvoice(), newCustomerInvoice) :
            axios.post(routeBuilder.api.createCustomerInvoice(), newCustomerInvoice);

        await upsert
            .then(() => navigate(CLIENT_PATHS.CUSTOMER_INVOICE_FORM_PAGE))
            .catch(console.error);
    }

    function isUnavailable(date: Date): boolean {
        return date.getTime() > Date.now();
    }

    return (
        <Tile>
            <Form onSubmit={handleSubmit} name={'CustomerInvoiceTODO'}>
                <TextInput
                    label="Account Number"
                    value={accountNumber}
                    onChange={setAccountNumber}
                    pattern="\d{16}"
                    helper="Account number should be 16 digits"
                    disabled={Boolean(invoice)}
                    required
                />
                <Datepicker
                    label="Start Date"
                    value={startDate ? new Date(startDate * 1000) : null}
                    unavailable={isUnavailable}
                    onSelect={(date: Date) => setStartDate(date.getTime() / 1000)} // Need to handle this in parseDate util
                    required
                />
                <Datepicker
                    label="End Date"
                    value={endDate ? new Date(endDate * 1000) : null}
                    unavailable={isUnavailable}
                    onSelect={(date: Date) => setEndDate(date.getTime() / 1000)} // Need to handle this in parseDate util
                    required
                />
                <FormActionTray
                    cancelButtonLinkTo={CLIENT_PATHS.CUSTOMER_INVOICE_FORM_PAGE}
                    submitButtonText={submitButtonText}
                />
            </Form>
        </Tile>
    )
}

export default CustomerInvoiceForm;