import { Orientation } from '@material-ui/core';
import { useState, useEffect } from 'react';

export enum ScreenType  {
    XSMALL = 368,
    SMALL = 600,
    MEDIUM = 768,
    LARGE = 1200,
    XLARGE = 1920,
    XXLARGE
}

export type screenInfo = {
    screenType: ScreenType,
    orientation: Orientation,
    width: number,
    height: number
}

export default function useGetScreenInfo(): screenInfo {

    const [screenType, setScreenType] = useState(getScreenTypeFromWidth(window.outerWidth));
    const [orientation, setOrientation] = useState<Orientation>("horizontal");
    const [width, setWidth] = useState<number>(window.outerWidth);
    const [height, setHeight] = useState<number>(window.outerHeight);

    const handleResize = () => {
        let currentScreenType = getScreenTypeFromWidth(window.outerWidth);
        setScreenType(currentScreenType);

        if (window.outerWidth > window.outerHeight) {
            setOrientation("horizontal");
        }
        else {
            setOrientation("vertical");
        }

        setWidth(window.outerWidth);
        setHeight(window.outerHeight);
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        window.dispatchEvent(new Event("resize"));

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    });

    return {
        screenType,
        orientation,
        width,
        height
    };
}

function getScreenTypeFromWidth(width: number): ScreenType {
    if (width < ScreenType.XSMALL) {
        return ScreenType.XSMALL;
    }
    else if (width < ScreenType.SMALL) {
        return ScreenType.SMALL;
    }
    else if (width < ScreenType.MEDIUM) {
        return ScreenType.MEDIUM;
    }
    else if (width < ScreenType.LARGE) {
        return ScreenType.LARGE;
    }
    else if (width < ScreenType.XLARGE) {
        return ScreenType.XLARGE;
    }
    else {
        return ScreenType.XXLARGE;
    }
}
