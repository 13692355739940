import React, { EffectCallback, useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { GetFrequentlyAskedQuestionsAdminResponse } from 'src/types/apiResponse';
import AddItemLinkedButton from 'src/components/buttons/AddItemLinkedButton/AddItemLinkedButton';
import FrequentlyAskedQuestionsAdminTable
    from 'src/components/FrequentlyAskedQuestionsAdminTable/FrequentlyAskedQuestionsAdminTable';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';

function ManageFrequentlyAskedQuestionsPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetFrequentlyAskedQuestionsAdminResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getAllFrequentlyAskedQuestionsAdmin();

    const refreshData: EffectCallback = getFromApi(apiRoute, setData, setError);
    useEffect(refreshData, [apiRoute]);

    if (error) {
        throw error;
    }

    return (
        <div>
            <ActionTray>
                <AddItemLinkedButton linkTo={routeBuilder.client.toCreateFrequentlyAskedQuestionPage()}>
                    {'Add Frequently Asked Question'}
                </AddItemLinkedButton>
            </ActionTray>
            {
                data && (
                    data.faqs.length ?
                        <FrequentlyAskedQuestionsAdminTable
                            frequentlyAskedQuestions={data.faqs}
                            refreshData={refreshData}
                        /> :
                        <NoResultsFound/>
                )
            }
        </div>
    );
}

export default ManageFrequentlyAskedQuestionsPage;
