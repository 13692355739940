import React, { FormEvent } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import clsx from 'clsx';
import 'src/components/formElements/Form/Form.css';

export interface FormProps extends FunctionComponentProps {
    className?: string;
    id?: string;
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
    name: string;
}

function Form({ className, id, children, onSubmit, name }: FormProps): FunctionComponentReturnType {
    async function handleSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
        event.preventDefault();
        await onSubmit(event);
    }

    return (
        <form name={name} onSubmit={handleSubmit} className={clsx(className)} id={id}>
            {children || null}
        </form>
    );
}

export default Form;
