import React, { EffectCallback, useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import PartnersMonitorsTable from 'src/components/PartnersMonitorsTable/PartnersMonitorsTable';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { GetPartnersAdminResponse, GetScheduledReportMonitorsResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { HasNameAndCode, HasLabelAndValue } from '../../types/shared';
import { ScheduledReportMonitorDTO } from 'src/types/scheduledReportMonitor';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';
import { FREQ_DAY_OPTIONS_MLY, FREQ_NUMBER_OPTIONS, FREQ_TYPE_OPTIONS } from 'src/utils/reportMonitoringUtils';

function ManageReportAlertsPage(): FunctionComponentReturnType {
    const [partnersData, setPartnersData] = useState<GetPartnersAdminResponse | null>(null);
    const [monitorsData, setMonitorsData] = useState<GetScheduledReportMonitorsResponse | null>(null);
    const [refactoredData, setRefactoredData] = useState<ScheduledReportMonitorDTO[] | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoutePartners: string = routeBuilder.api.getAllPartnersAdmin();
    const apiRouteMonitors: string = routeBuilder.api.getAllScheduledReportMonitors();

    const refreshPartnersData: EffectCallback = getFromApi(apiRoutePartners, setPartnersData, setError);
    const refreshMonitorsData: EffectCallback = getFromApi(apiRouteMonitors, setMonitorsData, setError);
    useEffect(refreshPartnersData, [apiRoutePartners]);
    useEffect(refreshMonitorsData, [apiRouteMonitors]);
    useEffect(refactorData, [partnersData, monitorsData]);

    if (error) {
        throw error;
    }

    function refactorData(): void {
        if (!partnersData || !monitorsData) return;
        const sortedMonitors = [...monitorsData.monitors].sort((a, b) => a.partnerCode.localeCompare(b.partnerCode));
        const refactoredMonitors: ScheduledReportMonitorDTO[] = createRefactoredMonitors(sortedMonitors);
        setRefactoredData(refactoredMonitors);
    }

    function createRefactoredMonitors(sortedMonitors: ScheduledReportMonitorDTO[]): ScheduledReportMonitorDTO[] {
        return sortedMonitors.map((monitor: ScheduledReportMonitorDTO) => {
            return {
                ...monitor,
                partnerCode: `${getNameFromCode(partnersData.partners, monitor.partnerCode)} (${monitor.partnerCode})`,
                categoryCode: getNameFromCode(partnersData.categories, monitor.categoryCode),
                frequencyTypeCode: `Every ${getLabelFromValue(FREQ_TYPE_OPTIONS, monitor.frequencyTypeCode)}`,
                frequencyDay: monitor.frequencyDay ? getLabelFromValue(FREQ_DAY_OPTIONS_MLY, monitor.frequencyDay) : '-',
                frequencyNumber: monitor.frequencyNumber ? getLabelFromValue(FREQ_NUMBER_OPTIONS, monitor.frequencyNumber) : '-',
            };
        });
    }

    function getNameFromCode(objects: HasNameAndCode[], code: string): string {
        return objects.find((item: HasNameAndCode) => item.code === code).name;
    }

    function getLabelFromValue(objects: HasLabelAndValue[], value: string): string {
        return objects.find((item: HasLabelAndValue) => item.value === value).label;
    }

    return (
        <>
            {
                refactoredData && (
                    refactoredData.length ?
                        <PartnersMonitorsTable monitors={refactoredData} refreshData={refreshMonitorsData}/> :
                        <NoResultsFound/>
                )
            }
        </>
    );
}

export default ManageReportAlertsPage;
