import React, { useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import UserForm from 'src/components/UserForm/UserForm';
import { GetExternalUsersAdminResponse } from 'src/types/apiResponse';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';

function CreateUserPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetExternalUsersAdminResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getExternalUsersAdmin();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    return (
        <UserForm users={data ? data.users : []}/>
    );
}

export default CreateUserPage;
