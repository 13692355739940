import React, { useEffect, useState, useCallback } from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { GetIsUserAuthenticatedResponse, GetUserResponse } from 'src/types/apiResponse';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { getFromApi } from 'src/utils/getFromApi';
import { RedirectRequest } from '@reach/router';
import Form from 'src/components/formElements/Form/Form';
import Tile from 'src/containers/Tile/Tile';
import TextInput from 'src/components/formElements/TextInput/TextInput';
import FormActionTray from 'src/components/formElements/FormActionTray/FormActionTray';
import axios from 'axios';
import 'src/pages/LoginPage/LoginPage.css';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import { GrvTypographyClass } from 'src/types/gravity';
import { FeatureType } from 'src/types/featureEnum';

function LoginPage(): FunctionComponentReturnType {
    const [username, setUsername] = useState<string>("");
    const [isWaitingForRedirect, setWaitingForRedirect] = useState<boolean>(false);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const redirectPath: string = routeBuilder.api.loginRedirect();
    const ssoLoginPath: string = routeBuilder.api.login();
    const getIsUserAuthenticatedRoute: string = routeBuilder.api.getIsUserAuthenticated();
    const getUserRoute: string = routeBuilder.api.getUser();

    const MINUTES_UNTIL_EXPIRY: number = Number(process.env.REACT_APP_FLAG_USERNAME_TIMEOUT);

    // TODO: Error Handling w/ Alert Banner

    const handleSubmit = useCallback((username: string): void => {
        if (!isWaitingForRedirect) {
            setWaitingForRedirect(true);

            getFromApi(redirectPath,
                (data: RedirectRequest) => {
                    let expirationTime = new Date(Date.now() + (60 * MINUTES_UNTIL_EXPIRY * 1000));
                    document.cookie = `username=${username};expires=${expirationTime.toUTCString()};path=/`;

                    let routeBasedOnRedirect = (data != null && data.uri != null && data.uri !== 'sso');
                    if (routeBasedOnRedirect) {
                        window.location.assign(data.uri);
                    }
                    else {
                        window.location.assign(ssoLoginPath);
                    }
                },
                (e) => {
                    setWaitingForRedirect(false);
                    console.error(e);
                },
                {
                    params: { username }
                }
            )();
        }
    }, [isWaitingForRedirect]);

    useEffect(() => {
        let cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i ++) {
            if (cookies[i].includes("username=")) {
                let usernameFromCookie = cookies[i].split('=')[1];
                setUsername(usernameFromCookie);
            }
        }
    }, [])

    useEffect(() =>
        {
            Promise.all([
                axios.get<GetIsUserAuthenticatedResponse>(
                    getIsUserAuthenticatedRoute,
                    { withCredentials: true }
                ),
                axios.get<GetUserResponse>(
                    getUserRoute,
                    { withCredentials: true }
                )
            ])
            .then(axios.spread((isUserAuthenticatedRes, user) => {
                if ((isUserAuthenticatedRes.data as GetIsUserAuthenticatedResponse).status === "OK") {
                    Object.keys(localStorage)
                        .filter(x => x.startsWith('copp_mobile_marketing_'))
                        .forEach(x => localStorage.removeItem(x));

                    setWaitingForRedirect(true);
                    if (isExternalStoreManager((user.data as GetUserResponse))) {
                        window.location.assign('/dashboards/partners');
                    } else {
                        window.location.assign('/')
                    }
                }
            }))
            .catch((e) => {
                console.error(e);
            })
        },
    [getIsUserAuthenticatedRoute]);

    const isExternalStoreManager = (data: GetUserResponse): boolean => {
        if (!data.user.isExternalUser || data.user.features.length == 0) {
            return false;
        }
        let storeManagerFeatures: string[] = [FeatureType.VIEW_ALL_DASHBOARDS, FeatureType.VIEW_TS_DASHBOARDS];
        return data.user.features.every(elem => storeManagerFeatures.includes(elem))
    }

    return (
        <div>
           <Tile className="login-tile">
               <p className={GrvTypographyClass.headingLarge}>Please provide your Email Address or Enterprise ID (EID)</p>
               <span className={GrvTypographyClass.bodyNormal}>We will validate your credentials and re-direct you for Partner Portal access.</span>
               <br />
               {
                    (!isWaitingForRedirect) ?
                        <div className="login-form">
                            <Form onSubmit={() => {handleSubmit(username)}} name="login-form">
                                <div className="login-username-prompt">
                                    <TextInput
                                        label="Email Address or EID"
                                        value={username}
                                        onChange={setUsername}
                                    />
                                </div>
                                <FormActionTray
                                    submitButtonLoading={isWaitingForRedirect}
                                    submitButtonText={"Sign On"}
                                />
                            </Form>
                        </div>
                    :
                        <LoadingIndicator />
                }
           </Tile>
        </div>
    );
}

export default LoginPage;
