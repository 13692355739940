import React, { useState } from 'react';
import TabList from 'src/components/buttons/TabList/TabList';
import ReportsTable from 'src/components/ReportsTable/ReportsTable';
import { FrequencyTypeAndReportDate, Report, ReportsByFrequencyTypeCode } from 'src/types/report';
import { isReportInDateRange, parseDate } from 'src/utils/datetime';
import { DateTime } from 'luxon';
import { FrequencyType, FrequencyTypeCode } from 'src/types/frequencyType';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import Tile from 'src/containers/Tile/Tile';
import ReportsDateSelector from 'src/components/ReportsDateSelector/ReportsDateSelector';
import 'src/components/ReportSelection/ReportSelection.css';

export interface ReportSelectionProps extends FunctionComponentProps {
    frequencyTypes: FrequencyType[];
    reports: ReportsByFrequencyTypeCode;
    latestReport: FrequencyTypeAndReportDate | null;
    refreshData: () => void;
    handleDownloadError: (msg: string) => void;
}

function ReportSelection(
    {
        frequencyTypes,
        reports,
        latestReport,
        refreshData,
        handleDownloadError
    }: ReportSelectionProps
): FunctionComponentReturnType {
    const maxDate: DateTime = parseDate(latestReport && latestReport.reportDate);

    const [startDate, setStartDate] = useState<DateTime>(maxDate);
    const [endDate, setEndDate] = useState<DateTime>(startDate);

    const [selectedFrequencyType, setSelectedFrequencyType] =
        useState<FrequencyTypeCode>(latestReport ? latestReport.frequencyType : frequencyTypes[0].code);

    const reportsForSelectedFrequency: Report[] = reports[selectedFrequencyType] || [];
    const reportsForSelectedFrequencyAndDate: Report[] = reportsForSelectedFrequency
        .filter(report => isReportInDateRange(report, startDate, endDate));

    const id = 'reports-table-wrapper';

    return (
        <div className="report-selection">
            <div className="report-selection-filters">
                {
                    Object.keys(reports).length ?
                        <ReportsDateSelector
                            className="report-date-selector-width"
                            reports={reportsForSelectedFrequency}
                            startValue={startDate}
                            endValue={endDate}
                            onStartChange={setStartDate}
                            onEndChange={setEndDate}
                        /> :
                        null
                }
                <TabList
                    controlledPanelId={id}
                    label="Frequency Type"
                    values={frequencyTypes}
                    selectedValue={selectedFrequencyType}
                    onChange={setSelectedFrequencyType}
                    alignment="right"
                />
            </div>
            <Tile>
                <ReportsTable
                    id={id}
                    reports={reportsForSelectedFrequencyAndDate}
                    refreshData={refreshData}
                    handleDownloadError={handleDownloadError}
                />
            </Tile>
        </div>
    );
}

export default ReportSelection;
