import React from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import FunctionalIcon, { FunctionalIconProps } from 'src/components/icons/Icon/FunctionalIcon';
import DecorativeIcon, { DecorativeIconProps } from 'src/components/icons/Icon/DecorativeIcon';
import { getIconNotAccessibleErrorMessage } from 'src/components/icons/GravityIcon/GravityIcon';
import 'src/components/icons/Icon/Icon.css';

export type IconProps = FunctionalIconProps | DecorativeIconProps;

function Icon(props: IconProps): FunctionComponentReturnType {
    if ('onClick' in props && (!props.alt || !props.alt.trim())) {
        throw new Error(getIconNotAccessibleErrorMessage(props));
    }

    return 'onClick' in props ? <FunctionalIcon {...props}/> : <DecorativeIcon {...props}/>;
}

export default Icon;
