import React, { ReactElement, ReactNode, useState } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import AdminTable, { ColumnTitleAndDataIndex } from 'src/components/tables/AdminTable/AdminTable';
import { SaasInvitation, SaasUserRole } from 'src/types/saas';
import UnorderedList from '../UnorderedList/UnorderedList';
import { Button } from '@c1/gravity-react';
import AlertBanner from '../AlertBanner/AlertBanner';

export interface SaasUsersAdminTableProps extends FunctionComponentProps {
    userInvitations: SaasInvitation[];
    refreshData: () => void;
    allUserRoles: SaasUserRole[] | null;
    currentPage?: number | null;
    totalPages?: number | null;
    tableUpdate?: (action: string, data: string) => void | null;
}

function UserInvitationsAdminTable({ userInvitations, refreshData, allUserRoles, currentPage, totalPages, tableUpdate }: SaasUsersAdminTableProps): FunctionComponentReturnType {
    const [showBanner, setShowBanner] = useState<boolean>(false);

    const routeBuilder: RouteBuilder = useRouteBuilder();

    const columns: ColumnTitleAndDataIndex<SaasInvitation>[] = [
        { title: 'Email', dataIndex: 'emailAddress' },
        { title: 'Roles', dataIndex: 'userRoleIds', filterable: false },
        { title: 'Expires On', dataIndex: 'invitationExpirationTimeStamp', filterable: false },
        { title: 'Invitation Link', dataIndex: 'invitationUrl', filterable: false },
    ];

    function getKey(item: SaasInvitation): string {
        return `saas-user-invitations-admin-table-row-${item.invitationId}`;
    }

    function getEditButtonLinkTo(item: SaasInvitation): string {
        return "N/A"
    }

    const modalTitle = 'Confirm Delete User Invitation';

    function getModalDescription(item: SaasInvitation): ReactNode {
        return `Are you sure you want to delete this invitation to [${item.emailAddress}]?`;
    }

    function getApiRouteToDelete(item: SaasInvitation): string {
        return routeBuilder.api.deleteSaasUserInvitation({
            invitationId: item.invitationId ?? '',
            email: item.emailAddress
        });
    }

    function getRoleDescriptionsFromRoleIDs(userRoleIds: string[]) {
        if (allUserRoles != null) {
            let roleInfoFromIDs = userRoleIds.map((x) => allUserRoles.find((y) => y.roleId.includes(x)));
            if (roleInfoFromIDs.length > 0) {
                return roleInfoFromIDs.map(z => z ? z.roleDescription : "");
            }
            else {
                return [];
            }
        }
        return [];
    }

    function overrideTableDataSourceMappedFields(item: SaasInvitation): {
        userRoleIds: ReactElement,
        invitationExpirationTimeStamp: ReactElement,
        invitationUrl: ReactElement
    } {
        return {
            userRoleIds: (
                <UnorderedList
                    items={getRoleDescriptionsFromRoleIDs(item.userRoleIds)}
                    marginSize="none"
                />
            ),
            invitationExpirationTimeStamp: (
                <span>{
                    item.invitationExpirationTimeStamp &&
                    (new Date(item.invitationExpirationTimeStamp)).toLocaleDateString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })
                }</span>
            ),
            invitationUrl: (
                <Button
                    type="progress"
                    id={'copp-button-copy-invite'}
                    compact
                    onClick={() => navigator.clipboard.writeText(item.invitationUrl ?? '')}>
                        Invite Link (click to copy)
                </Button>
            )
        };
    }

    return (
        <>
            <AlertBanner
                type="success"
                message={"Invitation was deleted successfully."}
                active={showBanner}
            />
            <AdminTable
                columns={columns}
                items={userInvitations}
                getKey={getKey}
                disableEdit={true}
                getEditButtonLinkTo={getEditButtonLinkTo}
                modalTitle={modalTitle}
                getModalDescription={getModalDescription}
                getApiRouteToDelete={getApiRouteToDelete}
                totalPages={totalPages}
                currentPage={currentPage}
                tableUpdate={tableUpdate}
                refreshData={() => {
                    refreshData();
                    setShowBanner(true)
                    setTimeout(() => setShowBanner(false), 5000);
                }}
                overrideTableDataSourceMappedFields={overrideTableDataSourceMappedFields}
            />
        </>
    );
}

export default UserInvitationsAdminTable;
