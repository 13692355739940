import React, { ReactNode } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { User } from 'src/types/user';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import AdminTable, { ColumnTitleAndDataIndex } from 'src/components/tables/AdminTable/AdminTable';

export interface UsersAdminTableProps extends FunctionComponentProps {
    users: User[];
    refreshData: () => void;
}

interface UserWithUserType extends User {
    userType: 'Internal' | 'External';
}

function UsersAdminTable({ users, refreshData }: UsersAdminTableProps): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    const columns: ColumnTitleAndDataIndex<UserWithUserType>[] = [
        { title: 'User Id', dataIndex: 'userId' },
        { title: 'First Name', dataIndex: 'firstName' },
        { title: 'Last Name', dataIndex: 'lastName' },
        { title: 'Email', dataIndex: 'email' },
        { title: 'User Type', dataIndex: 'userType' },
        { title: 'Phone Number', dataIndex: 'phone' }
    ];

    const usersWithUserType: UserWithUserType[] = users.map((user: User): UserWithUserType => {
        return {
            ...user,
            userType: user.isExternalUser ? 'External' : 'Internal'
        };
    });

    function getKey(item: UserWithUserType): string {
        return `external-users-admin-table-row-${item.userId}`;
    }

    function getEditButtonLinkTo(item: UserWithUserType): string {
        return routeBuilder.client.toEditUserPage({ userId: item.userId });
    }

    const modalTitle = 'Confirm Delete User';

    function getModalDescription(item: UserWithUserType): ReactNode {
        return `Are you sure you want to delete ${item.firstName} ${item.lastName} (${item.userId})?`;
    }

    function getApiRouteToDelete(item: UserWithUserType): string {
        return routeBuilder.api.deleteUser({ userId: item.userId });
    }

    return (
        <>
            <AdminTable
                columns={columns}
                items={usersWithUserType}
                getKey={getKey}
                getEditButtonLinkTo={getEditButtonLinkTo}
                modalTitle={modalTitle}
                getModalDescription={getModalDescription}
                getApiRouteToDelete={getApiRouteToDelete}
                refreshData={refreshData}
            />
        </>
    );
}

export default UsersAdminTable;
