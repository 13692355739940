import React from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import clsx from 'clsx';
import { NavMenuItems } from 'src/components/NavigationPane/NavMenuItems';
import { useNavigationPaneContext } from 'src/contextProviders/NavigationPaneProvider/NavigationPaneContext';
import 'src/components/NavigationPane/NavigationPane.css';
import {
    AuthenticationContextState,
    useAuthenticationContext
} from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';

function NavigationPane(): FunctionComponentReturnType {
    const { isAuthenticated, user }: AuthenticationContextState = useAuthenticationContext();
    const { isNavigationPaneOpen } = useNavigationPaneContext();

    return isAuthenticated && user ? (
        <nav
            className={clsx(
                'navigation-pane',
                isNavigationPaneOpen && 'open'
            )}
        >
            <NavMenuItems/>
        </nav>
    ) : null;
}

export default NavigationPane;
