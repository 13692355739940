import { AxiosError } from 'axios';
import { EffectCallback, ReactElement, ReactNode, useEffect, useState } from 'react';
import AdminTable, { ColumnTitleAndDataIndex } from 'src/components/tables/AdminTable/AdminTable';
import AddItemLinkedButton from 'src/components/buttons/AddItemLinkedButton/AddItemLinkedButton';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import { GetWalmartDisputesResponse } from 'src/types/apiResponse';
import { WalmartDispute, WalmartDisputeTable } from 'src/types/formRequest';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { formatDate } from 'src/utils/datetime';
import { getFromApi } from 'src/utils/getFromApi';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { CLIENT_PATHS } from 'src/utils/routing/routes';
import { convertNumberToCurrencyString } from 'src/utils/typeConversions';
import { Button, Tabs } from '@c1/gravity-react';
import FormModal from 'src/components/modals/FormModal/FormModal';
import WalmartDisputesForm from 'src/components/WalmartDisputesForm/WalmartDisputesForm';

export default function WalmartDisputesFormPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetWalmartDisputesResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getWalmartDisputes();

    const [historicData, setHistoricData] = useState<GetWalmartDisputesResponse | null>(null);
    const historicApiRoute: string = routeBuilder.api.getWalmartHistoricDisputes();
    const [dispute, setDispute] = useState<WalmartDispute | null>(null);
    const [disputeModalOpen, setDisputeModalOpen] = useState<boolean>(false);

    const getHistoricData: EffectCallback = getFromApi(historicApiRoute, setHistoricData, setError);

    const refreshData: EffectCallback = getFromApi(apiRoute, setData, setError);
    useEffect(refreshData, [apiRoute]);

    if (error) throw error;

    const columns: ColumnTitleAndDataIndex<WalmartDisputeTable>[] = [
        { title: 'CID', dataIndex: 'customerId' },
        { title: 'Issuer Claim ID', dataIndex: 'issuerClaimId' },
        { title: 'Dispute Amount', dataIndex: 'disputeAmount' },
        { title: 'Transaction Date', dataIndex: 'transactionDate' },
        { title: 'Claim Submit Date', dataIndex: 'messageDate' }
    ];

    function getKey(item: WalmartDisputeTable): string {
        return `walmart-disputes-${item.recordId}=${item.customerId}-${item.issuerClaimId}-${item.transactionDate}-${item.messageDate}`;
    }

    const deleteModalTitle = 'Confirm Delete';

    function getModalDescription(item: WalmartDisputeTable): ReactNode {
        return `Are you sure you want to delete dispute record for claim ID ${item.issuerClaimId}?`;
    }

    function getApiRouteToDelete(item: WalmartDisputeTable): string {
        return routeBuilder.api.deleteWalmartDispute({
            recordId: item.recordId?.toString()
        });
    }

    function getEditButtonLinkTo(item: WalmartDisputeTable): string {
        return routeBuilder.client.toEditWalmartDisputesPage({
            recordId: String(item.recordId)
        });
    }

    function openHistoricDisputeModal(item: WalmartDispute): void {
        setDispute(item);
        setDisputeModalOpen(true);
    }

    function closeHistoricDisputeModal(): void {
        setDispute(null);
        setDisputeModalOpen(false);
    }

    function getAdminTable(data: GetWalmartDisputesResponse | null, isHistoric: boolean = false): ReactElement {
        return (
            <AdminTable
                columns={columns}
                items={data ?
                    data.disputes.map(i => ({
                        recordId: i.recordId,
                        customerId: i.customerId,
                        issuerClaimId: i.issuerClaimId,
                        disputeAmount: convertNumberToCurrencyString(i.disputeAmount),
                        transactionDate: formatDate(i.transactionDate),
                        messageDate: formatDate(i.messageDate),
                        ...(isHistoric &&
                            {
                                actions: (
                                    <Button
                                        type="progressive"
                                        id={'copp-button-view-' + i?.issuerClaimId}
                                        compact
                                        onClick={() => openHistoricDisputeModal(i)}
                                    >View</Button>
                                )
                            }
                        )
                    })) : []}
                getKey={getKey}
                getEditButtonLinkTo={getEditButtonLinkTo}
                modalTitle={deleteModalTitle}
                getModalDescription={getModalDescription}
                getApiRouteToDelete={getApiRouteToDelete}
                refreshData={refreshData}
            />
        );
    }

    return (
        <>
            <p>
                {'Please be advised that the following requests will be processed at 8PM ET. Entries made later will be processed on the next business day.'}
            </p>
            <ActionTray>
                <AddItemLinkedButton linkTo={CLIENT_PATHS.CREATE_WALMART_DISPUTE_PAGE}>
                    {'New Request'}
                </AddItemLinkedButton>
            </ActionTray>
            <Tabs
                align="left"
                onChange={(activeKey: string) => {
                    if (activeKey === 'historic') getHistoricData();
                }}
            >
                <Tabs.Panel key="active" title="Active Requests">
                    {getAdminTable(data)}
                </Tabs.Panel>
                <Tabs.Panel key="historic" title="Historic Requests">
                    {getAdminTable(historicData, true)}
                </Tabs.Panel>
            </Tabs>
            <FormModal
                active={disputeModalOpen}
                title=""
                formId="view-historic-dispute"
                size="xlarge"
                onCancel={closeHistoricDisputeModal}
                hideOk
                hideCancel
            >
                {
                    historicData && dispute &&
                    <WalmartDisputesForm
                        disputes={historicData.disputes}
                        dispute={dispute}
                        readOnly
                    />
                }
            </FormModal>
        </>
    );
}
