import { Partner } from "./partner";

export interface Dashboard {
    dashboardId?: string,
    dashboardName: string,
    dashboardUrl: string,
    dashboardDescription: string,
    partner: Omit<Partial<Partner>, 'categories'>, // Primarily used by forms
    dashboardType?: DashboardType
}

export enum DashboardType {
    TABLEAU = 'TABLEAU',
    RSTUDIO = 'RSTUDIO',
    THOUGHTSPOT = 'THOUGHTSPOT'
}