import React from 'react';
import { FrequentlyAskedQuestion } from 'src/types/frequentlyAskedQuestion';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { Accordion } from '@c1/gravity-react';
import 'src/components/FaqAccordion/FaqAccordion.css';

export interface FaqAccordionProps extends FunctionComponentProps {
    faqs: FrequentlyAskedQuestion[];
}

function FaqAccordion({ faqs }: FaqAccordionProps): FunctionComponentReturnType {
    return <Accordion singleOpen={false}>
        {
            faqs.map((faq: FrequentlyAskedQuestion) => {
                return (
                    <Accordion.Item key={faq.id} title={faq.question} aria-controls={'foo'}>
                        <p className="faq-answer">
                            {faq.answer}
                        </p>
                    </Accordion.Item>
                );
            })
        }
    </Accordion>;
}

export default FaqAccordion;
