import React, { ReactNode, useEffect, useState } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import BaseModal, { ConfirmButtonComponentProps } from 'src/components/modals/BaseModal/BaseModal';
import { Report } from 'src/types/report';
import { Button } from '@c1/gravity-react';
import fileDownload from 'js-file-download';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { AxiosError } from 'axios';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import { getFromApi } from 'src/utils/getFromApi';
import ReportViewer from 'src/components/ReportViewer/ReportViewer';
import 'src/components/modals/ViewReportModal/ViewReportModal.css';

interface ViewReportModalProps extends FunctionComponentProps {
    onClose: () => void;
    report: Report;
}

function ViewReportModal(
    {
        onClose,
        report
    }: ViewReportModalProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [data, setData] = useState<ArrayBuffer | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const [isDownloadInProgress, setIsDownloadInProgress] = useState<boolean>(false);

    const apiRoute: string = routeBuilder.api.viewReport({ reportId: report.id });

    useEffect(getFromApi(apiRoute, setData, setError, { responseType: 'arraybuffer' }), [apiRoute]);

    if (error) throw error;

    function DownloadButton({ onClick }: ConfirmButtonComponentProps): FunctionComponentReturnType {
        return <Button
            onClick={onClick}
            id={'copp-button-download'}
            loading={isDownloadInProgress}
            disabled={Boolean(!data)}
            disabledOnLoading
            compact
        >
            {'Download'}
        </Button>;
    }

    function getDescription(): ReactNode {
        if (!data) return <LoadingIndicator/>;

        return <ReportViewer data={data}/>;
    }

    function downloadReport(): void {
        if (!data) return;

        setIsDownloadInProgress(true);
        fileDownload(data, report.fileName);
        setIsDownloadInProgress(false);
    }

    return (
        <BaseModal
            className="view-report-modal"
            ConfirmButtonComponent={DownloadButton}
            description={getDescription()}
            isOpen={true}
            title="Viewing Report"
            onClose={onClose}
            onConfirm={downloadReport}
        />
    );
}

export default ViewReportModal;
