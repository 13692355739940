import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import Icon from 'src/components/icons/Icon/Icon';
import clsx from 'clsx';
import 'src/components/icons/CompoundIcon/CompoundIcon.css';

export interface CompoundIconProps extends FunctionComponentProps {
    className?: string;
    primaryIconType: string;
    secondaryIconType: string;
}

function CompoundIcon(
    {
        className,
        primaryIconType,
        secondaryIconType
    }: CompoundIconProps
): FunctionComponentReturnType {
    return (
        <span className={clsx('compound-icon', className)} aria-hidden="true">
            <Icon type={primaryIconType} className="primary-icon"/>
            <Icon type={secondaryIconType} className="secondary-icon"/>
        </span>
    );
}

export default CompoundIcon;
