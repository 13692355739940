import React, { ReactElement, ReactNode } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { Partner } from 'src/types/partner';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import UnorderedList from 'src/components/UnorderedList/UnorderedList';
import AdminTable, { ColumnTitleAndDataIndex } from 'src/components/tables/AdminTable/AdminTable';
import { ReportCategory } from 'src/types/reportCategory';

export interface PartnersAdminTableProps extends FunctionComponentProps {
    partners: Partner[];
    refreshData: () => void;
}

function PartnersAdminTable({ partners, refreshData }: PartnersAdminTableProps): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    const columns: ColumnTitleAndDataIndex<Partner>[] = [
        { title: 'Partner Type', dataIndex: 'type' },
        { title: 'Partner Code', dataIndex: 'code' },
        { title: 'Partner Name', dataIndex: 'name' },
        { title: 'Associated Report Categories', dataIndex: 'categories' }
    ];

    function getKey(item: Partner): string {
        return `partners-admin-table-row-${item.code}-${item.type}`;
    }

    function getEditButtonLinkTo(item: Partner): string {
        return routeBuilder.client.toEditPartnerPage({
            accessTypeCode: item.type,
            partnerCode: item.code
        });
    }

    const modalTitle = 'Confirm Delete Partner';

    function getModalDescription(item: Partner): ReactNode {
        return `Are you sure you want to delete ${item.name} (${item.code} - ${item.type})?`;
    }

    function getApiRouteToDelete(item: Partner): string {
        return routeBuilder.api.deletePartner({
            accessTypeCode: item.type,
            partnerCode: item.code
        });
    }

    function overrideItemToFilter(item: Partner): Omit<Partner, 'categories'> & { categories: string } {
        return {
            ...item,
            categories: item.categories.map((x: ReportCategory) => x.name).join(' ')
        };
    }

    function overrideTableDataSourceMappedFields(item: Partner): { categories: ReactElement } {
        return {
            categories: (
                <UnorderedList
                    items={item.categories.map((x: ReportCategory) => x.name)}
                    marginSize="none"
                />
            )
        };
    }

    return (
        <>
            <AdminTable
                columns={columns}
                items={partners}
                getKey={getKey}
                getEditButtonLinkTo={getEditButtonLinkTo}
                modalTitle={modalTitle}
                getModalDescription={getModalDescription}
                getApiRouteToDelete={getApiRouteToDelete}
                refreshData={refreshData}
                overrideItemToFilter={overrideItemToFilter}
                overrideTableDataSourceMappedFields={overrideTableDataSourceMappedFields}
            />
        </>
    );
}

export default PartnersAdminTable;
