import React, { ReactNode } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import Link from 'src/components/Link/Link';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import Tile from 'src/containers/Tile/Tile';
import 'src/components/NoResultsFound/NoResultsFound.css';

interface NoResultsFoundProps extends FunctionComponentProps {
    specificError?: ReactNode;
}

function NoResultsFound({ specificError }: NoResultsFoundProps): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    return (
        <Tile className="no-results-found">
            <p>{'No results were found.'}</p>
            {specificError}
            <p>
                {
                    'If you believe you are receiving this message by mistake, please try reloading the page. ' +
                    'If the problem persists, please '
                }
                <Link to={routeBuilder.client.toReportAnIssuePage()}>
                    {'report an issue'}
                </Link>
                {' with details on how to reproduce this issue.'}
            </p>
        </Tile>
    );
}

export default NoResultsFound;
