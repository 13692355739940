import React, { useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { GetReportCategoriesAdminResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import ReportCategoryForm from 'src/components/ReportCategoryForm/ReportCategoryForm';
import { ReportCategory } from 'src/types/reportCategory';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';

function EditReportCategoryPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetReportCategoriesAdminResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getAllReportCategoriesAdmin();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    let reportCategory: ReportCategory | null = null;
    if (data) {
        reportCategory = data.categories.find(x => {
            return x.code === routeBuilder.getReportCategoryCode();
        }) || null;
    }

    return data && reportCategory && <ReportCategoryForm
        reportCategories={data.categories}
        reportCategory={reportCategory}
    />;
}

export default EditReportCategoryPage;
