import React, { ReactNode } from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { Spinner, Table } from '@c1/gravity-react';
import { Column } from 'src/types/gravity';
import { AdminTableDataSource, TableFiltersType } from 'src/types/shared';
import { createFilterableColumn, createPlainColumn, filterItem } from 'src/utils/tableUtils';
import ConfirmDeleteModal from 'src/components/modals/ConfirmDeleteModal/ConfirmDeleteModal';
import AdminTableActionTray from 'src/components/tables/AdminTableActionTray/AdminTableActionTray';
import 'src/components/tables/AdminTable/AdminTable.css';
import Button from '@c1/gravity-react/lib/Button';

export interface ColumnTitleAndDataIndex<ItemType extends Record<string, any>> {
    title: string;
    dataIndex: Exclude<keyof ItemType, number | symbol>;
    filterable?: boolean;
}

type ItemToString<ItemType> = (item: ItemType) => string;

export interface AdminTableProps<ItemType extends Record<string, any>> {
    items: ItemType[];
    columns: ColumnTitleAndDataIndex<ItemType>[];
    getKey: ItemToString<ItemType>;
    disableEdit?: boolean;
    getEditButtonLinkTo: ItemToString<ItemType>;
    modalTitle: string;
    getModalDescription: (item: ItemType) => ReactNode;
    getApiRouteToDelete: ItemToString<ItemType>;
    refreshData: () => void;
    overrideItemToFilter?: (item: ItemType) => Record<keyof ItemType, any>;
    overrideTableDataSourceMappedFields?: (item: ItemType) => Partial<AdminTableDataSource<ItemType>>;
    currentPage?: number | null;
    totalPages?: number | null;
    tableUpdate?: (action: string, data: string) => void | null;
}

function AdminTable<ItemType extends Record<string, any>>(
    {
        items,
        columns,
        getKey,
        disableEdit,
        getEditButtonLinkTo,
        getApiRouteToDelete,
        getModalDescription,
        modalTitle,
        refreshData,
        overrideItemToFilter,
        overrideTableDataSourceMappedFields,
        currentPage,
        totalPages,
        tableUpdate
    }: AdminTableProps<ItemType>
): FunctionComponentReturnType {
    const [filters, setFilters] = React.useState<TableFiltersType<ItemType>>({});
    const [itemToDelete, setItemToDelete] = React.useState<ItemType | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    function createColumns(): Column[] {
        const output: Column[] = columns.map((x: ColumnTitleAndDataIndex<ItemType>): Column => {
            if (x.filterable == false) {
                return createPlainColumn(x.title, x.dataIndex);
            }
            return createFilterableColumn(x.title, x.dataIndex, filters, setFilters);
        });

        output.push({ title: '', dataIndex: 'actions', key: 'actions' });

        return output;
    }

    function createDataSource(): AdminTableDataSource<ItemType>[] {
        return items
            .filter(filterItemWithOverrideIfPresent)
            .map(createAdminTableDataSourceWithOverrideIfPresent);
    }

    function filterItemWithOverrideIfPresent(item: ItemType): boolean {
        return filterItem(overrideItemToFilter ? overrideItemToFilter(item) : item, filters);
    }

    function createAdminTableDataSourceWithOverrideIfPresent(item: ItemType): AdminTableDataSource<ItemType> {

        return {
            key: getKey(item),
            actions: (
                <AdminTableActionTray
                    editButtonLinkTo={getEditButtonLinkTo(item)}
                    onDelete={(): void => setItemToDelete(item)}
                    disableEdit={disableEdit}
                />
            ),
            ...item,
            ...overrideTableDataSourceMappedFields && overrideTableDataSourceMappedFields(item)
        };
    }
    return (
        <>
            { currentPage != undefined ? (
            <div style={{display: 'flex', justifyContent: 'end', marginTop: '5px'}}>
                <Button id={'copp-button-first-page-top'} className="pagination-button" onClick={()=>{tableUpdate("page", "first")}}>&lt;&lt;</Button>
                <Button id={'copp-button-prev-page-top'} className="pagination-button" onClick={()=>{tableUpdate("page", "prev")}}>&lt;</Button>
                <div style={{lineHeight: '30px', padding: "0px 5px"}}>{currentPage + " / " + (totalPages < 0 ? '?' : totalPages )}</div>
                <Button id={'copp-button-next-page-top'} className="pagination-button" onClick={()=>{tableUpdate("page", "next")}}>&gt;</Button>
                <Button id={'copp-button-last-page-top'} className="pagination-button" onClick={()=>{tableUpdate("page", "last")}} disabled={totalPages < 0}>&gt;&gt;</Button>            </div>)
            : null }

            <Table
                className="admin-table"
                columns={createColumns()}
                dataSource={createDataSource()}
            />
            { currentPage != undefined ? (
            <div style={{display: 'flex', justifyContent: 'end', marginTop: '5px'}}>
                <Button id={'copp-button-first-page-bottom'} className="pagination-button" onClick={()=>{tableUpdate("page", "first")}}>&lt;&lt;</Button>
                <Button id={'copp-button-prev-page-bottom'} className="pagination-button" onClick={()=>{tableUpdate("page", "prev")}}>&lt;</Button>
                <div style={{lineHeight: '30px', padding: "0px 5px"}}>{currentPage + " / " + (totalPages < 0 ? '?' : totalPages )}</div>
                <Button id={'copp-button-next-page-bottom'} className="pagination-button" onClick={()=>{tableUpdate("page", "next")}}>&gt;</Button>
                <Button id={'copp-button-last-page-bottom'} className="pagination-button" onClick={()=>{tableUpdate("page", "last")}} disabled={totalPages < 0}>&gt;&gt;</Button>
            </div>)
            : null }

            <ConfirmDeleteModal
                isOpen={Boolean(itemToDelete)}
                title={modalTitle}
                description={itemToDelete ? getModalDescription(itemToDelete) : ''}
                apiRouteToDelete={itemToDelete ? getApiRouteToDelete(itemToDelete) : null}
                onClose={(): void => setItemToDelete(null)}
                onAfterDelete={refreshData}
            />
        </>
    );
}

export default AdminTable;
