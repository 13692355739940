import React, { useState } from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { LinkProps } from 'src/components/Link/Link';
import BaseModal from 'src/components/modals/BaseModal/BaseModal';
import clsx from 'clsx';
import TextButton from 'src/components/buttons/TextButton/TextButton';
import 'src/components/Link/ExternalLink.css';

function ExternalLink({ children, to, className }: LinkProps): FunctionComponentReturnType {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    function openModal(): void {
        setIsModalOpen(true);
    }

    function closeModal(): void {
        setIsModalOpen(false);
    }

    const modalDescription = (
        <>
            <p>
                {'You are about to leave Capital One Partner Portal (copp.capitalone.com).'}
            </p>
            <p>
                {`Are you sure you would like to continue to ${to}?`}
            </p>
        </>
    );

    function ConfirmButtonComponent(): FunctionComponentReturnType {
        return (
            <a
                href={to}
                target="_blank"
                rel="noopener noreferrer"
                className={'grv-button--destructive grv-button--compact grv-margin--none'}
                onClick={closeModal}
            >
                {'Continue'}
            </a>
        );
    }

    return (
        <>
            <TextButton
                className={clsx(className, 'external-link')}
                onClick={openModal}
            >
                {children}
            </TextButton>

            <BaseModal
                description={modalDescription}
                isOpen={isModalOpen}
                title="Warning!"
                onClose={closeModal}
                onConfirm={closeModal}
                ConfirmButtonComponent={ConfirmButtonComponent}
            />
        </>
    );
}

export default ExternalLink;
