import { ReactNode, useEffect, useCallback } from 'react';
import EmbeddedFrame from 'src/components/EmbeddedFrame/EmbeddedFrame';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import BaseModal from '../BaseModal/BaseModal';
import EmbeddedTableau from 'src/components/EmbeddedTableau/EmbeddedTableau';
import { Dashboard, DashboardType } from 'src/types/dashboard';
import axios from 'axios';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import 'src/components/modals/DashboardModal/DashboardModal.css';
import iframeResolutionHelper from 'src/utils/iframeResolutionHelper';

interface DashboardModalProps extends FunctionComponentProps {
    onClose: () => void;
    dashboard: Dashboard;
}

function DashboardModal(
    {
        onClose,
        dashboard
    }: DashboardModalProps
): FunctionComponentReturnType {
    const { dashboardId, dashboardType, dashboardUrl, } = dashboard;
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.trackDashboardView({ dashboardId });
    const apiRouteFail: string = routeBuilder.api.trackDashboardFail({ dashboardId });

    useEffect(() => {
        axios.post(apiRoute);
    }, [apiRoute]);
    useEffect(() => {
            if(dashboard.dashboardUrl && dashboard.dashboardId) {
                iframeResolutionHelper(dashboard.dashboardUrl, (iframeDoc, status) => {
                    let shouldSendFail = false;
                    if(!status || status && Math.floor(status/100) != 2) {
                        shouldSendFail = true;
                        console.log("Dashboard '" + dashboardId + "' failed because status=" + status);
                    }
                    else if(iframeDoc) {
                        try {
                            var element = (
                                dashboard.dashboardType === DashboardType.TABLEAU ? iframeDoc.getElementById("dashboard-viewport"):
                                dashboard.dashboardType === DashboardType.RSTUDIO ? iframeDoc.getElementById("dashboard-viewport"):
                                {}
                            );
                            shouldSendFail = !element;
                        } catch (e) {
                            console.log("Dashboard '" + dashboardId + "' failed because error=" + e)
                            shouldSendFail = true;
                        }
                    } else {
                        shouldSendFail = true;
                        console.log("Dashboard '" + dashboardId + "' failed because iframe was not accessible")
                    }
                    if (shouldSendFail) {
                        console.log("Dashboard '" + dashboardId + "' failed to load, sending error report")
                        axios.post(apiRouteFail);
                    } else {
                         console.log("Dashboard '" + dashboardId + "' looks to have loaded successfully")
                    }
                });
            }
        }, [apiRouteFail, dashboard]);

    function getDescription(): ReactNode {
        if (dashboardType === DashboardType.TABLEAU) {
            return <EmbeddedTableau dashboardUrl={dashboardUrl}/>;
        } else {
            return <EmbeddedFrame url={dashboardUrl}/>;
        }
    }

    return (
        <BaseModal
            className="dashboard-modal"
            isOpen={true}
            description={getDescription()}
            title={''}
            onClose={onClose}
        />
    );
}

export default DashboardModal;
