import { Spinner } from "@c1/gravity-react";
import { useState } from "react";
import { FunctionComponentProps, FunctionComponentReturnType } from "src/types/sharedReact";

interface EmbeddedFrameProps extends FunctionComponentProps {
    url: string;
}

function EmbeddedFrame(
    {
        url
    }: EmbeddedFrameProps
): FunctionComponentReturnType {
    const [loading, setLoading] = useState<boolean>(true);

    return (
        <>
            <Spinner active={loading} />
            <iframe
                src={url}
                width="100%"
                height="100%"
                frameBorder="0"
                onLoad={() => setLoading(false)}
            />
        </>
    )
}

export default EmbeddedFrame;
