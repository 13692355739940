import React from 'react';
import { HasNameAndCode } from 'src/types/shared';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import TabButton from 'src/components/buttons/TabButton/TabButton';
import clsx from 'clsx';
import { Alignment } from 'src/types/styles';
import 'src/components/buttons/TabList/TabList.css';

interface TabListProps<T = string> extends FunctionComponentProps {
    controlledPanelId: string;
    label: string;
    values: HasNameAndCode<T>[];
    onChange: (newValue: T) => void;
    selectedValue: T;
    alignment: Alignment;
}

function TabList<T = string>(
    {
        controlledPanelId,
        label,
        values,
        onChange,
        selectedValue,
        alignment
    }: TabListProps<T>
): FunctionComponentReturnType {
    return values.length < 2 ?
        null :
        (
            <div
                role="tablist"
                aria-label={label}
                className={clsx(
                    'grv-tabpanel__tablist',
                    alignment === 'center' ?
                        'grv-tabpanel__tablist--center' :
                        `grv-tabpanel__tablist--align-${alignment}`
                )}
            >
                {
                    values.map(x => (
                        <TabButton
                            key={`${x.code}-toggle-button`}
                            controlledPanelId={controlledPanelId}
                            isSelected={selectedValue === x.code}
                            item={x}
                            onClick={onChange}
                        />
                    ))
                }
            </div>
        );
}

export default TabList;
