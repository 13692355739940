import React from 'react';
import Link, { LinkProps } from 'src/components/Link/Link';
import clsx from 'clsx';
import { ButtonType, Spacing } from 'src/types/styles';
import { FunctionComponentReturnType } from 'src/types/sharedReact';

export interface LinkedButtonProps extends Omit<LinkProps, 'to'> {
    className?: string;
    linkTo: LinkProps['to'];
    buttonType?: ButtonType | ButtonType[];
    marginSize?: Spacing;
    compact?: boolean;
}

function LinkedButton(
    {
        className,
        linkTo,
        buttonType,
        marginSize,
        children,
        compact,
        ...linkProps
    }: LinkedButtonProps
): FunctionComponentReturnType {
    return (
        <Link
            to={linkTo}
            id={'copp-button-linked'}
            className={clsx(
                className,
                Array.isArray(buttonType) ?
                    buttonType.map(x => `grv-button--${x}`) :
                    buttonType ? `grv-button--${buttonType}` : `grv-button`,
                marginSize && `grv-margin--${marginSize}`,
                compact && `grv-button--compact`
            )}
            {...linkProps}
        >
            {children}
        </Link>
    );
}

export default LinkedButton;
