import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import ReportsDateSingleSelector
    from 'src/components/ReportsDateSelector/ReportsDateSingleSelector/ReportsDateSingleSelector';
import { DateTime } from 'luxon';
import { Report } from 'src/types/report';

interface ReportsDateRangeSelectorProps extends FunctionComponentProps {
    reports: Report[];
    startValue: DateTime;
    endValue: DateTime;
    onStartChange: (newDate: DateTime) => void;
    onEndChange: (newDate: DateTime) => void;
}

function ReportsDateRangeSelector(
    {
        reports,
        startValue,
        endValue,
        onStartChange,
        onEndChange
    }: ReportsDateRangeSelectorProps
): FunctionComponentReturnType {
    return (
        <>
            <ReportsDateSingleSelector
                label="Start Date"
                value={startValue}
                onChange={onStartChange}
                reports={reports}
            />
            <ReportsDateSingleSelector
                label="End Date"
                value={endValue}
                onChange={onEndChange}
                reports={reports}
            />
        </>
    );
}

export default ReportsDateRangeSelector;
