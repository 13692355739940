import React, { useState } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import ReportsDateRangeSelector
    from 'src/components/ReportsDateSelector/ReportsDateRangeSelector/ReportsDateRangeSelector';
import ReportsDateSingleSelector
    from 'src/components/ReportsDateSelector/ReportsDateSingleSelector/ReportsDateSingleSelector';
import { Checkbox } from '@c1/gravity-react';
import { DateTime } from 'luxon';
import { Report } from 'src/types/report';
import clsx from 'clsx';
import 'src/components/ReportsDateSelector/ReportsDateSelector.css';

interface ReportsDateSelectorProps extends FunctionComponentProps {
    className?: string;
    reports: Report[];
    startValue: DateTime;
    endValue: DateTime;
    onStartChange: (newStartDate: DateTime) => void;
    onEndChange: (newStartDate: DateTime) => void;
}

function ReportsDateSelector(
    {
        className,
        reports,
        startValue,
        endValue,
        onStartChange,
        onEndChange
    }: ReportsDateSelectorProps
): FunctionComponentReturnType {
    const [isDateRange, setIsDateRange] = useState<boolean>(false);

    function setSingleDate(newDate: DateTime): void {
        onStartChange(newDate);
        onEndChange(newDate);
    }

    function onDateRangeToggle(newIsDateRange: boolean): void {
        if (!newIsDateRange) {
            onEndChange(startValue);
        }

        setIsDateRange(newIsDateRange);
    }

    return (
        <div className={clsx('reports-date-selector', className)}>
            {
                isDateRange ?
                    <ReportsDateRangeSelector
                        reports={reports}
                        startValue={startValue}
                        endValue={endValue}
                        onStartChange={onStartChange}
                        onEndChange={onEndChange}
                    /> :
                    <ReportsDateSingleSelector
                        label="Report Date"
                        value={startValue}
                        reports={reports}
                        onChange={setSingleDate}
                    />
            }
            <div className="date-range-toggle">
                <Checkbox
                    label="Search Across Date Range"
                    checked={isDateRange}
                    onChange={onDateRangeToggle}
                />
            </div>
        </div>
    );
}

export default ReportsDateSelector;
