import React, { useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import Form from 'src/components/formElements/Form/Form';
import TextInput from 'src/components/formElements/TextInput/TextInput';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { navigate } from '@reach/router';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FrequencyType, FrequencyTypeCode } from 'src/types/frequencyType';
import FormActionTray from 'src/components/formElements/FormActionTray/FormActionTray';
import Tile from 'src/containers/Tile/Tile';
import { isDuplicateCode, isDuplicateName } from 'src/utils/duplicationValidation';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import _ from 'lodash';

export interface FrequencyTypeFormProps extends FunctionComponentProps {
    frequencyTypes: FrequencyType[];
    frequencyType?: FrequencyType;
}

function FrequencyTypeForm(
    {
        frequencyTypes,
        frequencyType
    }: FrequencyTypeFormProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [frequencyTypeCode, setFrequencyTypeCode] =
        useState<string | null>(frequencyType ? frequencyType.code : null);
    const [frequencyTypeName, setFrequencyTypeName] =
        useState<string | null>(frequencyType ? frequencyType.name : null);

    const submitButtonText = frequencyType ? 'Edit Frequency Type' : 'Add Frequency Type';

    const isCodeDuplicated: boolean = frequencyType ? false : isDuplicateCode(frequencyTypeCode, frequencyTypes);
    const isNameDuplicated: boolean = frequencyType ? false : isDuplicateName(frequencyTypeName, frequencyTypes);

    async function handleSubmit(): Promise<void> {
        const trimmedFrequencyTypeCode = frequencyTypeCode && frequencyTypeCode.trim() as FrequencyTypeCode;
        const trimmedFrequencyTypeName = frequencyTypeName && frequencyTypeName.trim();

        if (isCodeDuplicated || isNameDuplicated || !trimmedFrequencyTypeCode || !trimmedFrequencyTypeName) {
            setFrequencyTypeCode(trimmedFrequencyTypeCode || '');
            setFrequencyTypeName(trimmedFrequencyTypeName || '');
            return;
        }

        const newFrequencyType: FrequencyType = {
            name: trimmedFrequencyTypeName,
            code: trimmedFrequencyTypeCode,
        };

        const upsert: Promise<AxiosResponse> = frequencyType ?
            axios.put(routeBuilder.api.editFrequencyType(), newFrequencyType) :
            axios.post(routeBuilder.api.createFrequencyType(), newFrequencyType);

        await upsert
            .then(() => navigate(routeBuilder.client.toManageFrequencyTypesPage()))
            .catch(console.error);
    }

    return (
        <Tile>
            <Form onSubmit={handleSubmit} name={_.camelCase(submitButtonText)}>
                <TextInput
                    label="Frequency Type Code"
                    value={frequencyTypeCode}
                    onChange={setFrequencyTypeCode}
                    isDuplicate={isCodeDuplicated}
                    disabled={Boolean(frequencyType)}
                    required
                />
                <TextInput
                    label="Frequency Type Name"
                    value={frequencyTypeName}
                    onChange={setFrequencyTypeName}
                    isDuplicate={isNameDuplicated}
                    required
                />
                <FormActionTray
                    cancelButtonLinkTo={routeBuilder.client.toManageFrequencyTypesPage()}
                    submitButtonText={submitButtonText}
                />
            </Form>
        </Tile>
    );
}

export default FrequencyTypeForm;
