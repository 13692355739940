import React from 'react';
import Link from 'src/components/Link/Link';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';
import CardLogo from 'src/components/SelectableCard/CardLogo';
import { HasNameAndCode } from 'src/types/shared';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import 'src/components/SelectableCard/SelectableCard.css';

interface SelectableCardProps {
    item: HasNameAndCode;
    linkTo: string;
    defaultIconType: string;
    imageUrl?: string | null;
}

function SelectableCard(
    {
        item,
        linkTo,
        defaultIconType,
        imageUrl = null
    }: SelectableCardProps
): FunctionComponentReturnType {
    return (
        <Card className="selectable-card" square>
            <CardActionArea
                className="selectable-card-action-area"
                component={Link}
                to={linkTo}
                id={'copp-button-partner-' + item.name.split(' ').join('-')}
                role="link"
            >
                <CardMedia
                    component={CardLogo}
                    imageUrl={imageUrl}
                    item={item}
                    defaultIconType={defaultIconType}
                />
                <CardContent className="selectable-card-content">
                    <Typography component="h2">
                        {item.name}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default SelectableCard;
