import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import CustomerInvoiceForm from 'src/components/CustomerInvoiceForm/CustomerInvoiceForm';
import { GetCustomerInvoicesResponse } from 'src/types/apiResponse';
import { CustomerInvoice } from 'src/types/formRequest';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { getFromApi } from 'src/utils/getFromApi';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';

export default function EditCustomerInvoicePage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetCustomerInvoicesResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getCustomerInvoices();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    let invoice: CustomerInvoice | null = null;
    if (data) {
        invoice = data.invoices.find(invoice => 
                String(invoice.recordId) === routeBuilder.getFormRecordId())
            ?? null;
    }

    return data && invoice && <CustomerInvoiceForm 
        invoices={data.invoices}
        invoice={invoice} />;
}
