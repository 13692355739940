import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import TextInput from 'src/components/formElements/TextInput/TextInput';
import { TableFiltersType } from 'src/types/shared';
import { Dictionary } from 'ts-essentials';
import 'src/components/tables/FilterableColumnHeader/FilterableColumnHeader.css';

export interface FilterableColumnHeaderProps<ItemType extends Dictionary<any>> extends FunctionComponentProps {
    title: ReactNode;
    dataIndex: Exclude<keyof ItemType, number | symbol>;
    filters: TableFiltersType<ItemType>;
    setFilters: Dispatch<SetStateAction<TableFiltersType<ItemType>>>;
}

function FilterableColumnHeader<ItemType extends Dictionary<any>>(
    {
        title,
        dataIndex,
        filters,
        setFilters
    }: FilterableColumnHeaderProps<ItemType>
): FunctionComponentReturnType {
    function handleChange(newValue: string): void {
        setFilters({
            ...filters,
            [dataIndex]: newValue
        });
    }

    const value: string = filters[dataIndex] ?? '';

    return (
        <>
            {
                (typeof title) === 'string' || (typeof title) === 'number' ?
                    <div>{title}</div> :
                    title
            }
            <TextInput
                className="column-header-filter"
                label={'Filter'}
                value={value}
                placeholder="Filter"
                onChange={handleChange}
                hideLabel
            />
        </>
    );
}

export default FilterableColumnHeader;
