import React from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { useNavigationPaneContext } from 'src/contextProviders/NavigationPaneProvider/NavigationPaneContext';
import 'src/components/NavigationPane/MobileMenu.css';
import {
    AuthenticationContextState,
    useAuthenticationContext
} from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';
import { MobileMenuItems } from './NavMenuItems';

function MobileMenu(): FunctionComponentReturnType {
    const { isAuthenticated, user }: AuthenticationContextState = useAuthenticationContext();
    const { isMobileMenuOpen } = useNavigationPaneContext();

    return isAuthenticated && isMobileMenuOpen &&  user ? (
        <div className='navigation-menu-mobile'>
                {isMobileMenuOpen ? <MobileMenuItems /> : <></>}
        </div>           
    ) : null;
}

export default MobileMenu;
