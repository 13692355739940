import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';

function ErrorMessage({ children }: FunctionComponentProps): FunctionComponentReturnType {
    return (
        <span className="grv-textfield__helper--error" role="alert">
            {children || null}
        </span>
    );
}

export default ErrorMessage;
