import React, { EffectCallback, useEffect, useState, useCallback } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';
import SaasUserInvitationsAdminTable from 'src/components/SaasUserInvitationsAdminTable/SaasUserInvitationsAdminTable';
import { GetSaasUserInvitationsAdminResponse } from 'src/types/apiResponse';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import LinkedButton from 'src/components/buttons/LinkedButton/LinkedButton';
import { Spinner } from "@c1/gravity-react";
import { SaasInvitation, SaasUserRole } from 'src/types/saas';
import LinkedButtonWithIcon from 'src/components/buttons/ButtonWithIcon/LinkedButtonWithIcon';

function ManageSaasUserInvitationsPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetSaasUserInvitationsAdminResponse | null>(null);
    const [allUserRoles, setAllUserRoles] = useState<SaasUserRole[] | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRouteUserInvitations: string = routeBuilder.api.getAllSaasUserInvitations();
    const apiRouteUserRoles: string = routeBuilder.api.getAllSaasUserRoles();
    const [downloadLink, setDownloadLink] = useState<string | null>(null);

    const [page, setPage] = useState<number | null>(0);
    const [limit, setLimit] = useState<number | null>(100);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const refreshData: EffectCallback = getFromApi(apiRouteUserInvitations, setData, setError);
    useEffect(getFromApi(apiRouteUserInvitations, setData, setError, {params: {limit, page}}), [apiRouteUserInvitations, page, limit]);
    useEffect(getFromApi(apiRouteUserRoles, setAllUserRoles, setError), [apiRouteUserRoles]);
    useEffect(()=>{
        setIsLoading(data == null)
    },[data]);
    useEffect(() => {
            if (!data) return;
            const dataAsCsvString: string = 'Email,Invitation,Invited Time,Invitation Expiry Time\n' +
                data.users
                    ?.map((invitation: SaasInvitation): string => {
                        return [
                            invitation.emailAddress,
                            invitation.invitationUrl,
                            new Date(invitation.invitationCreationTimeStamp),
                            new Date(invitation.invitationExpirationTimeStamp)
                        ].join(',');
                    })
                    .join('\n');

            const dataAsBlob: Blob = new Blob([dataAsCsvString], { type: 'text/csv' });
            const blobUrl = (window.URL && window.URL.createObjectURL) ?
                window.URL.createObjectURL(dataAsBlob) :
                window.webkitURL.createObjectURL(dataAsBlob);

            setDownloadLink(blobUrl);

            return () => {
                window.URL.revokeObjectURL(blobUrl);
            };
        }, [data]);
    const handleTableUpdate = useCallback(function(event, action){

        if(event === "page") {
            if(action == "prev" && page > 0) {
                setPage(page-1)
                setIsLoading(true)
            }
            else if(action == "next" && data.users.length == limit) {
                setPage(page+1)
                setIsLoading(true)
            }
            else if (action == "first" && page != 0) {
                setPage(0)
                setIsLoading(true)
            }
        }
    },[page, setPage, setIsLoading, data])

    if (error) {
        throw error;
    }

    return (
        <div>
            <p>Invited users will receive an invite link to create their account and set their password.</p>
            <p>The invite link is also available below to share with new users, if needed.</p>
            <ActionTray>
                {
                    downloadLink &&
                    <LinkedButtonWithIcon
                        iconType="LinkDownloadLined"
                        buttonType="action"
                        download="userInvitations.csv"
                        linkTo={downloadLink}
                    >
                        {'Download'}
                    </LinkedButtonWithIcon>
                }
            </ActionTray>
            {
                data?.users?.length ?
                    <SaasUserInvitationsAdminTable
                        userInvitations={data.users}
                        refreshData={refreshData}
                        allUserRoles={allUserRoles}
                        totalPages={-1}
                        currentPage={page+1}
                        tableUpdate={handleTableUpdate}
                    /> :
                    <NoResultsFound/>
            }
            <br />
            <br />
            <ActionTray>
                <LinkedButton
                    className="form-go-back-button"
                    linkTo={routeBuilder.client.toManageSaasUsersPage()}
                    buttonType="regressive"
                >
                    {'Go Back'}
                </LinkedButton>
            </ActionTray>
            <Spinner active={isLoading} />
        </div>
    );
}

export default ManageSaasUserInvitationsPage;
