import React from 'react';
import { isMobile } from 'react-device-detect';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import CancelLinkedButton from 'src/components/buttons/CancelButton/CancelLinkedButton';
import { Button } from '@c1/gravity-react';
import 'src/components/formElements/FormActionTray/FormActionTray.css';

export interface FormActionTrayProps extends FunctionComponentProps {
    cancelButtonLinkTo?: string;
    submitButtonText?: string;
    submitButtonLoading?: boolean;
}

function FormActionTray(
    {
        cancelButtonLinkTo,
        submitButtonText = 'Submit',
        submitButtonLoading = false
    }: FormActionTrayProps
): FunctionComponentReturnType {

    return (
        <ActionTray className="form-action-tray">
            {cancelButtonLinkTo && !isMobile ? <CancelLinkedButton
                className="form-cancel-button"
                linkTo={cancelButtonLinkTo}
            /> : null }
            <Button
                htmlButtonType="submit"
                id={'copp-button-submit'}
                loading={submitButtonLoading}
                disabledOnLoading
            >
                {submitButtonText}
            </Button>
        </ActionTray>
    );
}

export default FormActionTray;
