import { createContext, useContext } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';

const RouteBuilderContext = createContext<RouteBuilder>(new RouteBuilder());

export function useRouteBuilder(): RouteBuilder {
    return useContext(RouteBuilderContext);
}

export default RouteBuilderContext;
