import React, { useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { GetFrequentlyAskedQuestionsAdminResponse } from 'src/types/apiResponse';
import FrequentlyAskedQuestionForm from 'src/components/FrequentlyAskedQuestionForm/FrequentlyAskedQuestionForm';
import { FrequentlyAskedQuestion } from 'src/types/frequentlyAskedQuestion';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';

function EditFrequentlyAskedQuestionPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetFrequentlyAskedQuestionsAdminResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getAllFrequentlyAskedQuestionsAdmin();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    let frequentlyAskedQuestion: FrequentlyAskedQuestion | null = null;
    if (data) {
        frequentlyAskedQuestion = data.faqs.find(x => {
            return x.id === routeBuilder.getFrequentlyAskedQuestionId();
        }) || null;
    }

    return data && frequentlyAskedQuestion && <FrequentlyAskedQuestionForm
        frequentlyAskedQuestions={data.faqs}
        frequentlyAskedQuestion={frequentlyAskedQuestion}
    />;
}

export default EditFrequentlyAskedQuestionPage;
