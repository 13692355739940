import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { HasNameAndCode } from 'src/types/shared';
import clsx from 'clsx';

interface TabButtonProps<T = string> extends FunctionComponentProps {
    controlledPanelId: string;
    isSelected?: boolean;
    item: HasNameAndCode<T>;
    onClick: (newValue: T) => void;
}

function TabButton<T = string>(
    {
        controlledPanelId,
        isSelected = false,
        item,
        onClick
    }: TabButtonProps<T>
): FunctionComponentReturnType {
    return (
        <button
            aria-selected={isSelected}
            role="tab"
            aria-controls={controlledPanelId}
            id={'copp-button-tab-' + item.code}
            className={clsx(
                'grv-tabpanel__tab',
                isSelected && 'grv-tabpanel__tab--active'
            )}
            onClick={() => onClick(item.code)}
        >
            {item.name}
        </button>
    );
}

export default TabButton;
