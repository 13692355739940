import React from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import Link from 'src/components/Link/Link';
import Tile from 'src/containers/Tile/Tile';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import 'src/pages/HomePage/HomePage.css';
import { Card } from '@c1/gravity-react';
import dashboardsImage from '../../assets/images/home-page-new-dashboards.png';
import reportsImage from '../../assets/images/home-page-reports.jpg';
import faqsImage from '../../assets/images/home-page-new-faqs.png';
import reportAnIssueImage from '../../assets/images/home-page-report-an-issue.jpg';
import { isMobile } from 'react-device-detect';
import useGetScreenInfo, { screenInfo, ScreenType } from 'src/utils/responsive';
import { isUserAuthorizedToSeeMenuItem } from 'src/utils/userAuthorizationUtils';
import { pageMetadatas } from 'src/utils/routing/pageMetadatas';
import { CLIENT_PATHS } from 'src/utils/routing/routes';
import AuthenticationContext, { AuthenticationContextState, useAuthenticationContext } from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';

function HomePage(): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const { isAuthenticated, user }: AuthenticationContextState = useAuthenticationContext();
    const [hasCheckedAuthentication, setHasCheckedAuthentication] = React.useState<boolean>(false);
    let isDesktop = !isMobile;
    let screenInfo: screenInfo = useGetScreenInfo();
    let cardClass = isMobile || (isDesktop && screenInfo.width < ScreenType.MEDIUM) ? "home-page-card-links-mobile" : "home-page-card-links-desktop";

    React.useEffect(()=>{
        if(user!= null && user.partnerAccess != null) {
            let hasAccessToReports = isUserAuthorizedToSeeMenuItem(user, { metadata: pageMetadatas[CLIENT_PATHS.SELECT_PARTNER_PAGE] });
            let hasAccessToDashboards = isUserAuthorizedToSeeMenuItem(user, { metadata: pageMetadatas[CLIENT_PATHS.DASHBOARDS_SELECT_PARTNER_PAGE] });
            // If user only has access to Dashboards, redirect to Dashboards page
            if(isAuthenticated && user && user.partnerAccess != null && hasAccessToDashboards && !hasAccessToReports) {
                window.location.replace(routeBuilder.client.toDashboardSelectPartnerPage());
            }
            // If a user only has access to Reports, redirect to Reports page
            else if(isAuthenticated && user && user.partnerAccess != null && hasAccessToReports && !hasAccessToDashboards) {
                 window.location.replace(routeBuilder.client.toDashboardSelectPartnerPage());
             }
             else {
                setHasCheckedAuthentication(true);
            }
        }
    },[user, isAuthenticated])

    let homePageDescriptionFull =
        `Capital One Partner Portal (COPP) is a content delivery platform with the capability of
        allowing external and internal users to securely view and download reports belonging to various
        categories. Users use Capital One SSO or their assigned service accounts to log in, and based
        on the access permissions registered for the logged in user, respective Partners and Reports
        are made available for selection and download.`;

    let homePageDescriptionMobile =
        `Capital One Partner Portal (COPP) is a content delivery platform enabling users
        to securely view reports and dashboards.`

    return hasCheckedAuthentication && (
        <Tile className="home-page-tile">
            <p>
                { isDesktop ? homePageDescriptionFull : homePageDescriptionMobile }
            </p>

            <div className={cardClass}>
                <Link
                    className="home-page-promotional-image-link"
                    id={'copp-button-home-page-dashboards'}
                    to={routeBuilder.client.toDashboardSelectPartnerPage()}>
                    <Card heading={'Dashboards'} media={`url(${dashboardsImage})`}/>
                </Link>

                {
                    isDesktop && isUserAuthorizedToSeeMenuItem(user, { metadata: pageMetadatas[CLIENT_PATHS.SELECT_PARTNER_PAGE] }) &&
                    <Link
                        className="home-page-promotional-image-link"
                        id={'copp-button-home-page-reports'}
                        to={routeBuilder.client.toPartnerSelectionPage()}
                    >
                        <Card heading={'Reports'} media={`url(${reportsImage})`}/>
                    </Link>
                }

                <Link
                    className="home-page-promotional-image-link"
                    id={'copp-button-home-page-faq'}
                    to={routeBuilder.client.toFrequentlyAskedQuestionPage()}
                >
                    <Card heading={'FAQs'} media={`url(${faqsImage})`}/>
                </Link>

                <Link
                    className="home-page-promotional-image-link"
                    id={'home-page-report-an-issue'}
                    to={routeBuilder.client.toReportAnIssuePage()}
                >
                    <Card heading={'Report an Issue'} media={`url(${reportAnIssueImage})`}/>
                </Link>

            </div>
        </Tile>
    );
}

export default HomePage;
