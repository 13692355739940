import React, { EffectCallback, useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { GetUsersAdminResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import UsersAdminTable from 'src/components/UsersAdminTable/UsersAdminTable';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';
import ManageUsersPageActionTray from 'src/pages/ManageUsersPage/ManageUsersPageActionTray';
import { Spinner } from "@c1/gravity-react";

function ManageUsersPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetUsersAdminResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getAllUsersAdmin();

    const refreshData: EffectCallback = getFromApi(apiRoute, setData, setError);
    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    return (
        <div>
            <ManageUsersPageActionTray data={data}/>
            {
                data?.users.length ?
                    <UsersAdminTable users={data.users} refreshData={refreshData}/> :
                    <NoResultsFound/>
            }
            <Spinner active={data == null} />
        </div>
    );
}

export default ManageUsersPage;
