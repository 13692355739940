import { AuthenticatedUser } from 'src/utils/AuthenticatedUser';
import axios from 'axios';
import { GetIsUserAuthenticatedResponse, GetUserResponse } from 'src/types/apiResponse';
import { GetPartnersResponse } from 'src/types/apiResponse';
export async function handleAuthentication(
    getIsUserAuthenticatedRoute: string,
    getUserRoute: string,
    getPartnersAccessRoute: string,
    setIsAuthenticated: (newIsAuthenticated: boolean) => void,
    setUser: (newUser: (AuthenticatedUser | null)) => void,
    loginPath: string = '',
    callback: ()  => void
): Promise<void> {

    await axios.get<GetIsUserAuthenticatedResponse>(getIsUserAuthenticatedRoute, { withCredentials: true })
        .then((isUserAuthenticatedRes) => {
            if (!isUserAuthenticatedRes || !isUserAuthenticatedRes.data || !isUserAuthenticatedRes.data.status) {
                if (process.env.REACT_APP_FLAG_OPEN_TENANT_LOGIN_FLOW === 'true') {
                    return goToAuthenticationPage(setUser, setIsAuthenticated);
                } else {
                    return forceAuthentication(setUser, setIsAuthenticated, loginPath);
                }
            }

            return axios.get<GetUserResponse>(getUserRoute, { withCredentials: true })
                .then((userRes) => {
                    let wasUserSet = false;
                    if(userRes && userRes.data && userRes.data.user) {
                        axios.get<GetPartnersResponse>(getPartnersAccessRoute, { withCredentials: true })
                        .then((partnerAccessResponse) => {
                            if(partnerAccessResponse && partnerAccessResponse.data) {
                                let authenticatedUser = new AuthenticatedUser(userRes.data.user, partnerAccessResponse.data);
                                setUser(authenticatedUser);
                                wasUserSet = true;
                            }
                        })
                        if (!wasUserSet) {
                            setUser(new AuthenticatedUser(userRes.data.user, null));
                        }
                    }
                })
                .catch((e) => {
                    setUser(null);
                    console.error(e);
                })
                .finally(() => {
                    callback();
                    setIsAuthenticated(true);
                });
        })
        .catch((e) => {
            callback();
            if (process.env.REACT_APP_FLAG_OPEN_TENANT_LOGIN_FLOW === 'true') {
                return goToAuthenticationPage(setUser, setIsAuthenticated);
            } else {
                return forceAuthentication(setUser, setIsAuthenticated, loginPath);
            }
        });
}

function forceAuthentication(
    setUser: (user: AuthenticatedUser | null) => void,
    setIsAuthenticated: (isAuthenticated: boolean) => void,
    loginRoute: string
): void {
    setUser(null);
    setIsAuthenticated(false);
    window.location.assign(loginRoute);
}

function goToAuthenticationPage(
    setUser: (user: AuthenticatedUser | null) => void,
    setIsAuthenticated: (isAuthenticated: boolean) => void,
): void {
    setUser(null);
    setIsAuthenticated(false);
    window.location.assign('/login');
}
