import React from 'react';
import ReactDOM from 'react-dom';
import 'src/index.css';
import App from 'src/App';

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
