import React from 'react';
import { Container } from '@material-ui/core';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import 'src/components/Footer/Footer.css';

function Footer(): FunctionComponentReturnType {
    return (
        <footer>
            <Container>
                <p className="footer-copyright">{`©${new Date().getFullYear()} Capital One`}</p>
                <p className="footer-disclaimer">
                    {
                        'Use of this system is restricted to authorized users of Capital One and its ' +
                        'subsidiaries, and for authorized purposes. Users must have no expectation of privacy ' +
                        'with respect to such usage. All software, data transactions and other electronic ' +
                        'communications may be monitored without notice to the user. Programs, data and ' +
                        'information contained herein are the property of Capital One and must be treated as ' +
                        'strictly confidential. Unauthorized use is prohibited, and violators may be subject to ' +
                        'civil and criminal prosecution.'
                    }
                </p>
            </Container>
        </footer>
    );
}

export default Footer;
