import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { LinkProps } from 'src/components/Link/Link';
import LinkedButtonWithIcon from 'src/components/buttons/ButtonWithIcon/LinkedButtonWithIcon';

export interface AddItemLinkedButtonProps extends FunctionComponentProps {
    linkTo: LinkProps['to'];
}

function AddItemLinkedButton(
    {
        children,
        linkTo
    }: AddItemLinkedButtonProps
): FunctionComponentReturnType {
    return (
        <LinkedButtonWithIcon linkTo={linkTo} iconType="PlusFilled" buttonType="action" id={'copp-button-add-item-button'}>
            {children}
        </LinkedButtonWithIcon>
    );
}

export default AddItemLinkedButton;
