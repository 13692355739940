export interface SaasUser {
    userId: string;
    emailAddress: string;
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    roles: SaasUserRole[];
}

export interface SaasUserRoleId {
    roleId: string;
}

export interface SaasUserRole {
    roleId: string;
    roleName: string;
    roleDescription: string;
}

export interface SaasInvitationRequest {
    inviterName: string;
    emailAddress: string;
    userRoleIds: string[];
}

export interface SaasInvitation {
    inviterName: string;
    emailAddress: string;
    userRoleIds: string[];

    invitationUrl: string | null;
    invitationCreationTimeStamp: Date | null;
    invitationExpirationTimeStamp: Date | null;
    invitationId: string | null;
}

enum SaasRoles {
    STORE_MANAGER = "Store Manager",
    CORP_MANAGER = "Corporate Manager",
    PARTNER_MANAGER = "Partner Manager"
};

export const SaasRoleSortOrder: {[key: string]: number} = {
    [SaasRoles.STORE_MANAGER]: 1,
    [SaasRoles.CORP_MANAGER]: 2,
    [SaasRoles.PARTNER_MANAGER]: 3,
};
