import React, { EffectCallback, useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import PartnersAdminTable from 'src/components/PartnersAdminTable/PartnersAdminTable';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import { GetPartnersAdminResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import AddItemLinkedButton from 'src/components/buttons/AddItemLinkedButton/AddItemLinkedButton';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';

function ManagePartnersPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetPartnersAdminResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getAllPartnersAdmin();

    const refreshData: EffectCallback = getFromApi(apiRoute, setData, setError);
    useEffect(refreshData, [apiRoute]);

    if (error) {
        throw error;
    }

    return (
        <>
            <ActionTray>
                <AddItemLinkedButton linkTo={routeBuilder.client.toCreatePartnerPage()}>
                    {'Add Partner'}
                </AddItemLinkedButton>
            </ActionTray>
            {
                data && (
                    data.partners.length ?
                        <PartnersAdminTable partners={data.partners} refreshData={refreshData}/> :
                        <NoResultsFound/>
                )
            }
        </>
    );
}

export default ManagePartnersPage;
