import React from 'react';
import { HasNameAndCode } from 'src/types/shared';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import Icon from 'src/components/icons/Icon/Icon';
import 'src/components/SelectableCard/CardLogo.css';

interface CardLogoProps extends FunctionComponentProps {
    item: HasNameAndCode;
    defaultIconType: string;
    imageUrl?: string | null;
}

function getLogo(code: string): string | null {
  let logo = null;
  try {
    logo = require(`../../assets/images/logos/${code}.svg`).default;
  } catch (e) {}

  if (!logo)
    try {
      logo = require(`../../assets/images/logos/${code}.png`).default;
    } catch (e) {}
  return logo;
}

function CardLogo({ item, defaultIconType, imageUrl }: CardLogoProps): FunctionComponentReturnType {
    const logo: string | null = imageUrl || getLogo(item.code);
    return (
        <div className="card-logo-wrapper">
            {
                logo ? (
                    <img
                        className="card-logo"
                        src={logo}
                        alt=""
                        role="presentation"
                    />
                ) : (
                    <Icon
                        className="card-logo-placeholder"
                        type={defaultIconType}
                    />
                )
            }
        </div>
    );
}

export default CardLogo;
