import React, { useRef, useEffect, useState, ChangeEvent, FC } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import axios, { AxiosError } from 'axios';
import AlertBanner, { AlertBannerType } from 'src/components/AlertBanner/AlertBanner';
import InformationModal from 'src/components/modals/InformationModal/InformationModal';
import { Button, Input, Spinner } from '@c1/gravity-react';
import Tile from 'src/containers/Tile/Tile';
import { Partner } from 'src/types/partner';
import '../UploadLogo/UploadLogo.css'

interface UploadLogoProps {
    disabled: boolean;
    partnerCode: string;
    partner: Partner;
}

const UploadLogo: React.FC<UploadLogoProps> = ({disabled, partnerCode, partner}) => {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File>();
    const [bannerType, setBannerType] = useState<AlertBannerType>("success");
    const [bannerMessage, setBannerMessage] = useState<string>("");
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const [showCurrentMarketingModal, setShowCurrentMarketingModal] = useState<boolean>(false);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [showNewMarketingModal, setShowNewMarketingModal] = useState<boolean>(false);
    const [currentMarketingImage, setCurrentMarketingImage] = useState();
    const logoButtonText = partner ? 'Change Logo' : 'Add Logo';
    const apiLatestPartnerLogo: string = routeBuilder.api.getLatestPartnerLogo(partnerCode);
    const [isValid, setIsValid] = useState<boolean>(true);

    const openInputFileDialog = () => {
        if(partnerCode != null){
            setIsValid(true)
            if (inputFileRef.current != null) {
                inputFileRef.current.click();
            }
        } else {
            setIsValid(false)
        }
    };

    const uploadLogo = (file: File) => {
        if (file == null) return;
        setIsUploading(true);
        let uploadEndpoint = routeBuilder.api.uploadPartnerLogo(partnerCode);

        const formData = new FormData();
        formData.append('File', file);
        axios.post(uploadEndpoint, formData)
            .then((res) => {
                setIsLoading(true);
                setBannerType("success");
                setBannerMessage(`Upload success!`);
                setShowBanner(true);
                setTimeout(() => {
                    setIsLoading(false);
                    setShowBanner(false);
                }, 2000);
            })
            .catch((e) => {
                setSelectedFile(undefined);
                setBannerType("error");
                setBannerMessage(`Error uploading new Marketing image`);
                setShowBanner(true);
                setTimeout(() => {
                    setShowBanner(false);
                }, 2000);
            })
            .finally(() => {
                setIsUploading(false);
            });
    }

    useEffect(() => {
        if (partner != null) {
        setIsLoading(true);
        setShowCurrentMarketingModal(false)
        setShowNewMarketingModal(false);
        setCurrentMarketingImage(undefined);
        axios.get(apiLatestPartnerLogo, { responseType: 'blob' })
            .then((res) => {
                setSelectedFile(res.data);
                setIsLoading(false);
            })
            .catch(async (error: AxiosError) => {
                if (error.response.status === 404) {
                    setBannerType("informational");
                    setBannerMessage(`No Partner Logo has been uploaded yet - Please upload below.`);
                    setShowBanner(true);
                    setIsLoading(false);
                    setTimeout(() => {
                        setShowBanner(false);
                    }, 2000);
                } else {
                    setBannerType("error");
                    setBannerMessage(`Unknown error - Please contact support team.`);
                    setShowBanner(true);
                    setIsLoading(false);
                    setTimeout(() => {
                        setShowBanner(false);
                    }, 2000);
                }
            })
        }
    }, [apiLatestPartnerLogo]);

    const inputFileOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null) {
            const allowedFileTypes: string[] = [ "png" ];
            const fileName = event.target.files[0].name;
            const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
            setSelectedFile(undefined);
            if (allowedFileTypes.includes(fileExtension)) {
                setSelectedFile(event.target.files[0]);
                uploadLogo(event.target.files[0]);
            }
            else {
                setSelectedFile(undefined);
                setBannerType("error");
                setBannerMessage(`Invalid file type (${fileExtension}) -- Please upload a PNG image.`);
                setShowBanner(true);
                setTimeout(() => {
                    setShowBanner(false);
                }, 2000);
            }
        }
    };

    return (

        <Tile className="manage-partner-container">
                {
                    selectedFile &&
                        <div className="manage-partner-preview">
                            <img className="manage-partner-preview-image" src={URL.createObjectURL(selectedFile)} alt="Pending Marketing Promotion" />
                            <InformationModal
                                description=""
                                isOpen={showNewMarketingModal}
                                title=" "
                                onClose={() => setShowNewMarketingModal(false)}
                            >
                                <img src={URL.createObjectURL(selectedFile)} alt="Preview Marketing Promotion" />
                            </InformationModal>
                        </div>

                }
                <div className="manage-partner-action-tray">
                    <Button
                        disabled={disabled}
                        type="action"
                        onClick={openInputFileDialog}
                        id={'copp-button-change-logo'}
                    >
                        {logoButtonText}
                    </Button>
                </div>
                <AlertBanner
                    type={bannerType}
                    message={bannerMessage}
                    active={showBanner}
                />
                <Input
                    type="file"
                    ref={inputFileRef}
                    onChange={inputFileOnChangeHandler}
                    className="manage-marketing-input-file-hidden"
                />
                {
                    partner == null && currentMarketingImage &&
                    <InformationModal
                            description=""
                            isOpen={showCurrentMarketingModal}
                            title=" "
                            onClose={() => setShowCurrentMarketingModal(false)}
                        >
                            <img src={URL.createObjectURL(currentMarketingImage)} alt="Latest Marketing Promotion" />
                    </InformationModal>
                }
                <Spinner active={isLoading} />
            </Tile>
        )};


export default UploadLogo;
