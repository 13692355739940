import React, { ReactElement, ReactNode } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { ScheduledReportMonitorDTO } from 'src/types/scheduledReportMonitor';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import MonitorTable, { ColumnTitleAndDataIndex } from 'src/components/tables/MonitorTable/MonitorTable';

export interface PartnersMonitorsTableProps extends FunctionComponentProps {
    monitors: ScheduledReportMonitorDTO[];
    refreshData: () => void;
}

function PartnersMonitorsTable({ monitors, refreshData }: PartnersMonitorsTableProps): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    const columns: ColumnTitleAndDataIndex<ScheduledReportMonitorDTO>[] = [
        { title: 'Partner', dataIndex: 'partnerCode' },
        { title: 'Report Category', dataIndex: 'categoryCode' },
        { title: 'Alert Frequency Number', dataIndex: 'frequencyNumber' },
        { title: 'Alert Frequency Day', dataIndex: 'frequencyDay' },
        { title: 'Alert Frequency Type', dataIndex: 'frequencyTypeCode' },
    ];

    function getKey(item: ScheduledReportMonitorDTO): string {
        return `partners-monitor-table-row-${item.reportMonitorId}`;
    }

    const modalTitle: string = 'Confirm Delete Report Alert';

    function getModalDescription(item: ScheduledReportMonitorDTO): ReactNode {
        return `Are you sure you want to delete alert for ${item.categoryCode} for ${item.partnerCode}?`;
    }

    function getApiRouteToDelete(item: ScheduledReportMonitorDTO): string {
        return routeBuilder.api.deleteScheduledReportMonitor({
            reportMonitorId: item.reportMonitorId
        });
    }

    return (
        <>
            <MonitorTable
                columns={columns}
                items={monitors}
                getKey={getKey}
                modalTitle={modalTitle}
                getModalDescription={getModalDescription}
                getApiRouteToDelete={getApiRouteToDelete}
                refreshData={refreshData}
            />
        </>
    );
}

export default PartnersMonitorsTable;
