import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import WalmartDisputesForm from 'src/components/WalmartDisputesForm/WalmartDisputesForm';
import { GetWalmartDisputesResponse } from 'src/types/apiResponse';
import { WalmartDispute } from 'src/types/formRequest';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { getFromApi } from 'src/utils/getFromApi';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';

export default function EditWalmartDisputesPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetWalmartDisputesResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getWalmartDisputes();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    let dispute: WalmartDispute | null = null;
    if (data) {
        dispute = data.disputes.find(dispute => 
                String(dispute.recordId) === routeBuilder.getFormRecordId())
            ?? null;
    }

    return data && dispute && <WalmartDisputesForm 
        disputes={data.disputes}
        dispute={dispute} />;
}
