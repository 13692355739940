import { HasLabelAndValue } from "src/types/shared";

export const FREQ_TYPE_OPTIONS: HasLabelAndValue[] = [
    { label: "Day", value: "DLY" },
    { label: "Week", value: "WLY" },
    { label: "Month", value: "MLY" }
];

export const FREQ_NUMBER_OPTIONS: HasLabelAndValue[] = [
    { label: "First", value: "1" },
    { label: "Second", value: "2" },
    { label: "Third", value: "3" },
    { label: "Fourth", value: "4" },
    { label: "Last", value: "-1" }
]

export const FREQ_DAY_OPTIONS_WLY: HasLabelAndValue[] = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" }
];

export const FREQ_DAY_OPTIONS_MLY: HasLabelAndValue[] = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Business Day", value: "Business Day" }
];