import React from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import ErrorMessage from 'src/components/errors/ErrorMessage';

function RequiredFieldError(): FunctionComponentReturnType {
    return (
        <ErrorMessage>
            {'This field is required.'}
        </ErrorMessage>
    );
}

export default RequiredFieldError;
