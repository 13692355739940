import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import clsx from 'clsx';

interface FormFieldProps extends FunctionComponentProps {
    className?: string;
}

function FormField({ className, children }: FormFieldProps): FunctionComponentReturnType {
    return (
        <div className={clsx('grv-form-field', className)}>
            {children || null}
        </div>
    );
}

export default FormField;
