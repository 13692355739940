import React, { EffectCallback, useEffect, useState, useCallback} from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';
import ManageSaasUsersPageActionTray from './ManageSaasUsersPageActionTray';
import SaasUsersAdminTable from 'src/components/SaasUsersAdminTable/SaasUsersAdminTable';
import { SaasUser, SaasUserRole } from 'src/types/saas';
import { Spinner } from "@c1/gravity-react";
import TenantStepper from 'src/components/TenantStepper/TenantStepper';
import { GetSaasUsersAdminResponse } from 'src/types/apiResponse';

function ManageSaasUsersPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetSaasUsersAdminResponse | null>(null);
    const [allUsers, setAllUsers] = useState<GetSaasUsersAdminResponse | null>(null);
    const [allUserRoles, setAllUserRoles] = useState<SaasUserRole[] | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const [page, setPage] = useState<number | null>(0);
    const [limit, setLimit] = useState<number | null>(50);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getSaasUsers();
    const apiRouteUserRoles: string = routeBuilder.api.getAllSaasUserRoles();
    const apiRouteAllUsers: string = routeBuilder.api.getAllSaasUsers();

    const refreshData: EffectCallback = getFromApi(apiRoute, setData, setError);
    useEffect(getFromApi(apiRoute, setData, setError, {params: {limit, page}}), [apiRoute, limit, page]);
    useEffect(getFromApi(apiRouteUserRoles, setAllUserRoles, setError), [apiRouteUserRoles]);
    useEffect(getFromApi(apiRouteAllUsers, setAllUsers, setError), [apiRouteAllUsers]);
    useEffect(()=>{
        setIsLoading(data == null)
    },[data]);
    const handleTableUpdate = useCallback(function(event, action){

        if(event === "page") {
            if(action == "prev" && page > 0) {
                setPage(page-1)
                setIsLoading(true)
            }
            else if(action == "next" && page <= Math.floor(data?.count/limit)-1) {
                setPage(page+1)
                setIsLoading(true)
            }
            else if (action == "first" && page != 0) {
                setPage(0)
                setIsLoading(true)
            }
            else if (action == "last" && page != (Math.floor(data?.count/limit)+1)-1) {
                setPage((Math.floor(data?.count/limit)+1)-1)
                setIsLoading(true)
            }
        }
    },[page, setPage, data, limit])
    if (error) {
        throw error;
    }
    return (
        <div>
            <TenantStepper selectedTenant={routeBuilder.getTenantId()}/>
            <ManageSaasUsersPageActionTray data={allUsers?.users}/>
            {
                data?.users?.length ?
                    <SaasUsersAdminTable
                        users={data.users}
                        refreshData={refreshData}
                        allUserRoles={allUserRoles}
                        totalPages={Math.floor(data?.count/limit)+1}
                        currentPage={page+1}
                        tableUpdate={handleTableUpdate}
                    /> :
                    <NoResultsFound/>
            }
            <Spinner active={isLoading} />
        </div>
    );
}

export default ManageSaasUsersPage;
