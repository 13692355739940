import { EffectCallback, useEffect, useState } from 'react';
import BannerForm from 'src/components/BannerForm/BannerForm';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { GetBannerResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';
import { AlertBannerType } from 'src/components/AlertBanner/AlertBanner';

function ManageBannerPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetBannerResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [alertType, setAlertType] = useState<AlertBannerType>('success');
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getCurrentBanner();

    const refreshData: EffectCallback = getFromApi(apiRoute, handleData, setError);
    useEffect(refreshData, [apiRoute]);

    function handleData(data: GetBannerResponse) {
        setIsLoading(true);
        setData(data);
        setIsLoading(false);
    }

    function handleSubmitRefresh(alertType: AlertBannerType, alertMessage: string) {
        setAlertType(alertType);
        setAlertMessage(alertMessage);
        setShowAlert(true);
        refreshData();
    }

    if (error) {
        throw error;
    }

    return (
        <>
            {
                !isLoading && (
                    <BannerForm
                        banner={data?.banners[0] ? data.banners[0] : null}
                        bannerTypes={data ? data.bannerTypes : null}
                        refreshData={handleSubmitRefresh}
                        message={alertMessage}
                        messageType={alertType}
                        showMessage={showAlert}
                    />)
            }
        </>
    );
}

export default ManageBannerPage;
