import React, { useEffect, useState } from 'react';
import ReportCategorySelection from 'src/components/ReportCategorySelection/ReportCategorySelection';
import { AxiosError } from 'axios';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { GetReportCategoriesResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import ReportStepper from 'src/components/ReportStepper/ReportStepper';
import { ReportCategory } from 'src/types/reportCategory';
import { redirectTo } from '@reach/router';
import { getFromApi } from 'src/utils/getFromApi';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';

function SelectReportCategoryPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetReportCategoriesResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getReportCategoriesForPartner();

    function handleData(data: GetReportCategoriesResponse): void {
        const reportCategories: ReportCategory[] = data.categories;

        if (reportCategories && reportCategories.length === 1 && !reportCategories[0].externalLink) {
            redirectTo(routeBuilder.client.toReportSelectionPage({
                reportCategoryCode: reportCategories[0].code
            }));
            return;
        }

        setData(data);
    }

    useEffect(getFromApi(apiRoute, handleData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    return (
        <>
            <ReportStepper
                selectedAccessType={data && data.selectedAccessType}
                selectedPartner={data && data.selectedPartner}
            />
            {
                data && (
                    data.categories.length ?
                        <ReportCategorySelection reportCategories={data.categories}/> :
                        <NoResultsFound/>
                )
            }
        </>
    );
}

export default SelectReportCategoryPage;
