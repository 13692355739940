import React from 'react';
import LinkedButton from 'src/components/buttons/LinkedButton/LinkedButton';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import Icon from 'src/components/icons/Icon/Icon';
import clsx from 'clsx';
import { ButtonType, Spacing } from 'src/types/styles';
import { LinkProps } from 'src/components/Link/Link';
import 'src/components/buttons/ButtonWithIcon/ButtonWithIcon.css';

export interface LinkedButtonWithIconProps extends Omit<LinkProps, 'to'> {
    className?: string;
    iconType: string;
    linkTo: LinkProps['to'];
    buttonType: ButtonType | ButtonType[];
    marginSize?: Spacing;
}

function LinkedButtonWithIcon(
    {
        className,
        children,
        iconType,
        linkTo,
        buttonType,
        marginSize,
        ...otherLinkProps
    }: LinkedButtonWithIconProps
): FunctionComponentReturnType {
    return (
        <LinkedButton
            className={clsx(className, 'button-with-icon')}
            linkTo={linkTo}
            buttonType={buttonType}
            marginSize={marginSize}
            {...otherLinkProps}
        >
            <Icon className="button-icon" type={iconType}/>
            {children}
        </LinkedButton>
    );
}

export default LinkedButtonWithIcon;
