import React, { useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import Form from 'src/components/formElements/Form/Form';
import TextInput from 'src/components/formElements/TextInput/TextInput';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { ReportCategory } from 'src/types/reportCategory';
import { navigate } from '@reach/router';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import FormActionTray from 'src/components/formElements/FormActionTray/FormActionTray';
import Tile from 'src/containers/Tile/Tile';
import { isDuplicateCode, isDuplicateName } from 'src/utils/duplicationValidation';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { Checkbox } from '@c1/gravity-react';
import _ from 'lodash';
import MultiItemTextInput from "src/components/formElements/MultiItemTextInput/MultiItemTextInput";

interface ReportCategoryFormProps extends FunctionComponentProps {
    reportCategories: ReportCategory[];
    reportCategory?: ReportCategory;
}

function cleanUrl(url: string): string {
    let output = url.trim();

    if (!output.length) {
        return output;
    }

    const disallowedLeadingOrTrailingCharacters = '!"#$%&\'()*+,-./@:;<=>[\\]^_`{|}~';
    const startOffset = disallowedLeadingOrTrailingCharacters.includes(output[0]) ? 1 : 0;
    const endOffset = disallowedLeadingOrTrailingCharacters.includes(output[output.length - 1]) ? 1 : 0;

    return (startOffset || endOffset) ? cleanUrl(output.substring(startOffset, output.length - endOffset)) : output;
}

function ReportCategoryForm(
    {
        reportCategories,
        reportCategory
    }: ReportCategoryFormProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [reportCategoryCode, setReportCategoryCode] =
        useState<string | null>(reportCategory ? reportCategory.code : null);
    const [reportCategoryName, setReportCategoryName] =
        useState<string | null>(reportCategory ? reportCategory.name : null);
    const [isExternalReportCategory, setIsExternalReportCategory] =
        useState<boolean>(Boolean(reportCategory && reportCategory.externalLink));
    const [externalLink, setExternalLink] =
        useState<string | null>(reportCategory ? reportCategory.externalLink : null);
    const [ownerEmails, setOwnerEmails] =
        useState<string[] | null>(reportCategory ? reportCategory.ownerEmails : null);

    const submitButtonText = reportCategory ? 'Edit Report Category' : 'Add Report Category';

    const isCodeDuplicated: boolean = reportCategory ? false : isDuplicateCode(reportCategoryCode, reportCategories);
    const isNameDuplicated: boolean = reportCategory ? false : isDuplicateName(reportCategoryName, reportCategories);

    function handleIsExternalReportCategoryCheckbox(newIsExternalReportCategory: boolean): void {
        if (!newIsExternalReportCategory) {
            setExternalLink(null);
        }
        setIsExternalReportCategory(newIsExternalReportCategory);
    }

    async function handleSubmit(): Promise<void> {
        const trimmedReportCategoryCode = reportCategoryCode && reportCategoryCode.trim();
        const trimmedReportCategoryName = reportCategoryName && reportCategoryName.trim();
        const trimmedExternalLink = externalLink && cleanUrl(externalLink);
        const nullSafeOwnerEmails = _.compact(ownerEmails || []);

        if (
            isCodeDuplicated ||
            isNameDuplicated ||
            !trimmedReportCategoryCode ||
            !trimmedReportCategoryName ||
            (isExternalReportCategory && !trimmedExternalLink)
        ) {
            setReportCategoryCode(trimmedReportCategoryCode || '');
            setReportCategoryName(trimmedReportCategoryName || '');
            setExternalLink(trimmedExternalLink || '');
            setOwnerEmails(nullSafeOwnerEmails);
            return;
        }

        const newReportCategory: ReportCategory = {
            name: trimmedReportCategoryName,
            code: trimmedReportCategoryCode,
            frequencyTypes: reportCategory ? reportCategory.frequencyTypes : [],
            partners: reportCategory ? reportCategory.partners : [],
            externalLink: trimmedExternalLink || '',
            ownerEmails: nullSafeOwnerEmails
        };

        const upsert: Promise<AxiosResponse> = reportCategory ?
            axios.put(routeBuilder.api.editReportCategory(), newReportCategory) :
            axios.post(routeBuilder.api.createReportCategory(), newReportCategory);

        await upsert
            .then(() => navigate(routeBuilder.client.toManageReportCategoriesPage()))
            .catch(console.error);
    }

    return (
        <Tile>
            <Form onSubmit={handleSubmit} name={_.camelCase(submitButtonText)}>
                <TextInput
                    label="Report Category Code"
                    value={reportCategoryCode}
                    onChange={setReportCategoryCode}
                    isDuplicate={isCodeDuplicated}
                    disabled={Boolean(reportCategory)}
                    required
                />
                <TextInput
                    label="Report Category Name"
                    value={reportCategoryName}
                    onChange={setReportCategoryName}
                    isDuplicate={isNameDuplicated}
                    required
                />
                <MultiItemTextInput
                    label="Owner Email(s)"
                    value={ownerEmails}
                    onChange={setOwnerEmails}
                    buttonLabel="Add Owner Email"
                />
                <Checkbox
                    label="Check this box if this report category is on another website."
                    checked={isExternalReportCategory}
                    onChange={handleIsExternalReportCategoryCheckbox}
                />
                {
                    isExternalReportCategory &&
                    <TextInput
                        label="External Link"
                        helper="External link is required if this report category is on another website."
                        value={externalLink}
                        onChange={setExternalLink}
                        required
                    />
                }
                <FormActionTray
                    cancelButtonLinkTo={routeBuilder.client.toManageReportCategoriesPage()}
                    submitButtonText={submitButtonText}
                />
            </Form>
        </Tile>
    );
}

export default ReportCategoryForm;
