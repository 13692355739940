import { FormEvent, useState } from "react";
import { FunctionComponentProps, FunctionComponentReturnType } from "src/types/sharedReact";
import RouteBuilder from "src/utils/routing/RouteBuilder";
import { useRouteBuilder } from "src/utils/routing/RouteBuilderContext";
import DropdownSelect from "../formElements/DropdownSelect/DropdownSelect";
import Form from "../formElements/Form/Form";
import 'src/components/ManageReportAlertsForm/ManageReportAlertsForm.css';
import { Divider } from "@c1/gravity-react";
import { ScheduledReportMonitorDTO } from "src/types/scheduledReportMonitor";
import { FREQ_DAY_OPTIONS_MLY, FREQ_DAY_OPTIONS_WLY, FREQ_NUMBER_OPTIONS, FREQ_TYPE_OPTIONS } from "src/utils/reportMonitoringUtils";
import { ReportCategory } from "src/types/reportCategory";

/*
    -- on Truthiness
    -- undefined is used for fields without interaction yet
    -- '' is used for empty fields, that fail validation when required
    -- null is used for unused fields in the API call
*/

interface ManageReportAlertsFormProps extends FunctionComponentProps {
    formId: string;
    reportCategory: ReportCategory;
    onSuccess?: () => void;
    isNew: boolean;
    monitor: ScheduledReportMonitorDTO;
    addMonitorToCreate?: (monitor: ScheduledReportMonitorDTO) => void;
    addMonitorToUpdate?: (monitor: ScheduledReportMonitorDTO) => void;
    ownerEmails: string[];
}

// References "ManageDashboardForm.tsx"
function ManageReportAlertsForm(
    {
        formId,
        reportCategory,
        onSuccess,
        isNew,
        monitor,
        addMonitorToCreate,
        addMonitorToUpdate,
        ownerEmails
    }: ManageReportAlertsFormProps): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    const [frequencyType, setFrequencyType] = useState<string | undefined>(monitor ? monitor.frequencyTypeCode : undefined);
    const [frequencyDay, setFrequencyDay] = useState<string | undefined>(monitor ? monitor.frequencyDay : undefined);
    const [frequencyNumber, setFrequencyNumber] = useState<string | undefined>(monitor ? monitor.frequencyNumber : undefined);

    function handleFrequencyTypeChange(_frequencyTypeCode: string) {
        setFrequencyDay(undefined);
        setFrequencyNumber(undefined);
        setFrequencyType(_frequencyTypeCode);
    }

    async function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.stopPropagation();

        if (!frequencyType) {
            setFrequencyType('');
            return;
        } else if (frequencyType == 'WLY') {
            if (!frequencyDay) {
                setFrequencyDay('');
                return;
            }
        } else if (frequencyType == 'MLY') {

            if (!frequencyDay || !frequencyNumber) {
                if (!frequencyDay) setFrequencyDay('');
                if (!frequencyNumber) setFrequencyNumber('');
                return;
            }
        }

        const scheduledReportMonitorDTO: ScheduledReportMonitorDTO = {
            reportMonitorId: monitor?.reportMonitorId ?? undefined,
            partnerCode: routeBuilder.getPartnerCode(),
            categoryCode: reportCategory.code,
            frequencyDay: frequencyDay ? frequencyDay : null,
            frequencyNumber: frequencyNumber ? frequencyNumber : null,
            frequencyTypeCode: frequencyType
        }

        monitor ? addMonitorToUpdate(scheduledReportMonitorDTO) : addMonitorToCreate(scheduledReportMonitorDTO);
        onSuccess();
    }

    return (
        <Form className="report-alert-form" onSubmit={handleSubmit} name={formId} id={formId}>
            Send alert at <b>10:00 AM EST</b> for "{reportCategory.name}"
            <br /><br />
            <DropdownSelect
                label="each..."
                options={FREQ_TYPE_OPTIONS}
                value={frequencyType}
                onChange={handleFrequencyTypeChange}
                required={true}
                placeholder="e.g. Day, Week, Month"
            />
            {
                (frequencyType === "WLY" && <>
                    <DropdownSelect
                        label={"on..."}
                        options={FREQ_DAY_OPTIONS_WLY}
                        value={frequencyDay}
                        onChange={setFrequencyDay}
                        required={true}
                        placeholder="frequency  (Mon, Tue...)"
                    />
                </>
                )
            }
            {
                (frequencyType === "MLY" &&
                    <>
                        <DropdownSelect
                            label="on the..."
                            options={FREQ_NUMBER_OPTIONS}
                            value={frequencyNumber}
                            onChange={setFrequencyNumber}
                            required={true}
                            placeholder="frequency number (1st, 2nd...)"
                        />
                        <DropdownSelect
                            label={""}
                            options={FREQ_DAY_OPTIONS_MLY}
                            value={frequencyDay}
                            onChange={setFrequencyDay}
                            required={true}
                            placeholder="frequency  (Mon, Tue...)"
                        />
                    </>
                )
            }
            if the report is not yet uploaded that day.
            <Divider />
            {
                ownerEmails.length > 0 ?
                    <>
                        <i>Alert will be sent to the following email addresses:</i>
                        <ul>
                            {
                                ownerEmails.map(email => (
                                    <li>{email}</li>
                                ))
                            }
                        </ul>
                    </>
                    :
                    <>
                        <i>NOTE: No Report Owners for this Report Category.
                            <br />Alerts will not be sent to any email addresses.</i>
                    </>
            }
        </Form>
    )
}

export default ManageReportAlertsForm;