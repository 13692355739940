import { Button } from "@c1/gravity-react";
import { AxiosError } from "axios";
import { EffectCallback, useEffect, useRef, useState } from "react";
import DashboardsAdminTable from "src/components/DashboardsAdminTable/DashboardsAdminTable";
import ManageDashboardForm from "src/components/ManageDashboardForm/ManageDashboardForm";
import ActionTray from "src/containers/ActionTray/ActionTray";
import { GetDashboardsResponse } from "src/types/apiResponse";
import { Dashboard } from "src/types/dashboard";
import { FunctionComponentReturnType } from "src/types/sharedReact";
import { getFromApi } from "src/utils/getFromApi";
import RouteBuilder from "src/utils/routing/RouteBuilder";
import { useRouteBuilder } from "src/utils/routing/RouteBuilderContext";
import { ManageDashboardFormRef } from 'src/components/ManageDashboardForm/ManageDashboardForm';
import FormModal from "src/components/modals/FormModal/FormModal";
import ConfirmDeleteModal from "src/components/modals/ConfirmDeleteModal/ConfirmDeleteModal";

function ManageDashboardPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetDashboardsResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [dashboard, setDashboard] = useState<Dashboard | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getAllDashboardsAdmin();

    const refreshData: EffectCallback = getFromApi(apiRoute, setData, setError);
    useEffect(refreshData, [apiRoute]);

    const formRef = useRef<ManageDashboardFormRef>(null);

    function handleClickDelete(dashboard: Dashboard) {
        setDashboard(dashboard);
        setShowDeleteModal(true);
    }

    function handleClickEdit(dashboard: Dashboard) {
        setDashboard(dashboard);
        setShowEditModal(true);
    }

    function handleCancelModal() {
        if (showCreateModal) setShowCreateModal(false);
        if (showEditModal) setShowEditModal(false);
        if (showDeleteModal) setShowDeleteModal(false);
        if (dashboard) setDashboard(null);
        formRef.current?.resetForm();
    }

    function handleSubmitRefresh() {
        refreshData();
        handleCancelModal();
    }

    if (error) throw error;

    return (
        <>
            <ActionTray>
                <Button
                    htmlButtonType="button"
                    onClick={() => setShowCreateModal(true)}
                    id={'copp-button-add-dashboard'}
                >
                    Add Dashboard
                </Button>
            </ActionTray>
            {
                data && (
                    data.dashboards.length ?
                        <DashboardsAdminTable
                            dashboards={data.dashboards}
                            onClickDelete={handleClickDelete}
                            onClickEdit={handleClickEdit}
                        /> :
                        null
                )
            }

            <FormModal
                active={showCreateModal}
                title="Add Dashboard"
                formId="manage-dashboard-form-create"
                onCancel={handleCancelModal}
            >
                <ManageDashboardForm
                    formId="manage-dashboard-form-create"
                    refreshData={handleSubmitRefresh}
                    ref={formRef}
                />
            </FormModal>

            <FormModal
                active={showEditModal}
                title="Edit Dashboard"
                formId="manage-dashboard-form-edit"
                okButtonType="progressive"
                onCancel={handleCancelModal}
            >
                {
                    dashboard &&
                        <ManageDashboardForm
                            formId="manage-dashboard-form-edit"
                            dashboard={dashboard}
                            refreshData={handleSubmitRefresh}
                            ref={formRef}
                        />
                }
            </FormModal>

            <ConfirmDeleteModal
                isOpen={showDeleteModal}
                title="Delete Dashboard"
                description={`Are you sure you want to delete "${dashboard?.dashboardName}" for ${dashboard?.partner.name} (${dashboard?.partner.type})?`}
                apiRouteToDelete={routeBuilder.api.deleteDashboard({ dashboardId: dashboard?.dashboardId })}
                onClose={handleCancelModal}
                onAfterDelete={handleSubmitRefresh}
            />
        </>
    )
}

export default ManageDashboardPage;
