import React, { EffectCallback, useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import ReportSelection from 'src/components/ReportSelection/ReportSelection';
import { GetReportsResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import ReportStepper from 'src/components/ReportStepper/ReportStepper';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import AlertBanner from 'src/components/AlertBanner/AlertBanner';

function DownloadReportsPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetReportsResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getReports();

    const refreshData: EffectCallback = getFromApi(apiRoute, setData, setError);
    useEffect(refreshData, [apiRoute]);

    const [downloadErrorMessage, setDownloadErrorMessage] = useState<string | null>(null);
    useEffect(() => {
        if (!!downloadErrorMessage) closeDownloadErrorAlertAfterSeconds(5)
    }, [downloadErrorMessage]);

    function closeDownloadErrorAlertAfterSeconds(seconds: number) {
        setTimeout(() => setDownloadErrorMessage(null), seconds * 1000);
    }

    function handleDownloadError(message: string) {
        setDownloadErrorMessage(message);
    }

    if (error) {
        throw error;
    }

    return (
        <>
            <AlertBanner
                type="error"
                message={downloadErrorMessage!}
                active={!!downloadErrorMessage}
            />
            <ReportStepper
                selectedAccessType={data && data.selectedAccessType}
                selectedPartner={data && data.selectedPartner}
                selectedReportCategory={data && data.selectedCategory}
            />
            {
                data ? (
                    Object.keys(data.reports).length ? (
                        <ReportSelection
                            frequencyTypes={data.frequencyTypes}
                            reports={data.reports}
                            latestReport={data.latestReport}
                            refreshData={refreshData}
                            handleDownloadError={handleDownloadError}
                        />
                    ) : <NoResultsFound/>
                ) : <LoadingIndicator/>
            }
        </>
    );
}

export default DownloadReportsPage;
