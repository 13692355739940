import React from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import ErrorMessage from 'src/components/errors/ErrorMessage';

function DuplicateValueError(): FunctionComponentReturnType {
    return (
        <ErrorMessage>
            {'This value is already in use.'}
        </ErrorMessage>
    );
}

export default DuplicateValueError;
