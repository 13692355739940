import React from 'react';
import Link from 'src/components/Link/Link';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import Tile from 'src/containers/Tile/Tile';
import { CLIENT_PATHS, PATH_TO_PAGE_NAME_MAP } from 'src/utils/routing/routes';
import RouteBuilder from 'src/utils/routing/RouteBuilder';

function AdminPage(): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    return (
        <Tile>
            <p>
                <Link to={routeBuilder.client.toManagePartnersPage()}>
                    {PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.MANAGE_PARTNERS_PAGE]}
                </Link>
            </p>
            <p>
                <Link to={routeBuilder.client.toManageReportCategoriesPage()}>
                    {PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.MANAGE_REPORT_CATEGORIES_PAGE]}
                </Link>
            </p>
            <p>
                <Link to={routeBuilder.client.toManageExternalUsersPage()}>
                    {PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.MANAGE_EXTERNAL_USERS_PAGE]}
                </Link>
            </p>
            <p>
                <Link to={routeBuilder.client.toManageFrequencyTypesPage()}>
                    {PATH_TO_PAGE_NAME_MAP[CLIENT_PATHS.MANAGE_FREQUENCY_TYPES_PAGE]}
                </Link>
            </p>
        </Tile>
    );
}

export default AdminPage;
