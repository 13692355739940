import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import React, { EffectCallback, ReactNode, useEffect, useState } from 'react';
import AdminTable, { ColumnTitleAndDataIndex } from 'src/components/tables/AdminTable/AdminTable';
import AddItemLinkedButton from 'src/components/buttons/AddItemLinkedButton/AddItemLinkedButton';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import { GetCustomerInvoicesResponse } from 'src/types/apiResponse';
import { CustomerInvoiceTable } from 'src/types/formRequest';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { formatDate } from 'src/utils/datetime';
import { getFromApi } from 'src/utils/getFromApi';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { CLIENT_PATHS } from 'src/utils/routing/routes';

function CustomerInvoiceFormPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetCustomerInvoicesResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getCustomerInvoices();

    const refreshData: EffectCallback = getFromApi(apiRoute, setData, setError);
    useEffect(refreshData, [apiRoute]);

    if (error) throw error;

    const columns: ColumnTitleAndDataIndex<CustomerInvoiceTable>[] = [
        { title: 'Account Number', dataIndex: 'accountNumber' },
        { title: 'Start Date', dataIndex: 'startDate' },
        { title: 'End Date', dataIndex: 'endDate' }
    ];

    function getKey(item: CustomerInvoiceTable): string {
        return `customer-invoice-${item.recordId}-${item.accountNumber}`;
    }

    const modalTitle = 'Confirm Delete';

    function getModalDescription(item: CustomerInvoiceTable): ReactNode {
        return `Are you sure you want to delete request for ${item.accountNumber}?`;
    }

    function getApiRouteToDelete(item: CustomerInvoiceTable): string {
        return routeBuilder.api.deleteCustomerInvoice({
            recordId: item.recordId?.toString()
        });
    }

    function getEditButtonLinkTo(item: CustomerInvoiceTable): string {
        return routeBuilder.client.toEditCustomerInvoicePage({
            recordId: String(item.recordId)
        });
    }

    return (
        <>
            <p>Please be advised that the following requests will be processed at the end of day
                on {formatDate(DateTime.now())}</p>
            <ActionTray>
                <AddItemLinkedButton linkTo={CLIENT_PATHS.CREATE_CUSTOMER_INVOICE_PAGE}>
                    New Request
                </AddItemLinkedButton>
            </ActionTray>
            <AdminTable
                columns={columns}
                items={data ?
                    data.invoices.map(i => ({
                        ...i,
                        startDate: formatDate(i.startDate),
                        endDate: formatDate(i.endDate)
                    })) : []}
                getKey={getKey}
                getEditButtonLinkTo={getEditButtonLinkTo}
                modalTitle={modalTitle}
                getModalDescription={getModalDescription}
                getApiRouteToDelete={getApiRouteToDelete}
                refreshData={refreshData}
            />
        </>
    );
}

export default CustomerInvoiceFormPage;
