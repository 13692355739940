import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import clsx from 'clsx';
import 'src/containers/Tile/Tile.css';

export interface TileProps extends FunctionComponentProps {
    className?: string;
    id?: string;
}

function Tile({ className, id, children }: TileProps): FunctionComponentReturnType {
    return (
        <div
            className={clsx('tile', className)}
            id={clsx(id)}
        >
            {children || null}
        </div>
    );
}

export default Tile;
