import {FunctionComponentProps, FunctionComponentReturnType} from "src/types/sharedReact";
import TextInput from "src/components/formElements/TextInput/TextInput";
import FormField from "src/components/formElements/FormField/FormField";
import React, {MouseEvent} from "react";
import {Button} from "@c1/gravity-react";
import 'src/components/formElements/MultiItemTextInput/MultiItemTextInput.css'
import Icon from "src/components/icons/Icon/Icon";

interface MultiItemTextInputProps extends FunctionComponentProps {
    label: string;
    value: string[] | null;
    onChange: (newValue: string[]) => void;
    buttonLabel: string;
}

function MultiItemTextInput(
    {
        label,
        value,
        onChange,
        buttonLabel
    }: MultiItemTextInputProps
): FunctionComponentReturnType {
    const inputValues = value && value.length ? [...value] : [""];

    function handleChange(newValue: string, index: number) {
        inputValues[index] = newValue;
        onChange(inputValues);
    }

    function handleAddItemClick(event: MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        if (inputValues[inputValues.length - 1].trim() === '') {
            return;
        }

        inputValues.push("");
        onChange(inputValues);
    }

    function handleRemoveItemClick(event: MouseEvent<HTMLButtonElement>, index: number): void {
        event.preventDefault();
        inputValues.splice(index, 1);
        onChange(inputValues);
    }

    return (
        <FormField>
            <div>
                {
                    inputValues.map((x, index) => {
                        return (
                            <div className="multi-item-text-input-item">
                                <TextInput
                                    className="multi-item-text-input"
                                    key={index}
                                    label={index === 0 ? label : ''}
                                    value={x}
                                    onChange={(newValue: string) => handleChange(newValue, index)}
                                />
                                <div className="remove-item-icon">
                                    <Icon
                                        type="MinusFilled"
                                        onClick={(event: MouseEvent<HTMLButtonElement>) => handleRemoveItemClick(event,index)}
                                        alt={'RemoveItem'}
                                    />
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div className="add-item-icon">
                <Button
                    className="multi-item-text-input-add-button"
                    id={'copp-button-add-item'}
                    onClick={handleAddItemClick}
                    compact
                >
                    {buttonLabel}
                </Button>
            </div>
        </FormField>
    );
}

export default MultiItemTextInput;
