import React, { ReactNode, useState } from 'react';
import axios from 'axios';
import DeleteButton from 'src/components/buttons/DeleteButton/DeleteButton';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import CancelButton from 'src/components/buttons/CancelButton/CancelButton';
import BaseModal from 'src/components/modals/BaseModal/BaseModal';
import { Spinner } from '@c1/gravity-react';

interface ConfirmDeleteModalProps extends FunctionComponentProps {
    apiRouteToDelete: string | null;
    description: ReactNode;
    title: string;
    isOpen: boolean;
    onClose: () => void;
    onAfterDelete: () => void;
}

function ConfirmDeleteModal(
    {
        apiRouteToDelete,
        description,
        isOpen,
        title,
        onClose,
        onAfterDelete
    }: ConfirmDeleteModalProps
): FunctionComponentReturnType {
    const [loading, setLoading] = useState<boolean>(false);

    const onDelete = async (): Promise<void> => {
        if (!apiRouteToDelete) {
            return onClose();
        }

        setLoading(true);
        await axios.delete(apiRouteToDelete)
            .then(() => {
                setLoading(false);
                onAfterDelete();
            })
            .catch(console.error)
            .finally(onClose);
    };

    return (
        <>
            <BaseModal
                CloseButtonComponent={CancelButton}
                ConfirmButtonComponent={DeleteButton}
                description={description}
                isOpen={isOpen}
                title={title}
                onClose={onClose}
                onConfirm={onDelete}
            />
            <Spinner active={loading} />
        </>
    );
}

export default ConfirmDeleteModal;
