import React, { ReactElement } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { HasLabelAndValue } from 'src/types/shared';
import RequiredFieldError from 'src/components/errors/RequiredFieldError';
import { Radio } from '@c1/gravity-react';
import DuplicateValueError from 'src/components/errors/DuplicateValueError';
import { Falsy } from 'src/types/falsy';
import 'src/components/formElements/RadioButtonGroup/RadioButtonGroup.css';
import _ from 'lodash';

interface RadioButtonGroupProps extends FunctionComponentProps {
    label: string;
    value: string | null;
    options: HasLabelAndValue[];
    onChange: (newValue: string) => void;
    disabled?: boolean;
    errors?: (ReactElement | Falsy)[];
    isDuplicate?: boolean;
    required?: boolean;
}

function RadioButtonGroup(
    {
        label,
        options,
        disabled = false,
        errors = [],
        isDuplicate = false,
        required = false,
        value,
        onChange
    }: RadioButtonGroupProps
): FunctionComponentReturnType {
    const isRequiredFieldError = required && (value?.trim() === '');

    const parsedErrors = [
        isRequiredFieldError && <RequiredFieldError key={'required-field-error'}/>,
        isDuplicate && <DuplicateValueError key={'duplicate-value-error'}/>,
        ...errors
    ].filter(x => x);

    return (
        <Radio.Group
            className="radio-button-group"
            legend={label}
            value={value}
            onChange={onChange}
            error={parsedErrors.length ? parsedErrors : null}
        >
            {
                options.map((option: HasLabelAndValue) => (
                    <Radio
                        key={`${option.label}-${option.value}`}
                        name={_.camelCase(label)}
                        label={option.label}
                        value={option.value}
                        disabled={disabled}
                    />
                ))
            }
        </Radio.Group>
    );
}

export default RadioButtonGroup;
