import React, { Component, ErrorInfo, ReactNode } from 'react';
import TechnicalDifficultiesPage from 'src/pages/TechnicalDifficultiesPage/TechnicalDifficultiesPage';
import 'src/containers/ErrorBoundary/ErrorBoundary.css';
import { AxiosError } from 'axios';

export interface ErrorBoundaryState {
    error: Error | AxiosError | null;
}

class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
    constructor(props: {}) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: Error | AxiosError): ErrorBoundaryState {
        return { error };
    }

    componentDidCatch(error: Error, info: ErrorInfo): void {
        console.error(error, info);
    }

    is400Error = (): boolean => {
        const { error } = this.state;

        if (!error) {
            return false;
        }

        if (
            ('isAxiosError' in error && error.isAxiosError) && (
                (error.code && error.code.includes('400 ')) ||
                (error.response && error.response.status === 400)
            )
        ) {
            return true;
        }

        return error.message ? error.message.includes('400') : false;
    };

    render(): ReactNode {
        const { error } = this.state;
        const { children } = this.props;

        if (error) {
            return (
                <TechnicalDifficultiesPage errorMessage={this.is400Error() ? undefined : error.message}/>
            );
        }

        return <>{children}</>;
    }
}

export default ErrorBoundary;
