import { EffectCallback } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { RedirectRequest } from '@reach/router';

export function getFromApi<T>(
    route: string,
    handleSuccess: (data: T) => void,
    handleError: (e: AxiosError | null) => void,
    config: AxiosRequestConfig = {}
): EffectCallback {
    return (): ReturnType<EffectCallback> => {
        let componentIsUnmounted: boolean = false;
        const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
        axios.get<T>(route, { cancelToken: cancelTokenSource.token, ...config })
            .then((res: AxiosResponse<T>) => {
                if (componentIsUnmounted) {
                    return;
                }
                handleSuccess(res.data);
                handleError(null);
            })
            .catch((e: AxiosError<T> | RedirectRequest) => {
                if (componentIsUnmounted) {
                    return;
                }

                if ('uri' in e) {
                    window.location.replace(e.uri);
                    return;
                }

                handleError(e);
            });

        return (): void => {
            componentIsUnmounted = true;
            cancelTokenSource.cancel();
        };
    };
}
