export function throwIfInvalidRouteOrQueryParameter(item: Record<string, any | undefined>): void {
    const properties: string[] = Object.keys(item);

    if (!properties.length) {
        throw new Error('Object has no properties.');
    }

    const missingProperties: string[] = properties.filter(x => {
        const property = item[x];
        return property === undefined ||
            property === null ||
            property === '' ||
            (Array.isArray(property) && !property.length);
    });

    if (missingProperties.length) {
        throw new Error(`\nThe following properties are missing or empty:\n${missingProperties.join('\n')}\n`);
    }
}
