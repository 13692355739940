import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import clsx from 'clsx';
import 'src/containers/CenteredWrapper/CenteredWrapper.css';

export interface CenteredWrapperProps extends FunctionComponentProps {
    className?: string;
    id?: string;
}

function CenteredWrapper(
    {
        className,
        id,
        children
    }: CenteredWrapperProps
): FunctionComponentReturnType {
    return (
        <div
            id={clsx(id)}
            className={clsx('centered-wrapper', className)}
        >
            {children || null}
        </div>
    );
}

export default CenteredWrapper;
