import { FunctionComponentProps, FunctionComponentReturnType } from "src/types/sharedReact";
import { CLIENT_PATHS } from "src/utils/routing/routes";
import LinkedStepper from "../LinkedStepper/LinkedStepper";

export interface DashboardStepperProps extends FunctionComponentProps {
    selectedAccessType?: string | null;
    selectedPartner?: string | null;
}

function DashboardStepper(
    {
        selectedAccessType,
        selectedPartner
    }: DashboardStepperProps
): FunctionComponentReturnType {
    const selectedLabels: string[] = [];

    if (selectedPartner && selectedAccessType) {
        selectedLabels.push(`${selectedPartner} (${selectedAccessType})`);
    } else if (selectedPartner) {
        selectedLabels.push(selectedPartner);
    }

    return (
        <LinkedStepper
            steps={[
                CLIENT_PATHS.DASHBOARDS_SELECT_PARTNER_PAGE,
                CLIENT_PATHS.DASHBOARDS_SELECT_PAGE,
            ]}
            optionalLabels={selectedLabels}
        />
    )
}

export default DashboardStepper;