import React, { ReactElement, ReactNode } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { ReportCategory } from 'src/types/reportCategory';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import UnorderedList from 'src/components/UnorderedList/UnorderedList';
import AdminTable, { ColumnTitleAndDataIndex } from 'src/components/tables/AdminTable/AdminTable';
import { Partner } from 'src/types/partner';

export interface ReportCategoriesAdminTableProps extends FunctionComponentProps {
    reportCategories: ReportCategory[];
    refreshData: () => void;
}

function ReportCategoriesAdminTable(
    {
        reportCategories,
        refreshData
    }: ReportCategoriesAdminTableProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    const columns: ColumnTitleAndDataIndex<ReportCategory>[] = [
        { title: 'Report Category Code', dataIndex: 'code' },
        { title: 'Report Category Name', dataIndex: 'name' },
        { title: 'Associated Partners', dataIndex: 'partners' },
        { title: 'External Link', dataIndex: 'externalLink' },
        { title: 'Owner Email', dataIndex: 'ownerEmails'}
    ];

    function getKey(item: ReportCategory): string {
        return `report-categories-admin-table-row-${item.code}`;
    }

    function getEditButtonLinkTo(item: ReportCategory): string {
        return routeBuilder.client.toEditReportCategoryPage({
            reportCategoryCode: item.code
        });
    }

    const modalTitle = 'Confirm Delete Report Category';

    function getModalDescription(item: ReportCategory): ReactNode {
        return `Are you sure you want to delete ${item.name} (${item.code})?`;
    }

    function getApiRouteToDelete(item: ReportCategory): string {
        return routeBuilder.api.deleteReportCategory({
            reportCategoryCode: item.code
        });
    }

    function overrideItemToFilter(item: ReportCategory): Omit<ReportCategory, 'partners' | 'ownerEmails'> & { partners: string; ownerEmails: string } {
        return {
            ...item,
            partners: item.partners.map((x: Partner) => `${x.name} (${x.type})`).join(' '),
            ownerEmails: item.ownerEmails.join(' ')
        };
    }

    function overrideTableDataSourceMappedFields(item: ReportCategory): { partners: ReactElement; ownerEmails: ReactElement } {
        return {
            partners: (
                <UnorderedList
                    items={item.partners.map((x: Partner) => `${x.name} (${x.type})`)}
                    marginSize="none"
                />
            ),
            ownerEmails: (
                <UnorderedList
                    items={item.ownerEmails}
                    marginSize="none"
                />
            )
        };
    }

    return (
        <>
            <AdminTable
                columns={columns}
                items={reportCategories}
                getKey={getKey}
                getEditButtonLinkTo={getEditButtonLinkTo}
                modalTitle={modalTitle}
                getModalDescription={getModalDescription}
                getApiRouteToDelete={getApiRouteToDelete}
                refreshData={refreshData}
                overrideItemToFilter={overrideItemToFilter}
                overrideTableDataSourceMappedFields={overrideTableDataSourceMappedFields}
            />
        </>
    );
}

export default ReportCategoriesAdminTable;
