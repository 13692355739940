import { CLIENT_PATHS, ClientPath } from 'src/utils/routing/routes';
import HomePage from 'src/pages/HomePage/HomePage';
import SelectPartnerPage from 'src/pages/SelectPartnerPage/SelectPartnerPage';
import SelectTenantPage from 'src/pages/SelectTenantPage/SelectTenantPage';
import SelectReportCategoryPage from 'src/pages/SelectReportCategoryPage/SelectReportCategoryPage';
import DownloadReportsPage from 'src/pages/DownloadReportsPage/DownloadReportsPage';
import DashboardSelectPartnerPage from 'src/pages/DashboardsPages/DashboardSelectPartnerPage';
import DashboardSelectionPage from 'src/pages/DashboardsPages/DashboardSelectionPage';
import MyAccountPage from 'src/pages/MyAccountPage/MyAccountPage';
import AdminPage from 'src/pages/AdminPage/AdminPage';
import ManagePartnersPage from 'src/pages/ManagePartnersPage/ManagePartnersPage';
import ManageReportAlertsPage from "../../pages/ManageReportAlertsPage/ManageReportAlertsPage";
import ManageReportCategoriesPage from 'src/pages/ManageReportCategoriesPage/ManageReportCategoriesPage';
import ManageFrequencyTypesPage from 'src/pages/ManageFrequencyTypesPage/ManageFrequencyTypesPage';
import ManageUsersPage from 'src/pages/ManageUsersPage/ManageUsersPage';
import ManageFrequentlyAskedQuestionsPage
    from 'src/pages/ManageFrequentlyAskedQuestionsPage/ManageFrequentlyAskedQuestionsPage';
import CreatePartnerPage from 'src/pages/CreatePartnerPage/CreatePartnerPage';
import CreateReportCategoryPage from 'src/pages/CreateReportCategoryPage/CreateReportCategoryPage';
import CreateFrequencyTypePage from 'src/pages/CreateFrequencyTypePage/CreateFrequencyTypePage';
import CreateUserPage from 'src/pages/CreateUserPage/CreateUserPage';
import CreateFrequentlyAskedQuestionPage
    from 'src/pages/CreateFrequentlyAskedQuestionPage/CreateFrequentlyAskedQuestionPage';
import EditPartnerPage from 'src/pages/EditPartnerPage/EditPartnerPage';
import EditReportCategoryPage from 'src/pages/EditReportCategoryPage/EditReportCategoryPage';
import EditFrequencyTypePage from 'src/pages/EditFrequencyTypePage/EditFrequencyTypePage';
import EditUserPage from 'src/pages/EditUserPage/EditUserPage';
import EditFrequentlyAskedQuestionPage from 'src/pages/EditFrequentlyAskedQuestionPage/EditFrequentlyAskedQuestionPage';
import MarketplacePage from 'src/pages/MarketplacePage/MarketplacePage';
import ManageDashboardPage from 'src/pages/ManageDashboardPage/ManageDashboardPage';
import ManageSaasUsersPage from 'src/pages/ManageSaasUsersPage/ManageSaasUsersPage';
import ManageSaasUserInvitationsPage from 'src/pages/ManageSaasUserInvitationsPage/ManageSaasUserInvitationsPage';
import InviteSaasUserPage from 'src/pages/InviteSaasUserPage/InviteSaasUserPage';
import EditSaasUserPage from 'src/pages/EditSaasUserPage/EditSaasUserPage';
import MarketingSelectPartnerPage from 'src/pages/MarketingSelectPartnerPage/MarketingSelectPartnerPage';
import SelectOperationalFormPage from 'src/pages/SelectOperationalFormPage/SelectOperationalFormPage';
import CustomerInvoiceFormPage from 'src/pages/CustomerInvoiceFormPage/CustomerInvoiceFormPage';
import CreateCustomerInvoicePage from 'src/pages/CustomerInvoiceFormPage/CreateCustomerInvoicePage';
import EditCustomerInvoicePage from 'src/pages/CustomerInvoiceFormPage/EditCustomerInvoicePage';
import WalmartDisputesFormPage from 'src/pages/WalmartDisputesFormPage/WalmartDisputesFormPage';
import CreateWalmartDisputesPage from 'src/pages/WalmartDisputesFormPage/CreateWalmartDisputePage';
import EditWalmartDisputesPage from 'src/pages/WalmartDisputesFormPage/EditWalmartDisputesPage';
import FrequentlyAskedQuestionsPage from 'src/pages/FrequentlyAskedQuestionsPage/FrequentlyAskedQuestionsPage';
import ReportAnIssuePage from 'src/pages/ReportAnIssuePage/ReportAnIssuePage';
import LogoutPage from 'src/pages/LogoutPage/LogoutPage';
import LoginPage from 'src/pages/LoginPage/LoginPage';
import { PageMetadata } from 'src/utils/routing/PageMetadata';
import { FEATURES } from 'src/utils/feature';
import ThoughtSpotLiveBoard from 'src/containers/thoughtSpot/ThoughtSpotLiveBoard';
import ManageMarketingContentPage from 'src/pages/ManageMarketingContentPage/ManageMarketingContentPage';
import ManageBannerPage from 'src/pages/ManageBannerPage/ManageBannerPage';

type PageMetadatas = Partial<{ [key in ClientPath]: PageMetadata; }>;


const corePages: PageMetadatas = {
    [CLIENT_PATHS.HOME_PAGE]: new PageMetadata(
        HomePage,
        CLIENT_PATHS.HOME_PAGE,
        FEATURES.NO_FEATURE_REQUIRED,
        { menuIcon: 'Home' }
    ),

    [CLIENT_PATHS.SELECT_PARTNER_PAGE]: new PageMetadata(
        SelectPartnerPage,
        CLIENT_PATHS.SELECT_PARTNER_PAGE,
        FEATURES.VIEW_REPORTS,
        { menuIcon: 'Bill', deprecatedAuthorizations: {isReportPage: true} }
    ),
    [CLIENT_PATHS.SELECT_TENANT_PAGE]: new PageMetadata(
        SelectTenantPage,
        CLIENT_PATHS.SELECT_TENANT_PAGE,
        FEATURES.MANAGE_EXTERNAL_USERS,
        { menuIcon: 'UserMultiuser' ,deprecatedAuthorizations: { isAdminPage: true }}
    ),
    [CLIENT_PATHS.SELECT_REPORT_CATEGORY_PAGE]: new PageMetadata(
        SelectReportCategoryPage,
        CLIENT_PATHS.SELECT_REPORT_CATEGORY_PAGE,
        FEATURES.VIEW_REPORTS,
        { deprecatedAuthorizations: {isReportPage: true} }

    ),
    [CLIENT_PATHS.DOWNLOAD_REPORTS_PAGE]: new PageMetadata(
        DownloadReportsPage,
        CLIENT_PATHS.DOWNLOAD_REPORTS_PAGE,
        FEATURES.VIEW_REPORTS,
        { deprecatedAuthorizations: {isReportPage: true} }
    ),

    [CLIENT_PATHS.DASHBOARDS_SELECT_PARTNER_PAGE]: new PageMetadata(
        DashboardSelectPartnerPage,
        CLIENT_PATHS.DASHBOARDS_SELECT_PARTNER_PAGE,
        FEATURES.NO_FEATURE_REQUIRED,
        { menuIcon: 'Information', deprecatedAuthorizations: {isDashboardPage: true} }
    ),
    [CLIENT_PATHS.DASHBOARDS_SELECT_PAGE]: new PageMetadata(
        DashboardSelectionPage,
        CLIENT_PATHS.DASHBOARDS_SELECT_PAGE,
        FEATURES.NO_FEATURE_REQUIRED,
        { deprecatedAuthorizations: {isDashboardPage: true} }
    ),
    [CLIENT_PATHS.THOUGHTSPOT_LIVEBOARD_PAGE]: new PageMetadata(
        ThoughtSpotLiveBoard,
        CLIENT_PATHS.THOUGHTSPOT_LIVEBOARD_PAGE,
        FEATURES.VIEW_TS_DASHBOARDS,
        { deprecatedAuthorizations: {isDashboardPage: true} }
    ),
    [CLIENT_PATHS.MY_ACCOUNT_PAGE]: new PageMetadata(
        MyAccountPage,
        CLIENT_PATHS.MY_ACCOUNT_PAGE,
        FEATURES.NO_FEATURE_REQUIRED,
        { menuIcon: 'User' }
    )
};


const adminPages: PageMetadatas = {
    [CLIENT_PATHS.ADMIN_PAGE]: new PageMetadata(
        AdminPage,
        CLIENT_PATHS.ADMIN_PAGE,
        FEATURES.INTERNAL_ADMIN,
        { menuIcon: 'Wrench', deprecatedAuthorizations: { isAdminPage: true } }
    ),
    [CLIENT_PATHS.MANAGE_PARTNERS_PAGE]: new PageMetadata(
        ManagePartnersPage,
        CLIENT_PATHS.MANAGE_PARTNERS_PAGE,
        FEATURES.MANAGE_PARTNERS,
        { menuIcon: 'Card', deprecatedAuthorizations: { isAdminPage: true } }
    ),
    [CLIENT_PATHS.MANAGE_REPORT_ALERTS_PAGE]: new PageMetadata(
        ManageReportAlertsPage,
        CLIENT_PATHS.MANAGE_REPORT_ALERTS_PAGE,
        FEATURES.MANAGE_REPORT_CATEGORIES,
        { menuIcon: 'ViewGrid', deprecatedAuthorizations: { isAdminPage: true } }
    ),
    [CLIENT_PATHS.CREATE_PARTNER_PAGE]: new PageMetadata(
        CreatePartnerPage,
        CLIENT_PATHS.CREATE_PARTNER_PAGE,
        FEATURES.MANAGE_PARTNERS,
        { deprecatedAuthorizations: { isAdminPage: true } }
    ),
    [CLIENT_PATHS.EDIT_PARTNER_PAGE]: new PageMetadata(
        EditPartnerPage,
        CLIENT_PATHS.EDIT_PARTNER_PAGE,
        FEATURES.MANAGE_PARTNERS,
        { deprecatedAuthorizations: { isAdminPage: true } }
    ),

    [CLIENT_PATHS.MANAGE_REPORT_CATEGORIES_PAGE]: new PageMetadata(
        ManageReportCategoriesPage,
        CLIENT_PATHS.MANAGE_REPORT_CATEGORIES_PAGE,
        FEATURES.MANAGE_REPORT_CATEGORIES,
        { menuIcon: 'ViewGrid', deprecatedAuthorizations: { isAdminPage: true } }
    ),
    [CLIENT_PATHS.CREATE_REPORT_CATEGORY_PAGE]: new PageMetadata(
        CreateReportCategoryPage,
        CLIENT_PATHS.CREATE_REPORT_CATEGORY_PAGE,
        FEATURES.MANAGE_REPORT_CATEGORIES,
        { deprecatedAuthorizations: { isAdminPage: true } }
    ),
    [CLIENT_PATHS.EDIT_REPORT_CATEGORY_PAGE]: new PageMetadata(
        EditReportCategoryPage,
        CLIENT_PATHS.EDIT_REPORT_CATEGORY_PAGE,
        FEATURES.MANAGE_REPORT_CATEGORIES,
        { deprecatedAuthorizations: { isAdminPage: true } }
    ),

    [CLIENT_PATHS.MANAGE_FREQUENCY_TYPES_PAGE]: new PageMetadata(
        ManageFrequencyTypesPage,
        CLIENT_PATHS.MANAGE_FREQUENCY_TYPES_PAGE,
        FEATURES.MANAGE_REPORT_CATEGORIES,
        { menuIcon: 'Calendar', deprecatedAuthorizations: { isAdminPage: true } }
    ),
    [CLIENT_PATHS.CREATE_FREQUENCY_TYPE_PAGE]: new PageMetadata(
        CreateFrequencyTypePage,
        CLIENT_PATHS.CREATE_FREQUENCY_TYPE_PAGE,
        FEATURES.MANAGE_REPORT_CATEGORIES,
        { deprecatedAuthorizations: { isAdminPage: true } }
    ),
    [CLIENT_PATHS.EDIT_FREQUENCY_TYPE_PAGE]: new PageMetadata(
        EditFrequencyTypePage,
        CLIENT_PATHS.EDIT_FREQUENCY_TYPE_PAGE,
        FEATURES.MANAGE_REPORT_CATEGORIES,
        { deprecatedAuthorizations: { isAdminPage: true } }
    ),

    [CLIENT_PATHS.MANAGE_EXTERNAL_USERS_PAGE]: new PageMetadata(
        ManageUsersPage,
        CLIENT_PATHS.MANAGE_EXTERNAL_USERS_PAGE,
        FEATURES.INTERNAL_ADMIN,
        { menuIcon: 'UserMultiuser', deprecatedAuthorizations: { isAdminPage: true } }
    ),
    [CLIENT_PATHS.CREATE_USER_PAGE]: new PageMetadata(
        CreateUserPage,
        CLIENT_PATHS.CREATE_USER_PAGE,
        FEATURES.INTERNAL_ADMIN,
        { deprecatedAuthorizations: { isAdminPage: true } }
    ),
    [CLIENT_PATHS.EDIT_USER_PAGE]: new PageMetadata(
        EditUserPage,
        CLIENT_PATHS.EDIT_USER_PAGE,
        FEATURES.INTERNAL_ADMIN,
        { deprecatedAuthorizations: { isAdminPage: true } }
    ),

    [CLIENT_PATHS.MANAGE_FREQUENTLY_ASKED_QUESTIONS_PAGE]: new PageMetadata(
        ManageFrequentlyAskedQuestionsPage,
        CLIENT_PATHS.MANAGE_FREQUENTLY_ASKED_QUESTIONS_PAGE,
        FEATURES.INTERNAL_ADMIN,
        { menuIcon: 'Question', deprecatedAuthorizations: { isAdminPage: true } }
    ),
    [CLIENT_PATHS.CREATE_FREQUENTLY_ASKED_QUESTION_PAGE]: new PageMetadata(
        CreateFrequentlyAskedQuestionPage,
        CLIENT_PATHS.CREATE_FREQUENTLY_ASKED_QUESTION_PAGE,
        FEATURES.INTERNAL_ADMIN,
        { deprecatedAuthorizations: { isAdminPage: true } }
    ),
    [CLIENT_PATHS.EDIT_FREQUENTLY_ASKED_QUESTION_PAGE]: new PageMetadata(
        EditFrequentlyAskedQuestionPage,
        CLIENT_PATHS.EDIT_FREQUENTLY_ASKED_QUESTION_PAGE,
        FEATURES.INTERNAL_ADMIN,
        { deprecatedAuthorizations: { isAdminPage: true } }
    ),

    [CLIENT_PATHS.MARKETPLACE_PAGE]: new PageMetadata(
        MarketplacePage,
        CLIENT_PATHS.MARKETPLACE_PAGE,
        FEATURES.INTERNAL_ADMIN,
        { deprecatedAuthorizations: { isAdminPage: true } }
    ),

    [CLIENT_PATHS.MANAGE_DASHBOARDS_PAGE]: new PageMetadata(
        ManageDashboardPage,
        CLIENT_PATHS.MANAGE_DASHBOARDS_PAGE,
        FEATURES.MANAGE_DASHBOARDS,
        { menuIcon: 'Information', deprecatedAuthorizations: { isAdminPage: true } }
    ),

    [CLIENT_PATHS.MANAGE_BANNER_PAGE]: new PageMetadata(
        ManageBannerPage,
        CLIENT_PATHS.MANAGE_BANNER_PAGE,
        FEATURES.INTERNAL_ADMIN,
        { menuIcon: 'Bell', deprecatedAuthorizations: { isAdminPage: true } }
    )
};


const partnerManagerPages: PageMetadatas = {
    [CLIENT_PATHS.MANAGE_SAAS_USERS_PAGE]: new PageMetadata(
        ManageSaasUsersPage,
        CLIENT_PATHS.MANAGE_SAAS_USERS_PAGE,
        FEATURES.MANAGE_EXTERNAL_USERS,
        { menuIcon: 'UserMultiuser' }
    ),
    [CLIENT_PATHS.MANAGE_SAAS_USER_INVITATIONS_PAGE]: new PageMetadata(
        ManageSaasUserInvitationsPage,
        CLIENT_PATHS.MANAGE_SAAS_USER_INVITATIONS_PAGE,
        FEATURES.MANAGE_EXTERNAL_USERS
    ),
    [CLIENT_PATHS.INVITE_SAAS_USER_PAGE]: new PageMetadata(
        InviteSaasUserPage,
        CLIENT_PATHS.INVITE_SAAS_USER_PAGE,
        FEATURES.MANAGE_EXTERNAL_USERS
    ),
    [CLIENT_PATHS.EDIT_SAAS_USER_PAGE]: new PageMetadata(
        EditSaasUserPage,
        CLIENT_PATHS.EDIT_SAAS_USER_PAGE,
        FEATURES.MANAGE_EXTERNAL_USERS
    )
};


const marketingPages: PageMetadatas = {
    [CLIENT_PATHS.MARKETING_SELECT_PARTNER_PAGE]: new PageMetadata(
        MarketingSelectPartnerPage,
        CLIENT_PATHS.MARKETING_SELECT_PARTNER_PAGE,
        FEATURES.MANAGE_MARKETING_CONTENT,
        { menuIcon: 'Megaphone', deprecatedAuthorizations: { isAdminPage: true } }
    ),
    [CLIENT_PATHS.MANAGE_MARKETING_CONTENT_PAGE]: new PageMetadata(
        ManageMarketingContentPage,
        CLIENT_PATHS.MANAGE_MARKETING_CONTENT_PAGE,
        FEATURES.MANAGE_MARKETING_CONTENT
    ),

};


// TODO: FIGURE OUT HOW TO HANDLE THESE IN NEW PARADIGM
const operationPages: PageMetadatas = {
    [CLIENT_PATHS.SELECT_OPERATIONAL_FORM_PAGE]: new PageMetadata(
        SelectOperationalFormPage,
        CLIENT_PATHS.SELECT_OPERATIONAL_FORM_PAGE,
        FEATURES.UNKNOWN_FEATURE_REQUIRED,
        { menuIcon: 'MailInbox', deprecatedAuthorizations: { isOperationPage: true } }
    ),
    [CLIENT_PATHS.CUSTOMER_INVOICE_FORM_PAGE]: new PageMetadata(
        CustomerInvoiceFormPage,
        CLIENT_PATHS.CUSTOMER_INVOICE_FORM_PAGE,
        FEATURES.UNKNOWN_FEATURE_REQUIRED,
        { deprecatedAuthorizations: { isOperationPage: true } }
    ),
    [CLIENT_PATHS.CREATE_CUSTOMER_INVOICE_PAGE]: new PageMetadata(
        CreateCustomerInvoicePage,
        CLIENT_PATHS.CREATE_CUSTOMER_INVOICE_PAGE,
        FEATURES.UNKNOWN_FEATURE_REQUIRED,
        { deprecatedAuthorizations: { isOperationPage: true } }
    ),
    [CLIENT_PATHS.EDIT_CUSTOMER_INVOICE_PAGE]: new PageMetadata(
        EditCustomerInvoicePage,
        CLIENT_PATHS.EDIT_CUSTOMER_INVOICE_PAGE,
        FEATURES.UNKNOWN_FEATURE_REQUIRED,
        { deprecatedAuthorizations: { isOperationPage: true } }
    ),
    [CLIENT_PATHS.WALMART_DISPUTE_FORM_PAGE]: new PageMetadata(
        WalmartDisputesFormPage,
        CLIENT_PATHS.WALMART_DISPUTE_FORM_PAGE,
        FEATURES.UNKNOWN_FEATURE_REQUIRED,
        { deprecatedAuthorizations: { isOperationPage: true } }
    ),
    [CLIENT_PATHS.CREATE_WALMART_DISPUTE_PAGE]: new PageMetadata(
        CreateWalmartDisputesPage,
        CLIENT_PATHS.CREATE_WALMART_DISPUTE_PAGE,
        FEATURES.UNKNOWN_FEATURE_REQUIRED,
        { deprecatedAuthorizations: { isOperationPage: true } }
    ),
    [CLIENT_PATHS.EDIT_WALMART_DISPUTE_PAGE]: new PageMetadata(
        EditWalmartDisputesPage,
        CLIENT_PATHS.EDIT_WALMART_DISPUTE_PAGE,
        FEATURES.UNKNOWN_FEATURE_REQUIRED,
        { deprecatedAuthorizations: { isOperationPage: true } }
    )
};


const supportPages: PageMetadatas = {
    [CLIENT_PATHS.FREQUENTLY_ASKED_QUESTIONS_PAGE]: new PageMetadata(
        FrequentlyAskedQuestionsPage,
        CLIENT_PATHS.FREQUENTLY_ASKED_QUESTIONS_PAGE,
        FEATURES.NO_FEATURE_REQUIRED,
        { menuIcon: 'Question' }
    ),
    [CLIENT_PATHS.REPORT_AN_ISSUE_PAGE]: new PageMetadata(
        ReportAnIssuePage,
        CLIENT_PATHS.REPORT_AN_ISSUE_PAGE,
        FEATURES.NO_FEATURE_REQUIRED,
        { menuIcon: 'MailEnvelope' }
    ),
    [CLIENT_PATHS.LOGOUT]: new PageMetadata(
        LogoutPage,
        CLIENT_PATHS.LOGOUT,
        FEATURES.NO_FEATURE_REQUIRED,
        { menuIcon: 'Lock' }
    ),
    [CLIENT_PATHS.LOGIN]: new PageMetadata(
        LoginPage,
        CLIENT_PATHS.LOGIN,
        FEATURES.NO_FEATURE_REQUIRED
    )
};


const nonAdminPages: PageMetadatas = {
    ...corePages,
    ...supportPages
};

// @ts-ignore
export const pageMetadatas: Required<PageMetadatas> = {
    ...nonAdminPages,
    ...operationPages,
    ...adminPages,
    ...partnerManagerPages,
    ...marketingPages
};
