import React, { useEffect, useState } from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import Tile from 'src/containers/Tile/Tile';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { GetFrequentlyAskedQuestionsResponse } from 'src/types/apiResponse';
import FaqAccordion from 'src/components/FaqAccordion/FaqAccordion';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';

function FrequentlyAskedQuestionsPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetFrequentlyAskedQuestionsResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getFrequentlyAskedQuestions();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    return data && (
        data.faqs.length ? (
                <Tile>
                    <FaqAccordion faqs={data.faqs}/>
                </Tile>
            ) :
            <NoResultsFound/>
    );
}

export default FrequentlyAskedQuestionsPage;
