import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import { navigate } from '@reach/router';
import FormWithReCaptcha from 'src/components/formElements/Form/FormWithReCaptcha';
import TextInput from 'src/components/formElements/TextInput/TextInput';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import FormActionTray from 'src/components/formElements/FormActionTray/FormActionTray';
import Tile from 'src/containers/Tile/Tile';
import DropdownSelect from 'src/components/formElements/DropdownSelect/DropdownSelect';
import { ReportableIssue } from 'src/types/reportableIssue';
import { convertNameAndCodeToLabelAndValue } from 'src/utils/typeConversions';
import { AuthenticatedUser } from 'src/utils/AuthenticatedUser';
import InformationModal from 'src/components/modals/InformationModal/InformationModal';
import FormField from 'src/components/formElements/FormField/FormField';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import 'src/components/ReportAnIssueForm/ReportAnIssueForm.css';
import AlertBanner from '../AlertBanner/AlertBanner';

interface ReportAnIssueFormProps extends FunctionComponentProps {
    reportableIssues: ReportableIssue[];
    user: AuthenticatedUser | null;
}

export interface ReportAnIssueFormFields {
    phone: string;
    reason: string;
    message: string;
}

function ReportAnIssueForm(
    {
        reportableIssues,
        user
    }: ReportAnIssueFormProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string | null>(user ? user.firstName : null);
    const [lastName, setLastName] = useState<string | null>(user ? user.lastName : null);
    const [email, setEmail] = useState<string | null>(user ? user.email : null);
    const [phone, setPhone] = useState<string | null>((user && user.phone) ? user.phone : null);
    const [reason, setReason] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
    const [isFailureModalOpen, setIsFailureModalOpen] = useState<boolean>(false);
    const selectedReason: ReportableIssue | undefined = reportableIssues.find(x => x.code === reason);




    async function handleSubmit(): Promise<void> {
        const trimmedPhone = phone && phone.trim();
        const trimmedReason = reason && reason.trim();
        const trimmedMessage = message && message.trim();

        if (!trimmedReason || !trimmedMessage) {
            setPhone(trimmedPhone || '');
            setReason(trimmedReason || '');
            setMessage(trimmedMessage || '');
            return;
        }

        const issueToReport: ReportAnIssueFormFields = {
            phone: trimmedPhone || '',
            reason: trimmedReason,
            message: trimmedMessage
        };

        await axios
            .post(routeBuilder.api.submitReportAnIssueForm(), issueToReport)
            .then((res) => {
                if(res.status == 200) {
                    setIsSuccessModalOpen(true);
                }
                else {
                    setIsFailureModalOpen(true);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }

    async function handleModalClose(): Promise<void> {
        setIsSuccessModalOpen(false);
        setIsFailureModalOpen(false);
        await navigate(routeBuilder.client.toHomePage());
    }

    return (
        <>
            <Tile>
                <div className="grv-margin__top--medium-1 grv-margin__bottom--large-1 spinner-container">
                    <div className={"grv-spinner " + (isLoading? "grv-spinner--active" : "")} id="buttonSpinnerLight"></div>
                    <FormWithReCaptcha name="reportAnIssue" onSubmit={handleSubmit} isLoading={setIsLoading}>
                        <FormField className="inline-form-fields">
                            <TextInput
                                label="First Name*"
                                value={firstName}
                                onChange={setFirstName}
                                disabled
                            />
                            <TextInput
                                label="Last Name*"
                                value={lastName}
                                onChange={setLastName}
                                disabled
                            />
                        </FormField>
                        <FormField className="inline-form-fields">
                            <TextInput
                                label="Email*"
                                value={email}
                                onChange={setEmail}
                                disabled
                            />
                            <TextInput
                                label="Phone Number"
                                value={phone}
                                onChange={setPhone}
                                disabled={Boolean(user && user.phone)}
                            />
                        </FormField>
                        <DropdownSelect
                            className="report-an-issue"
                            label="Reason*"
                            options={reportableIssues.map(convertNameAndCodeToLabelAndValue)}
                            placeholder="Please select a reason"
                            value={reason}
                            onChange={setReason}
                            required
                        />
                        <TextInput
                            label="Message*"
                            value={message}
                            onChange={setMessage}
                            textarea
                            required
                        />
                        <FormActionTray
                            cancelButtonLinkTo={routeBuilder.client.toHomePage()}
                            submitButtonText="Report an Issue"
                            submitButtonLoading={isLoading}
                        />
                    </FormWithReCaptcha>
                </div>
            </Tile>
            <InformationModal
                description={
                    "Your issue has been reported. Please allow some time for our team to review your issue."
                }
                isOpen={isSuccessModalOpen}
                    title="Your issue has been reported."
                onClose={handleModalClose}
            />
            <InformationModal
                description={
                    'An error occurred while attempting to report your issue.\n' +
                    'Please try again later or contact support.'
                }
                isOpen={isFailureModalOpen}
                title="An error occurred while attempting to report your issue."
                onClose={handleModalClose}
            />
        </>
    );
}

export default ReportAnIssueForm;
