import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import WalmartDisputesForm from 'src/components/WalmartDisputesForm/WalmartDisputesForm';
import { GetWalmartDisputesResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { getFromApi } from 'src/utils/getFromApi';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';

export default function CreateWalmartDisputesPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetWalmartDisputesResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getWalmartDisputes();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    return <WalmartDisputesForm disputes={data?.disputes ?? []} />
}
