import React from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { TableFiltersType } from 'src/types/shared';
import ReportCategoryCard from 'src/components/ReportCategoryCard/ReportCategoryCard';
import LinkedButton from 'src/components/buttons/LinkedButton/LinkedButton';
import { ReportCategory } from 'src/types/reportCategory';
import CenteredWrapper from 'src/containers/CenteredWrapper/CenteredWrapper';
import { Button, Table } from '@c1/gravity-react';
import { Column } from 'src/types/gravity';
import { createFilterableColumn, filterItem } from 'src/utils/tableUtils';
import ActionTray from 'src/containers/ActionTray/ActionTray';

interface ReportCategorySelectionProps extends FunctionComponentProps {
    reportCategories: ReportCategory[];
}

function ReportCategorySelection({ reportCategories }: ReportCategorySelectionProps): FunctionComponentReturnType {
    const [filters, setFilters] = React.useState<TableFiltersType<Object>>({});
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const columns = [
        { title: 'name', dataIndex: 'key' },
    ];

    function createColumns(): Column[] {
        const output: Column[] = columns.map((x): Column => {
            return createFilterableColumn(x.title, x.dataIndex, filters, setFilters);
        });

        output.push({ title: '', dataIndex: 'actions', key: 'actions' });

        return output;
    }

    function createDataSource() {
        return reportCategories
            .map(i => ({
                key: i.name,
                name: i.name,
                actions: (
                    <ActionTray className="reports-table-action-tray"> {
                        <LinkedButton
                            className="grv-margin--tiny"
                            buttonType="progressive"
                            linkTo={i.externalLink ? i.externalLink : routeBuilder.client.toReportSelectionPage({ reportCategoryCode: i.code })}
                            compact
                        >
                            {'View'}
                        </LinkedButton>
                    }
                    </ActionTray>
                )
            }))
            .filter(filterReports);
    }

    function filterReports(item: Object): boolean {
        return filterItem(item, filters);
    }

    return (
        <Table
            columns={createColumns()}
            dataSource={createDataSource()}
        />
    );
}

export default ReportCategorySelection;
