import React from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { List } from '@c1/gravity-react';
import { Spacing } from 'src/types/styles';
import clsx from 'clsx';

interface UnorderedListProps {
    className?: string;
    items: string[];
    marginSize?: Spacing;
}

function UnorderedList({ className, items, marginSize }: UnorderedListProps): FunctionComponentReturnType {
    return (
        <List
            className={clsx(className, marginSize && `grv-margin--${marginSize}`)}
            type="linked"
        >
            {
                items.map((x, index) => {
                    let key = (x !== null) ? x.split(" ").join("") : index;
                    return (
                        <li
                            key={`unordered-list-item-${key}`}
                            className="grv-list__list-item"
                        >
                            {x}
                        </li>
                    );
                })
            }
        </List>
    );
}

export default UnorderedList;
