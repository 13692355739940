import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import Icon from 'src/components/icons/Icon/Icon';
import clsx from 'clsx';
import 'src/components/LoadingIndicator/LoadingIndicator.css';

interface LoadingIndicatorProps extends FunctionComponentProps {
    className?: string;
}

function LoadingIndicator({ className }: LoadingIndicatorProps): FunctionComponentReturnType {
    return (
        <div className={clsx(className, 'loading-indicator')}>
            <Icon
                className="loading-indicator-icon"
                type="HourglassLined"
                role="img"
                alt="Loading, please wait."
            />
        </div>
    );
}

export default LoadingIndicator;
