import React, { useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { GetReportCategoriesAdminResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import ReportCategoryForm from 'src/components/ReportCategoryForm/ReportCategoryForm';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';

function CreateReportCategoryPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetReportCategoriesAdminResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getAllReportCategoriesAdmin();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    return data && <ReportCategoryForm reportCategories={data.categories}/>;
}

export default CreateReportCategoryPage;
