import React from 'react';
import { isMobile } from 'react-device-detect';
import C1Logo from 'src/assets/images/logos/C1_Logo_White_RGB.png';
import Link from 'src/components/Link/Link';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import Icon from 'src/components/icons/Icon/Icon';
import { useNavigationPaneContext } from 'src/contextProviders/NavigationPaneProvider/NavigationPaneContext';
import { useAuthenticationContext } from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import 'src/components/Header/Header.css';
import clsx from 'clsx';

function Header(): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const { user } = useAuthenticationContext();
    const { toggleNavigationPane, toggleMobileMenu, closeMobileMenu } = useNavigationPaneContext();
    const { isNavigationPaneOpen, isMobileMenuOpen } = useNavigationPaneContext();


    let userDisplayName = `${user?.firstName ? user?.firstName : ""} ${user?.lastName ? user?.lastName : ""}`;
    let userInitials = `${user?.firstName ? user?.firstName.charAt(0) : ""}${user?.lastName ? user?.lastName.charAt(0) : ""}`.toLocaleUpperCase();

    return (
        <header>
            <div className={clsx(isMobile ? "header-left-mobile" : "header-left")} id={'copp-button-menu'}>
                {
                    //Menu,button,group banner
                    user && <Icon
                        className="menu-button"
                        type="MenuLined"
                        onClick={isMobile ? toggleMobileMenu : toggleNavigationPane}
                        alt="Menu"
                        titleText={isNavigationPaneOpen && !isMobile || isMobileMenuOpen && isMobile ? "Menu collapse button" : "Menu expand button"}
                    />
                }
            </div>
            <div className={clsx(isMobile ? "header-middle-mobile" : "header-middle")}>
                <Link
                    to={routeBuilder.client.toHomePage()}
                    onClick={closeMobileMenu}
                    className="header-link"
                >
                    <img
                        src={C1Logo}
                        alt="Capital One"
                        height={32}
                        width={89.13}
                    />
                    {
                        !isMobile &&
                        <div className="header-title">
                            {'Partner Portal'}
                        </div>
                    }
                </Link>
            </div>
            <div className="header-right">
                {
                    user && isMobile ? (
                        <Link to={routeBuilder.client.toMyAccountPage()} className="header-myaccount-link">
                            <div className="header-user-name" title={userDisplayName}>
                                {userInitials}
                            </div>
                            <Icon type="UserFilled" />
                        </Link>
                    ) : (
                        <div className="header-user-name" title={userDisplayName}>
                            {userDisplayName}
                        </div>
                    )
                }
            </div>
        </header>
    );
}

export default Header;
