import React, { useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import UserForm from 'src/components/UserForm/UserForm';
import { GetUsersAdminResponse } from 'src/types/apiResponse';
import { User } from 'src/types/user';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';
import Tile from 'src/containers/Tile/Tile';
import Link from 'src/components/Link/Link';
import LinkedButton from 'src/components/buttons/LinkedButton/LinkedButton';

function EditUserPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetUsersAdminResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getAllUsersAdmin();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    let user: User | null = null;
    if (data) {
        user = data.users.find(x => {
            return x.userId === routeBuilder.getUserId();
        }) || null;
    }

    return data &&
        (user ?
            (user.isExternalUser ?
                <UserForm
                    users={data.users}
                    user={user}
                /> :
                <CannotEditInternalUsers />
            ) :
            <UserNotFound />
        );
}

export default EditUserPage;

const CannotEditInternalUsers = () => {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    return (
        <Tile className="cannot-edit-internal-users">
            <h2>{'Internal Users cannot be edited.'}</h2>
            <p>{'If you believe you are receiving this message by mistake, please try reloading the page.'}</p>
            <p>{'If the problem persists, please '}
                <Link to={routeBuilder.client.toReportAnIssuePage()}>
                    {'report an issue'}
                </Link>
                {' with details on how to reproduce this issue.'}
            </p>
            <br />
            <br />
            <LinkedButton
                className="form-go-back-button"
                linkTo={routeBuilder.client.toManageExternalUsersPage()}
                buttonType="regressive"
            >
                {'Go Back'}
            </LinkedButton>
        </Tile>
    )
}

const UserNotFound = () => {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    return (
        <Tile className="user-not-found">
            <h2>{'User ID was not found.'}</h2>
            <p>{'If you believe you are receiving this message by mistake, please try reloading the page.'}</p>
            <p>{'If the problem persists, please '}
                <Link to={routeBuilder.client.toReportAnIssuePage()}>
                    {'report an issue'}
                </Link>
                {' with details on how to reproduce this issue.'}
            </p>
            <br />
            <br />
            <LinkedButton
                className="form-go-back-button"
                linkTo={routeBuilder.client.toManageExternalUsersPage()}
                buttonType="regressive"
            >
                {'Go Back'}
            </LinkedButton>
        </Tile>
    )
}