import React from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import ErrorMessage from 'src/components/errors/ErrorMessage';

function DuplicatePartnerError(): FunctionComponentReturnType {
    return (
        <ErrorMessage>
            {'A partner already exists with this Access Type and Partner Code combination.'}
        </ErrorMessage>
    );
}

export default DuplicatePartnerError;
