import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { ClientPath } from 'src/utils/routing/routes';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Link from 'src/components/Link/Link';
import 'src/components/LinkedStepper/LinkedStepper.css';

export interface LinkedStepperProps extends FunctionComponentProps {
    steps: ClientPath[]
    optionalLabels?: (string | null)[];
}

function LinkedStepper({ steps, optionalLabels }: LinkedStepperProps): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    const activeStep: number = steps.findIndex(path => {
        return routeBuilder.client.getPageNameFromPath(path) === routeBuilder.client.getCurrentPageName();
    });

    return (
        <Stepper activeStep={activeStep} className="linked-stepper">
            {
                steps.map((path, index: number) => {
                    const pageName = routeBuilder.client.getPageNameFromPath(path);
                    const isStepToBeCreatedBeforeCurrentPage = index <= activeStep;
                    const uriFromPath: string | undefined = isStepToBeCreatedBeforeCurrentPage ?
                        routeBuilder.getUriFromPath({ path }) : undefined;
                    const optionalLabel = optionalLabels && optionalLabels[index];

                    return (
                        <Step key={path}>
                            <StepLabel
                                optional={
                                    optionalLabel && (
                                        uriFromPath ?
                                            <Link
                                                to={uriFromPath}
                                                className="step-label-link"
                                            >
                                                {optionalLabel}
                                            </Link> :
                                            optionalLabel
                                    )
                                }
                            >
                                {
                                    isStepToBeCreatedBeforeCurrentPage && uriFromPath ? (
                                            <Link
                                                to={uriFromPath}
                                                className="step-label-link"
                                            >
                                                {pageName}
                                            </Link>
                                        ) :
                                        pageName
                                }
                            </StepLabel>
                        </Step>
                    );
                })
            }
        </Stepper>
    );
}

export default LinkedStepper;
