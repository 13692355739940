import React, { useEffect, useState } from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import {
    AuthenticationContextState,
    useAuthenticationContext
} from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';
import { AxiosError } from 'axios';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { getFromApi } from 'src/utils/getFromApi';

function LogoutPage(): FunctionComponentReturnType {
    const authenticationContext: AuthenticationContextState = useAuthenticationContext();
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.logout();
    function handleSuccess(res: string): void {
        authenticationContext.setUser(null);
        authenticationContext.setIsAuthenticated(false);
        if (res.startsWith("https")) {
            window.location.assign(res);
        }
    }
    useEffect(getFromApi(apiRoute, handleSuccess, setError), [authenticationContext.setUser, authenticationContext.setIsAuthenticated, apiRoute]);

    if (error) {
        throw error;
    }

    return (
        <p>
            {'For your security, please close the browser.'}
        </p>
    );
}

export default LogoutPage;
