import NavMenuItem, { MenuItem, MobileMenuItem } from 'src/components/NavigationPane/NavMenuItem';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { CLIENT_PATHS } from 'src/utils/routing/routes';
import { useAuthenticationContext } from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';
import { AuthenticatedUser } from 'src/utils/AuthenticatedUser';
import 'src/components/NavigationPane/NavMenuItems.css';
import { pageMetadatas } from 'src/utils/routing/pageMetadatas';
import { isMobile } from 'react-device-detect';
import { isUserAuthorizedToSeeMenuItem } from 'src/utils/userAuthorizationUtils';

function getMenuItems(isDesktop: boolean, user: AuthenticatedUser | null): MenuItem[] {
    const coreMenuItems: MenuItem[] = [
        { metadata: pageMetadatas[CLIENT_PATHS.HOME_PAGE] },
        { metadata: pageMetadatas[CLIENT_PATHS.DASHBOARDS_SELECT_PARTNER_PAGE] }
    ];

    const desktopOnlyMenuItems: MenuItem[] = [
        { metadata: pageMetadatas[CLIENT_PATHS.SELECT_PARTNER_PAGE] }
    ];

    const adminMenuItems: MenuItem[] = [
        {
            metadata: pageMetadatas[CLIENT_PATHS.ADMIN_PAGE],
            menuItems: [
                { metadata: pageMetadatas[CLIENT_PATHS.MANAGE_PARTNERS_PAGE] },
                { metadata: pageMetadatas[CLIENT_PATHS.MANAGE_REPORT_ALERTS_PAGE] },
                { metadata: pageMetadatas[CLIENT_PATHS.MANAGE_REPORT_CATEGORIES_PAGE] },
                { metadata: pageMetadatas[CLIENT_PATHS.MANAGE_FREQUENCY_TYPES_PAGE] },
                { metadata: pageMetadatas[CLIENT_PATHS.MANAGE_EXTERNAL_USERS_PAGE] },
                { metadata: pageMetadatas[CLIENT_PATHS.SELECT_TENANT_PAGE] },
                { metadata: pageMetadatas[CLIENT_PATHS.MANAGE_FREQUENTLY_ASKED_QUESTIONS_PAGE] },
                { metadata: pageMetadatas[CLIENT_PATHS.MANAGE_DASHBOARDS_PAGE] },
                { metadata: pageMetadatas[CLIENT_PATHS.MARKETING_SELECT_PARTNER_PAGE] },
                { metadata: pageMetadatas[CLIENT_PATHS.MANAGE_BANNER_PAGE] }
            ]
                .filter((menuItem: MenuItem) => isUserAuthorizedToSeeMenuItem(user, menuItem))
        }
    ];

    const operationsMenuItems: MenuItem[] = [
        { metadata: pageMetadatas[CLIENT_PATHS.SELECT_OPERATIONAL_FORM_PAGE] }
    ];

    const supportMenuItems: MenuItem[] = [
        { metadata: pageMetadatas[CLIENT_PATHS.FREQUENTLY_ASKED_QUESTIONS_PAGE] },
        { metadata: pageMetadatas[CLIENT_PATHS.REPORT_AN_ISSUE_PAGE] },
        { metadata: pageMetadatas[CLIENT_PATHS.MY_ACCOUNT_PAGE] },
        { metadata: pageMetadatas[CLIENT_PATHS.LOGOUT] }
    ];

    return [
        ...coreMenuItems,
        ...(isDesktop ? desktopOnlyMenuItems : []),
        ...(isDesktop ? adminMenuItems : []),
        ...operationsMenuItems,
        ...supportMenuItems
    ]
        .filter((menuItem: MenuItem) => isUserAuthorizedToSeeMenuItem(user, menuItem))
}

export function NavMenuItems(): FunctionComponentReturnType {

    let isDesktop = !isMobile;

    let menuItems = getMenuItems(isDesktop, useAuthenticationContext().user)
        .map(menuItem => {
            return <NavMenuItem key={menuItem.metadata.menuItemName} menuItem={menuItem}/>;
        });

    return (
        <div className="nav-menu-items grv-accordion">
            {menuItems}
        </div>
    );
}

export function MobileMenuItems(): FunctionComponentReturnType {
    let isDesktop = !isMobile;

    let menuItems = getMenuItems(isDesktop, useAuthenticationContext().user)
        .map(menuItem => {
            return <MobileMenuItem
                key={menuItem.metadata.menuItemName}
                menuItem={menuItem}
                isSubMenu={false}
            />;
        });

    return (
        <div className="nav-menu-items grv-accordion mobile-menu">
            {menuItems}
        </div>
    );
}
