import React from 'react';
import { Link as InternalLink } from '@reach/router';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import ExternalLink from 'src/components/Link/ExternalLink';

export type LinkProps = Parameters<InternalLink<any>>[0];

function Link({ to, children, ...otherProps }: LinkProps): FunctionComponentReturnType {
    if (to.startsWith('blob:')) {
        return (
            <a href={to} {...otherProps}>
                {children}
            </a>
        );
    }

    const LinkComponent = to.includes('//') ? ExternalLink : InternalLink;

    return (
        <LinkComponent to={to} {...otherProps}>
            {children}
        </LinkComponent>
    );
}

export default Link;
