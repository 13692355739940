import React, { useState } from 'react';
import PartnerCard from 'src/components/PartnerCard/PartnerCard';
import { Partner, PartnersByAccessTypeCode } from 'src/types/partner';
import TabList from 'src/components/buttons/TabList/TabList';
import { AccessType, AccessTypeCode } from 'src/types/accessType';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import CenteredWrapper from 'src/containers/CenteredWrapper/CenteredWrapper';
import { PathParameters } from 'src/utils/routing/RouteBuilder';

type PartnerLinkClientBuilder = ({
    accessTypeCode,
    partnerCode
}: PathParameters) => string;

interface PartnerSelectionProps extends FunctionComponentProps {
    accessTypes: AccessType[];
    partners: PartnersByAccessTypeCode;
    clientBuilder: PartnerLinkClientBuilder;
}

function PartnerSelection(
    {
        partners,
        accessTypes,
        clientBuilder
    }: PartnerSelectionProps
): FunctionComponentReturnType {
    const [selectedAccessType, setSelectedAccessType] = useState<AccessTypeCode>(accessTypes[0].code);
    const partnersFromSelectedAccessType: Partner[] = (partners && partners[selectedAccessType]) || [];

    function onAccessTypeChange(value: AccessTypeCode): void {
        setSelectedAccessType(value);
    }

    const id = 'partner-cards-wrapper';
    
    return (
        <>
            <TabList
                controlledPanelId={id}
                label="Access Type"
                values={accessTypes}
                onChange={onAccessTypeChange}
                selectedValue={selectedAccessType}
                alignment="center"
            />
            <CenteredWrapper id={id}>
                {
                    partnersFromSelectedAccessType.map((partner, i) => {
                        return <PartnerCard
                            key={i}
                            partner={partner}
                            linkTo={clientBuilder({
                                accessTypeCode: partner.type,
                                partnerCode: partner.code
                            })}
                        />;
                    })
                }
            </CenteredWrapper>
        </>
    );
}

export default PartnerSelection;
