import React, { RefObject } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import ReCAPTCHA from 'react-google-recaptcha';
import clsx from 'clsx';
import Form from 'src/components/formElements/Form/Form';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { ValidateReCaptchaResponse } from 'src/types/apiResponse';
import axios, { AxiosResponse } from 'axios';
import { useEnvironment } from 'src/contextProviders/EnvironmentProvider/EnvironmentContext';

export interface FormWithReCaptchaProps extends FunctionComponentProps {
    className?: string;
    id?: string;
    name: string;
    onSubmit: CallableFunction;
    isLoading?: CallableFunction;
}

function FormWithReCaptcha(
    {
        className,
        id,
        children,
        onSubmit,
        isLoading,
        name
    }: FormWithReCaptchaProps
): FunctionComponentReturnType {
    const { googleRecaptchaPublicKey } = useEnvironment();

    const routeBuilder: RouteBuilder = useRouteBuilder();
    const reCaptchaRef: RefObject<ReCAPTCHA> = React.useRef<ReCAPTCHA>(null);

    function submitWithRecaptcha(){
        if (!reCaptchaRef.current) {
            console.error('reCaptchaRef not initiated');
            return;
        }
        if (!googleRecaptchaPublicKey) {
            console.error('Google reCAPTCHA Site Key missing');
            return;
        }

        isLoading?.(true);
        reCaptchaRef.current.executeAsync().then(async function(reCaptchaWidgetResponse) {
            reCaptchaRef.current.reset();

            if (!reCaptchaWidgetResponse) {
                console.error(
                    'Invalid response from Google reCAPTCHA widget:\n',
                    { reCaptchaWidgetResponse }
                );
                return;
            }

            const url = routeBuilder.api.validateReCaptcha();

            const validateReCaptchaResponse: AxiosResponse<ValidateReCaptchaResponse> =
                await axios.post<ValidateReCaptchaResponse>(
                    url,
                    { response: reCaptchaWidgetResponse }
                );

            if (!validateReCaptchaResponse.data.success) {
                console.error(
                    'Unable to validate Google reCAPTCHA widget response:\n',
                    { validation: validateReCaptchaResponse.data }
                );
                return;
            }

            await onSubmit();
        }).finally(() => isLoading?.(false));
    }

    return (
        <Form onSubmit={submitWithRecaptcha} className={clsx(className)} id={id} name={name}>
            {children || null}
            {
                googleRecaptchaPublicKey &&
                <ReCAPTCHA
                    ref={reCaptchaRef}
                    size="invisible"
                    sitekey={googleRecaptchaPublicKey}
                    badge="bottomright"
                />
            }
        </Form>
    );
}

export default FormWithReCaptcha;
