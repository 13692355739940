import React, { useState } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import AuthenticationContext from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';
import { AuthenticatedUser } from 'src/utils/AuthenticatedUser';

function AuthenticationProvider({ children }: FunctionComponentProps): FunctionComponentReturnType {
    const [user, setUser] = useState<AuthenticatedUser | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    return (
        <AuthenticationContext.Provider
            value={{
                user,
                setUser,
                isAuthenticated,
                setIsAuthenticated
            }}
        >
            {children || null}
        </AuthenticationContext.Provider>
    );
}

export default AuthenticationProvider;
