import React, { ReactElement, useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { Report } from 'src/types/report';
import { formatDate } from 'src/utils/datetime';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { Button, Checkbox, Table } from '@c1/gravity-react';
import { Column } from 'src/types/gravity';
import FormWithReCaptcha from 'src/components/formElements/Form/FormWithReCaptcha';
import fileDownload from 'js-file-download';
import FormActionTray from 'src/components/formElements/FormActionTray/FormActionTray';
import { createSortableColumn } from 'src/utils/tableUtils';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import DeleteButton from 'src/components/buttons/DeleteButton/DeleteButton';
import ConfirmDeleteModal from 'src/components/modals/ConfirmDeleteModal/ConfirmDeleteModal';
import { useAuthenticationContext } from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';
import ViewReportModal from 'src/components/modals/ViewReportModal/ViewReportModal';
import { isReportViewable } from 'src/utils/reportUtils';
import 'src/components/ReportsTable/ReportsTable.css';
import { convertArrayBufferToJson } from 'src/utils/typeConversions';
import { ErrorDetailsResponse } from 'src/types/apiResponse';
import { FEATURES } from 'src/utils/feature';
import Form from '../formElements/Form/Form';

interface ReportsTableProps extends FunctionComponentProps {
    id: string;
    reports: Report[];
    refreshData: () => void;
    handleDownloadError: (msg: string) => void;
}

export interface ReportAsTableDataSource extends Omit<Report, 'reportDate' | 'lastModified'> {
    reportDate: string;
    lastModified: string;
    select: ReactElement;
    actions?: ReactElement;
}

function isReportSelected(report: Report, selectedReports: Report[]): boolean {
    return Boolean(selectedReports.find((aReport: Report) => aReport.id === report.id));
}

function ReportsTable({
    reports,
    id,
    refreshData,
    handleDownloadError
}: ReportsTableProps): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const { user } = useAuthenticationContext();
    const userCanDeleteReports =
        Boolean(user?.isInternalAdmin() || user?.hasAccessToFeature(FEATURES.DELETE_REPORTS));

    const [itemToView, setItemToView] = useState<Report | null>(null);
    const [itemToDelete, setItemToDelete] = useState<Report | null>(null);

    const [isDownloadInProgress, setIsDownloadInProgress] = useState<boolean>(false);
    const [selectedReports, setSelectedReports] = useState<Report[]>([]);
    const [sortBy, setSortBy] = useState<keyof Report | null>(null);
    const [sortDirection, setSortDirection] = useState<number>(0);

    const MAX_NUMBER_OF_REPORTS = 3;
    const isMaxNumberOfReportsSelected = selectedReports.length >= MAX_NUMBER_OF_REPORTS;

    useEffect(() => {
        setSelectedReports((currentSelectedReports: Report[]) => {
            return currentSelectedReports
                .filter((selectedReport: Report) => reports.find((report: Report) => report.id === selectedReport.id));
        });
    }, [reports]);

    async function downloadReports(): Promise<void> {
        if (!selectedReports.length || selectedReports.length > MAX_NUMBER_OF_REPORTS) {
            return;
        }

        setIsDownloadInProgress(true);

        await axios.get<ArrayBuffer>(
            routeBuilder.api.downloadReports({ reportIds: selectedReports.map(x => x.id) }),
            { responseType: 'arraybuffer' }
        )
            .then((res) => {
                fileDownload(res.data, res.headers['content-disposition'].split('=')[1]);
                setIsDownloadInProgress(false);
            })
            .catch((e: AxiosError) => {
                const errorMessage = convertArrayBufferToJson<ErrorDetailsResponse>( e.response?.data ).message;
                handleDownloadError(errorMessage);
                setIsDownloadInProgress(false);
            });
    }

    function createColumns(): Column[] {
        return [
            { title: 'Select', dataIndex: 'select', key: 'select' },
            createSortableColumn('Report Date', 'reportDate', 'reportDate', sortBy, sortDirection, setSortBy, setSortDirection),
            createSortableColumn('File Name', 'fileName', 'fileName', sortBy, sortDirection, setSortBy, setSortDirection),
            createSortableColumn('File Size', 'fileSize', 'rawSize', sortBy, sortDirection, setSortBy, setSortDirection),
            createSortableColumn('Last Modified Date', 'lastModified', 'lastModified', sortBy, sortDirection, setSortBy, setSortDirection),
            { title: '', dataIndex: 'actions', key: 'actions' }
        ];
    }

    function toggleReportSelected(report: Report): void {
        setSelectedReports((prevSelectedReports: Report[]) => (
            isReportSelected(report, prevSelectedReports) ?
                prevSelectedReports.filter(x => x.id !== report.id) :
                prevSelectedReports.concat(report)
        ));
    }

    function createDataSource(): ReportAsTableDataSource[] {
        return reports
            .sort((a: Report, b: Report): number => {
                if (!sortBy) {
                    return 0;
                }

                const fieldA = a[sortBy];
                const fieldB = b[sortBy];

                let diff: number = 0;

                if (typeof (fieldA) === 'number' && typeof (fieldB) === 'number') {
                    diff = fieldA - fieldB;
                }

                if (typeof (fieldA) === 'string' && typeof (fieldB) === 'string') {
                    diff = fieldA.toLocaleLowerCase().localeCompare(fieldB.toLocaleLowerCase());
                }

                return diff * sortDirection;
            })
            .map((report: Report): ReportAsTableDataSource => {
                const isSelected = isReportSelected(report, selectedReports);

                return {
                    ...report,
                    select: (
                        <Checkbox
                            checked={isSelected}
                            label={`Select ${report.fileName}`}
                            onClick={() => toggleReportSelected(report)}
                            disabled={isMaxNumberOfReportsSelected && !isSelected}
                            hideLabel
                        />
                    ),
                    reportDate: formatDate(report.reportDate),
                    lastModified: formatDate(report.lastModified),
                    actions: (
                        <ActionTray className="reports-table-action-tray">
                            {
                                isReportViewable(report) &&
                                <Button
                                    className="grv-margin--tiny"
                                    id={'copp-button-view-report-' + report.fileName}
                                    onClick={(event): void => {
                                        event.preventDefault();
                                        setItemToView(report);
                                    }}
                                    type="progressive"
                                    loading={false}
                                    disabledOnLoading
                                    compact
                                >
                                    {'View'}
                                </Button>
                            }
                            {
                                userCanDeleteReports &&
                                <DeleteButton
                                    onClick={(event): void => {
                                        event.preventDefault();
                                        setItemToDelete(report);
                                    }}
                                    marginSize="tiny"
                                />
                            }
                        </ActionTray>
                    )
                };
            });
    }

    const confirmDeleteModalTitle = 'Confirm Delete Report';

    const confirmDeleteModalDescription = itemToDelete ?
        `Are you sure you want to delete ${itemToDelete.fileName} (${formatDate(itemToDelete.reportDate)}) (${itemToDelete.fileSize})?` :
        '';

    const apiRouteToDelete: string | null = itemToDelete ?
        routeBuilder.api.deleteReport({ reportId: itemToDelete.id }) :
        null;

    return (
        <>
            {
                user.isExternalUser != null && !user.isExternalUser ? (
                    <Form onSubmit={downloadReports} id={id} name="downloadReports">
                        <Table
                            rowKey="id"
                            columns={createColumns()}
                            dataSource={createDataSource()}
                        />
                        <FormActionTray
                            cancelButtonLinkTo={routeBuilder.client.toReportCategorySelectionPage()}
                            submitButtonText="Download"
                            submitButtonLoading={isDownloadInProgress}
                        />
                    </Form>
                ) :
                (
                    <FormWithReCaptcha onSubmit={downloadReports} id={id} name="downloadReports">
                        <Table
                            rowKey="id"
                            columns={createColumns()}
                            dataSource={createDataSource()}
                        />
                        <FormActionTray
                            cancelButtonLinkTo={routeBuilder.client.toReportCategorySelectionPage()}
                            submitButtonText="Download"
                            submitButtonLoading={isDownloadInProgress}
                        />
                    </FormWithReCaptcha>
                )
            }

            {
                itemToView && <ViewReportModal
                    onClose={(): void => setItemToView(null)}
                    report={itemToView}
                />
            }

            <ConfirmDeleteModal
                isOpen={Boolean(itemToDelete)}
                title={confirmDeleteModalTitle}
                description={confirmDeleteModalDescription}
                apiRouteToDelete={apiRouteToDelete}
                onClose={(): void => setItemToDelete(null)}
                onAfterDelete={refreshData}
            />
        </>
    );
}

export default ReportsTable;
