import { ValueOf } from 'ts-essentials';

export class FEATURES {
    static readonly INTERNAL_ADMIN = 'INTERNAL_ADMIN';

    static readonly VIEW_REPORTS = 'VIEW_REPORTS';
    static readonly DELETE_REPORTS = 'DELETE_REPORTS';

    static readonly MANAGE_REPORT_CATEGORIES = 'MANAGE_REPORT_CATEGORIES';

    static readonly VIEW_TS_DASHBOARDS = 'VIEW_TS_DASHBOARDS';
    static readonly VIEW_ALL_DASHBOARDS = 'VIEW_ALL_DASHBOARDS';

    static readonly MANAGE_DASHBOARDS = 'MANAGE_DASHBOARDS';

    static readonly MANAGE_PARTNERS = 'MANAGE_PARTNERS';

    static readonly MANAGE_EXTERNAL_USERS = 'MANAGE_EXTERNAL_USERS';

    static readonly MANAGE_MARKETING_CONTENT = 'MANAGE_MARKETING_CONTENT';

    static readonly NO_FEATURE_REQUIRED = 'NO_FEATURE_REQUIRED';

    // TODO: FIGURE OUT HOW TO HANDLE THESE
    static readonly UNKNOWN_FEATURE_REQUIRED = 'UNKNOWN_FEATURE_REQUIRED';
}

type Features = Omit<typeof FEATURES, 'prototype'>;
export type Feature = ValueOf<Features>;
