import { createContext, useContext } from 'react';
import { AuthenticatedUser } from 'src/utils/AuthenticatedUser';

export interface AuthenticationContextState {
    user: AuthenticatedUser | null;
    setUser: (newUser: AuthenticatedUser | null) => void;
    isAuthenticated: boolean;
    setIsAuthenticated: (newIsAuthenticated: boolean) => void;
}

export function createAuthenticationContextState(
    { user = null, isAuthenticated = false }: Partial<Pick<AuthenticationContextState, 'user' | 'isAuthenticated'>> = {}
): AuthenticationContextState {
    const authenticationContextState: AuthenticationContextState = {
        user: user,
        setUser(newUser: AuthenticatedUser | null): void {
            authenticationContextState.user = newUser;
        },
        isAuthenticated: isAuthenticated,
        setIsAuthenticated(newIsAuthenticated: boolean): void {
            authenticationContextState.isAuthenticated = newIsAuthenticated;
        }
    };

    return authenticationContextState;
}

const AuthenticationContext = createContext<AuthenticationContextState>(createAuthenticationContextState());

export function useAuthenticationContext(): AuthenticationContextState {
    return useContext(AuthenticationContext);
}

export default AuthenticationContext;
