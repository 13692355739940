import React, { MouseEventHandler } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import clsx from 'clsx';
import GravityIcon, { getIconNotAccessibleErrorMessage } from 'src/components/icons/GravityIcon/GravityIcon';
import 'src/components/icons/Icon/Icon.css';

export interface FunctionalIconProps extends FunctionComponentProps {
    className?: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
    type: string;
    alt: string;
    titleText?: string;
}

function FunctionalIcon(
    {
        className,
        onClick,
        type,
        alt,
        titleText
    }: FunctionalIconProps
): FunctionComponentReturnType {
    const role = 'img';
    const trimmedAlt = alt.trim();

    if (!trimmedAlt) {
        throw new Error(getIconNotAccessibleErrorMessage({ type, role, alt: trimmedAlt }));
    }
    return (
        <button
            id={'copp-button-functional-icon-' + titleText}
            className={clsx('icon', 'functional-icon', className)}
            onClick={onClick}
        >
            <GravityIcon type={type} role={role} alt={trimmedAlt} titleText={titleText}/>
        </button>
    );
}

export default FunctionalIcon;
