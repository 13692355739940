import React, { useEffect, useState } from 'react';
import PartnerForm from 'src/components/PartnerForm/PartnerForm';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { GetPartnersAdminResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { Partner } from 'src/types/partner';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';
import { ScheduledReportMonitorDTO } from 'src/types/scheduledReportMonitor';

function EditPartnerPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetPartnersAdminResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getPartnerAdmin(routeBuilder.getPartnerCode());

    function removeReportMonitorState(_reportMonitorId: string) {
        const updatedData = { ...data };
        updatedData.monitors = data.monitors.filter(x => x.reportMonitorId !== _reportMonitorId);
        setData(updatedData);
    }

    function updateReportMonitorState(_reportMonitor: ScheduledReportMonitorDTO) {
        const updatedData = { ...data };
        updatedData.monitors = [
            ...data.monitors.filter(x => x.reportMonitorId !== _reportMonitor.reportMonitorId),
            _reportMonitor ];

        setData(updatedData);
    }

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    let partner: Partner | null = null;
    if (data) {
        partner = data.partners.find((x): boolean => {
            return x.type === routeBuilder.getAccessTypeCode() &&
                x.code === routeBuilder.getPartnerCode();
        }) || null;
    }

    return data && partner && <PartnerForm
        accessTypes={data.accessTypes}
        frequencyTypes={data.frequencyTypes}
        partners={data.partners}
        reportCategories={data.categories}
        partner={partner}
        monitors={data.monitors}
        removeReportMonitorState={removeReportMonitorState}
        updateReportMonitorState={updateReportMonitorState}
    />;
}

export default EditPartnerPage;
