import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { CLIENT_PATHS } from 'src/utils/routing/routes';
import LinkedStepper from 'src/components/LinkedStepper/LinkedStepper';

export interface TenantStepperProps extends FunctionComponentProps {
    selectedTenant?: string | null;
}

function TenantStepper(
    {
        selectedTenant
    }: TenantStepperProps
): FunctionComponentReturnType {
    const selectedLabels: string[] = [];

    if (selectedTenant)
        selectedLabels.push(`${selectedTenant}`);
    return (
        <LinkedStepper
            steps={[
                CLIENT_PATHS.SELECT_TENANT_PAGE,
                CLIENT_PATHS.MANAGE_SAAS_USERS_PAGE,
            ]}
            optionalLabels={selectedLabels}
        />
    );
}

export default TenantStepper;
