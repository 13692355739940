import React from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import 'src/pages/NotFoundPage/NotFoundPage.css';

function NotFoundPage(): FunctionComponentReturnType {
    return (
        <div className="not-found-page">
            <h1>{'Page Not Found'}</h1>
        </div>
    );
}

export default NotFoundPage;
