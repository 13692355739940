



export default function iframeResolutionHelper(
    iframeUrl: string,
    callback: (iframeDoc: any, status: number | null) => void,
    maxTime: number = 10000
): void{

    callbackOnComplete(iframeUrl, callback, 0, maxTime);
}
let checkPeriod = 500;
function callbackOnComplete(iframeUrl: string, callback: ((iframeDoc: any, status: number | null) => void) | null, depth: number, maxTime: number): void {

    var iframe = iframeUrl ? ([...document.getElementsByTagName("iframe")].filter((iframe) =>{
         return  iframe.src.includes(iframeUrl);
     })[0]) : document.getElementsByTagName("iframe")[0];
     iframeUrl = iframe?.src || iframeUrl;
     if(depth > (maxTime/checkPeriod)) {
        console.log("iframeResolutionHelper: check for iframe=\"" + iframeUrl + "\" has timed out");
         callback(null, null);
         return;
     }
    if(!!iframe) {
        let status = null;
        // @ts-ignore
        try {status = iframe?.contentWindow?.performance.getEntriesByType('navigation')[0].responseStatus || null} catch(e) {console.log((e as Error).message);}
        try {
            var iframeDoc = iframe?.contentDocument || iframe?.contentWindow?.document;
            if (
                iframeDoc &&
                iframeDoc.readyState  == 'complete'
            ) {
                console.log("iframeResolutionHelper: iframe=\"" + iframeUrl + "\" has resolved with status=" + status);
                callback(iframeDoc, status);
                return;
            }
        } catch (e) {
            console.log((e as Error).message);
            callback(null, status);
            return;
        }
    }
    window.setTimeout(()=>{callbackOnComplete(iframeUrl, callback, ++depth, maxTime)}, checkPeriod);
}
