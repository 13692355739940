import React from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import SaasUserForm from 'src/components/SaasUserForm/SaasUserForm';

function InviteSaasUserPage(): FunctionComponentReturnType {
    return (
        <SaasUserForm />
    );
}

export default InviteSaasUserPage;
