import React, { ReactElement, ReactNode } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import AdminTable, { ColumnTitleAndDataIndex } from 'src/components/tables/AdminTable/AdminTable';
import { SaasUser, SaasUserRole } from 'src/types/saas';
import UnorderedList from '../UnorderedList/UnorderedList';

export interface SaasUsersAdminTableProps extends FunctionComponentProps {
    users: SaasUser[];
    refreshData: () => void;
    allUserRoles: SaasUserRole[] | null;
    currentPage?: number | null;
    totalPages?: number | null;
    tableUpdate?: (action: string, data: string) => void | null;
}

function UsersAdminTable({ users, refreshData, allUserRoles, currentPage, totalPages, tableUpdate }: SaasUsersAdminTableProps): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    const columns: ColumnTitleAndDataIndex<SaasUser>[] = [
        { title: 'Email', dataIndex: 'emailAddress' },
        { title: 'Roles', dataIndex: 'roles' }
    ];

    function getKey(item: SaasUser): string {
        return `saas-users-admin-table-row-${item.userId}`;
    }

    function getEditButtonLinkTo(item: SaasUser): string {
        return routeBuilder.client.toEditSaasUserPage({ userId: item.userId });
    }

    const modalTitle = 'Confirm Delete User';

    function getModalDescription(item: SaasUser): ReactNode {
        return `Are you sure you want to delete [${item.emailAddress}]?`;
    }

    function getApiRouteToDelete(item: SaasUser): string {
        return routeBuilder.api.deleteSaasUser({
            userId: item.userId,
            email: item.emailAddress
        });
    }

    function getRoleDescriptionsFromRoleIDs(userRoleIds: string[]) {
        if (allUserRoles != null) {
            let roleInfoFromIDs = userRoleIds.map((x) => allUserRoles.find((y) => y.roleId.includes(x)));
            if (roleInfoFromIDs.length > 0) {
                return roleInfoFromIDs.map(z => z ? z.roleDescription : "");
            }
            else {
                return [];
            }
        }
        return [];
    }

    function overrideTableDataSourceMappedFields(item: SaasUser): { roles: ReactElement } {
        return {
            roles: (
                <UnorderedList
                    items={getRoleDescriptionsFromRoleIDs(item.roles.map(x => x.roleId))}
                    marginSize="none"
                />
            )
        };
    }

    return (
        <>
            <AdminTable
                columns={columns}
                items={users}
                getKey={getKey}
                disableEdit={false}
                getEditButtonLinkTo={getEditButtonLinkTo}
                modalTitle={modalTitle}
                getModalDescription={getModalDescription}
                getApiRouteToDelete={getApiRouteToDelete}
                refreshData={refreshData}
                overrideTableDataSourceMappedFields={overrideTableDataSourceMappedFields}
                currentPage={currentPage}
                totalPages={totalPages}
                tableUpdate={tableUpdate}
            />
        </>
    );
}

export default UsersAdminTable;
