import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { CLIENT_PATHS } from 'src/utils/routing/routes';
import 'src/pages/SelectOperationalFormPage/SelectOperationalFormPage.css';
import { Card, Col, Row,  } from '@c1/gravity-react';

interface OperationalForm {
    formName: string,
    formDescription: string,
    formLink: string,
    formSelect: string
}

function SelectOperationalFormPage(): FunctionComponentReturnType {

    const availableForms: OperationalForm[] = [{
        formName: 'Customer Invoices',
        formDescription: 'Generate report on transactions performed by an account during a given time frame for Menards',
        formLink: CLIENT_PATHS.CUSTOMER_INVOICE_FORM_PAGE,
        formSelect: "Select"
    }, {
        formName: 'Walmart Commercial Disputes',
        formDescription: 'Capture dispute-related information to be submitted to Walmart',
        formLink: CLIENT_PATHS.WALMART_DISPUTE_FORM_PAGE,
        formSelect: "Select"
    }];

    return (
        <>
            <p className="operational-form-instruction">Select a form request so we can retrieve the correct form for the next step</p>
                {availableForms.map(form => (
                    <Row className="operational-form-row">
                        <Col>
                            <Card
                                heading={form.formName}
                                href={form.formLink}
                                hrefText={form.formSelect}
                            >
                                {form.formDescription}
                        </Card>
                        </Col>
                    </Row>
                ))}

        </>
    )
}

export default SelectOperationalFormPage;
