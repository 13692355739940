import React, { EffectCallback, useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import { GetReportCategoriesAdminResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import ReportCategoriesAdminTable from 'src/components/ReportCategoriesAdminTable/ReportCategoriesAdminTable';
import AddItemLinkedButton from 'src/components/buttons/AddItemLinkedButton/AddItemLinkedButton';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';

function ManageReportCategoriesPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetReportCategoriesAdminResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getAllReportCategoriesAdmin();

    const refreshData: EffectCallback = getFromApi(apiRoute, setData, setError);
    useEffect(refreshData, [apiRoute]);

    if (error) {
        throw error;
    }

    return (
        <div>
            <ActionTray>
                <AddItemLinkedButton linkTo={routeBuilder.client.toCreateReportCategoryPage()}>
                    {'Add Report Category'}
                </AddItemLinkedButton>
            </ActionTray>
            {
                data && (
                    data.categories.length ?
                        <ReportCategoriesAdminTable reportCategories={data.categories} refreshData={refreshData}/> :
                        <NoResultsFound/>
                )
            }
        </div>
    );
}

export default ManageReportCategoriesPage;
