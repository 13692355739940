import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { Button } from '@c1/gravity-react';
import clsx from 'clsx';

export interface CancelButtonProps extends FunctionComponentProps {
    className?: string;
    onClick: () => void;
}

function CancelButton({ onClick, className }: CancelButtonProps): FunctionComponentReturnType {
    return (
        <Button
            className={clsx(className)}
            type="regressive"
            onClick={onClick}
            id={'copp-button-cancel'}
            compact
        >
            {'Cancel'}
        </Button>
    );
}

export default CancelButton;
