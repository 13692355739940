import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { FrequencyType } from 'src/types/frequencyType';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import AdminTable, { ColumnTitleAndDataIndex } from 'src/components/tables/AdminTable/AdminTable';

export interface FrequencyTypesAdminTableProps extends FunctionComponentProps {
    frequencyTypes: FrequencyType[];
    refreshData: () => void;
}

function FrequencyTypesAdminTable(
    {
        frequencyTypes,
        refreshData
    }: FrequencyTypesAdminTableProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    const columns: ColumnTitleAndDataIndex<FrequencyType>[] = [
        { title: 'Frequency Type Code', dataIndex: 'code' },
        { title: 'Frequency Type Name', dataIndex: 'name' }
    ];

    function getKey(item: FrequencyType): string {
        return `frequency-types-admin-table-row-${item.code}`;
    }

    function getEditButtonLinkTo(item: FrequencyType): string {
        return routeBuilder.client.toEditFrequencyTypePage({
            frequencyTypeCode: item.code
        });
    }

    const modalTitle = 'Confirm Delete Frequency Type';

    function getModalDescription(item: FrequencyType): string {
        return `Are you sure you want to delete ${item.name} (${item.code})?`;
    }

    function getApiRouteToDelete(item: FrequencyType): string {
        return routeBuilder.api.deleteFrequencyType({ frequencyTypeCode: item.code });
    }

    return (
        <AdminTable
            columns={columns}
            items={frequencyTypes}
            getKey={getKey}
            getEditButtonLinkTo={getEditButtonLinkTo}
            modalTitle={modalTitle}
            getModalDescription={getModalDescription}
            getApiRouteToDelete={getApiRouteToDelete}
            refreshData={refreshData}
        />
    );
}

export default FrequencyTypesAdminTable;
