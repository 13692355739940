import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { Icon } from '@c1/gravity-react';
import { DecorativeIconProps } from 'src/components/icons/Icon/DecorativeIcon';
import { FunctionalIconProps } from 'src/components/icons/Icon/FunctionalIcon';

export interface GravityIconProps extends FunctionComponentProps {
    type: string;
    role: 'img' | 'presentation';
    alt: string;
    titleText?: string;
}

export function getIconNotAccessibleErrorMessage(
    {
        type,
        role,
        alt
    }: Partial<DecorativeIconProps & FunctionalIconProps>
): string {
    const errorMessage = `Provided role (${role}) and alt (${alt}) for Icon type (${type}).\n` +
        'Please provide appropriate alt text if the icon is not purely decorative ' +
        'or if the role is not \'presentation\'.\n' +
        'See the following resources for more information:\n' +
        'https://www.w3.org/WAI/tutorials/images/decorative/\n' +
        'https://www.w3.org/WAI/tutorials/images/functional/\n' +
        'http://web-accessibility.carnegiemuseums.org/code/svg/';

    console.trace(errorMessage);

    return errorMessage;
}

function GravityIcon({ type, role, alt, titleText }: GravityIconProps): FunctionComponentReturnType {
    const isPurelyDecorative: boolean = role === 'presentation';
    const trimmedAlt = alt.trim();

    if ((!isPurelyDecorative && !trimmedAlt) || (isPurelyDecorative && trimmedAlt)) {
        throw new Error(getIconNotAccessibleErrorMessage({ type, role, alt: trimmedAlt }));
    }

    // @ts-ignore
    return (<Icon type={type} role={role} alt={trimmedAlt} title={titleText} aria-label={titleText}/>);
}

export default GravityIcon;
