import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import XLSX, { WorkBook, WorkSheet } from 'xlsx';
import { useEffect, useState } from 'react';
import TabList from 'src/components/buttons/TabList/TabList';
import { HasNameAndCode } from 'src/types/shared';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import ReportViewerTable from 'src/components/ReportViewer/ReportViewerTable';

interface ReportViewerProps extends FunctionComponentProps {
    data: ArrayBuffer;
}

function makeReportColumns(range?: string): HasNameAndCode<number>[] {
    if (!range) {
        return [];
    }

    const reportColumns: HasNameAndCode<number>[] = [];
    const numberOfCols: number = XLSX.utils.decode_range(range).e.c + 1;

    for (let i: number = 0; i < numberOfCols; i++) {
        reportColumns.push({ name: XLSX.utils.encode_col(i), code: i });
    }

    return reportColumns;
}

function ReportViewer({ data }: ReportViewerProps): FunctionComponentReturnType {
    const [workBook, setWorkBook] = useState<WorkBook | null>(null);
    const [workSheetNames, setWorkSheetNames] = useState<HasNameAndCode<number>[]>([]);
    const [activeWorkSheetCode, setActiveWorkSheetCode] = useState<number>(0);

    useEffect(() => {
        const reader: FileReader = new FileReader();
        const canReadAsBinaryString: boolean = !!reader.readAsBinaryString;

        reader.onload = (e: ProgressEvent<FileReader>): void => {
            if (!e.target) return;

            const wb: WorkBook = XLSX.read(
                e.target.result,
                { type: canReadAsBinaryString ? 'binary' : 'array' }
            );

            setWorkBook(wb);
            setWorkSheetNames(wb.SheetNames.map((name: string, code: number) => ({ name, code })));
        };

        const dataAsBlob: Blob = new Blob(
            [data],
            { type: 'application/octet-stream' }
        );

        if (canReadAsBinaryString) {
            reader.readAsBinaryString(dataAsBlob);
        } else {
            reader.readAsArrayBuffer(dataAsBlob);
        }
    }, [data]);

    if (!workBook || !workSheetNames.length) return <LoadingIndicator/>;

    const activeWorkSheet: WorkSheet = workBook.Sheets[workSheetNames[activeWorkSheetCode].name];

    const id = 'report-viewer-table-wrapper';

    return (
        <div>
            <TabList
                controlledPanelId={id}
                label="Worksheet Name"
                values={workSheetNames}
                onChange={setActiveWorkSheetCode}
                selectedValue={activeWorkSheetCode}
                alignment="left"
            />
            <ReportViewerTable
                id={id}
                data={XLSX.utils.sheet_to_json<string[]>(activeWorkSheet, { header: 1 })}
                reportColumns={makeReportColumns(activeWorkSheet['!ref'])}
            />
        </div>
    );
}

export default ReportViewer;
