import { Alert } from '@c1/gravity-react';
import { FunctionComponentProps, FunctionComponentReturnType } from "src/types/sharedReact";
import 'src/components/AlertBanner/AlertBanner.css';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import { GetBannerResponse } from 'src/types/apiResponse';
import { AxiosError } from 'axios';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { getFromApi } from 'src/utils/getFromApi';
import 'src/components/MaintenanceBanner/MaintenanceBanner.css'
import { Banner } from 'src/types/banner';
import { BannerType } from 'src/types/bannerType';

function MaintenanceBanner(): FunctionComponentReturnType {
    const [banners, setBanners] = useState<Banner[] | null>(null);
    const [bannerTypes, setBannerTypes] = useState<BannerType[] | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const [type, setType] = useState<string | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getCurrentBanner();

    const HOURS_UNTIL_EXPIRY = 24;
    const BANNER_SEEN_PREFIX = "has_seen_";

    useEffect(getFromApi(apiRoute, handleData, setError), [apiRoute]);

    function handleData(data: GetBannerResponse) {
        if (data.banners.length > 0) {
            let bannersSeen = checkBannerVisibilityAndUpdate(data.banners);
            let bannerList: Banner[] = []
            data.banners?.forEach((banner) => {
                if(banner) {
                    banner.isActive = banner?.isActive && bannersSeen?.indexOf(banner?.bannerId) === -1;
                    bannerList.push(banner);
                }

            });
            setBanners(bannerList);
            setBannerTypes(data.bannerTypes);
        }
    }
    let getBannerType = React.useCallback((banner: Banner) => {
        if(bannerTypes) {
            return bannerTypes.filter((type) => {
                return type.bannerCode == banner.bannerType
            })[0].alertCode;
        }
        return "Info";
    },[bannerTypes]);

    let handleClose = React.useCallback((bannerId: string) => {
        return () => {
            console.log("Closing bannerId: ", bannerId);
            let banner = banners.filter((banner) => banner.bannerId === bannerId)[0];
            banner.isActive = false;
            localStorage.setItem(BANNER_SEEN_PREFIX + bannerId, Date.now().toString());
            setBanners([...banners])// force re-render
        }
    }, [banners, setBanners]);
    function checkBannerVisibilityAndUpdate(banners: Banner[]) {
        let output = [];
        let bannersSeen = Object.keys(localStorage).filter((key) => key.includes(BANNER_SEEN_PREFIX));
        for (let i = 0; i < bannersSeen.length; i++) {
                let bannerId = bannersSeen[i].substr(bannersSeen[i].indexOf(BANNER_SEEN_PREFIX) + BANNER_SEEN_PREFIX.length, 36);
            if((Date.now() - localStorage[bannersSeen[i]] > HOURS_UNTIL_EXPIRY * 60 * 60 * 1000) || banners.filter((banner) => banner?.bannerId === bannerId)[0] === undefined) {
                localStorage.removeItem(bannersSeen[i]);
            } else {
                output.push(bannerId)
            }
        }
        return output;
    }

    if (error) {
        throw error;
    }

    return (<div className="banner-alert-container">
        {banners?.length > 0 ? banners?.map((banner) => (<Alert
            type={getBannerType(banner)}
            active={banner.isActive}
            message={banner.bannerMessage}
            onAction={handleClose(banner.bannerId)}
            className={"banner-alert"}
            actionText='Acknowledge'
        />)): null}
        </div>)
}

export default MaintenanceBanner;
