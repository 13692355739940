import { useState, FormEvent, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import Form from 'src/components/formElements/Form/Form';
import TextInput from 'src/components/formElements/TextInput/TextInput';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import Tile from 'src/containers/Tile/Tile';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import _ from 'lodash';
import DropdownSelect from '../formElements/DropdownSelect/DropdownSelect';
import { Banner } from 'src/types/banner';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import { Button, Spinner } from '@c1/gravity-react';
import DeleteButton from '../buttons/DeleteButton/DeleteButton';
import AlertBanner, { AlertBannerType } from '../AlertBanner/AlertBanner';
import ConfirmDeleteModal from '../modals/ConfirmDeleteModal/ConfirmDeleteModal';
import BaseModal from '../modals/BaseModal/BaseModal';
import CancelButton from '../buttons/CancelButton/CancelButton';
import { HasLabelAndValue } from 'src/types/shared';
import { BannerType } from 'src/types/bannerType';
import { convertNameAndCodeToLabelAndValue } from 'src/utils/typeConversions';

interface BannerFormProps extends FunctionComponentProps {
    banner?: Banner;
    bannerTypes?: BannerType[];
    refreshData?: (messageType: AlertBannerType, message: string) => void;
    showMessage?: boolean;
    message?: string;
    messageType?: AlertBannerType;
}

function BannerForm(
    {
        banner,
        bannerTypes,
        refreshData,
        showMessage,
        message,
        messageType
    }: BannerFormProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [bannerMessage, setBannerMessage] = useState<string | null>(banner ? banner.bannerMessage : null);
    const [bannerType, setBannerType] = useState<string | null>(banner ? banner.bannerType : null);
    const [isActive, setIsActive] = useState<boolean | false>(banner ? banner.isActive : false);

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<AlertBannerType | null>(messageType);
    const [alertMessage, setAlertMessage] = useState<string | null>(message);
    const [showAlert, setShowAlert] = useState<boolean | null>(showMessage);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const submitButtonText = isActive ? 'Save Changes' : 'Add Banner';

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    });

    async function handleDelete(): Promise<void> {
        setShowDeleteModal(false);
        if (!banner.bannerId) {
            return;
        }

        const delBanner: Promise<AxiosResponse> =
            axios.put(routeBuilder.api.disableBanner({ bannerId: banner.bannerId }));

        setIsLoading(true);
        await delBanner
            .then(() => {
                let messageType: AlertBannerType = 'success';
                let message = 'The banner was successfully deleted.';
                setTimeout(() => {
                    setIsLoading(false);
                    refreshData(messageType, message);
                }, 3000);
            })
            .catch(() => {
                setIsLoading(false);
                setAlertType("error");
                setAlertMessage("Unable to delete the banner.");
                setShowAlert(true);
            });
    }

    async function handleSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
        const trimmedBannerMessage = bannerMessage?.trim();
        const trimmedBannerType = bannerType?.trim();

        if (!trimmedBannerMessage || !trimmedBannerType) {
            setBannerMessage(trimmedBannerMessage || '');
            setBannerType(trimmedBannerType || '');
            return;
        }

        const newBanner: Banner = {
            bannerId: banner?.bannerId ?? undefined,
            bannerMessage: trimmedBannerMessage,
            bannerType: trimmedBannerType
        }

        const upsert: Promise<AxiosResponse> = banner ?
            axios.put(routeBuilder.api.editBanner({ bannerId: newBanner.bannerId }), newBanner) :
            axios.post(routeBuilder.api.createBanner(), newBanner);

        setIsLoading(true);
        await upsert
            .then(() => {
                let messageType: AlertBannerType = 'success'
                let message: string;
                if (banner) {
                    message = "The banner has been successfully updated.";
                } else {
                    message = "The banner has been successfully created.";
                }
                setTimeout(() => {
                    setIsLoading(false);
                    refreshData(messageType, message);
                }, 3000);
            })
            .catch(() => {
                setIsLoading(false);
                setAlertType("error");
                setAlertMessage("Unable to update banner.")
                setShowAlert(true);
            });
    }

    const bannerTypeOptions: HasLabelAndValue<string>[] =
        bannerTypes.map((type) => {
            return {
                label: _.capitalize(type.bannerCode),
                value: type.bannerCode
            }
        });

    return (
        <Tile>
            <Form onSubmit={handleSubmit} name={_.camelCase(submitButtonText)}>
                <TextInput
                    label="Banner Message"
                    value={bannerMessage}
                    onChange={setBannerMessage}
                    textarea
                    required
                />
                <DropdownSelect
                    label="Banner Type"
                    value={bannerType}
                    onChange={setBannerType}
                    options={bannerTypeOptions}
                    required
                />
                <ActionTray>
                    {isActive && <Button
                        type="destructive"
                        id={'copp-button-destructive-banner'}
                        onClick={() => { setShowDeleteModal(true) }}
                    >
                        Delete
                    </Button>}
                    <Button
                        htmlButtonType="submit"
                        id={'copp-button-submit-banner'}
                        disabledOnLoading
                    >
                        {submitButtonText}
                    </Button>
                </ActionTray>
            </Form>
            <BaseModal
                CloseButtonComponent={CancelButton}
                ConfirmButtonComponent={DeleteButton}
                description={"Are you sure you want to delete this banner?"}
                isOpen={showDeleteModal}
                title={"Delete Banner"}
                onClose={() => { setShowDeleteModal(false) }}
                onConfirm={handleDelete}
            />
            <Spinner active={isLoading} />
            <AlertBanner
                type={alertType}
                message={alertMessage}
                active={showAlert}
            />
        </Tile>
    );
}

export default BannerForm;
