import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { Button } from '@c1/gravity-react';
import clsx from 'clsx';

export interface CloseButtonProps extends FunctionComponentProps {
    className?: string;
    onClick: () => void;
    buttonName? : string;
}

function CloseButton({ onClick, className, buttonName }: CloseButtonProps): FunctionComponentReturnType {
    return (
        <Button
            className={clsx(className)}
            type={'regressive'}
            onClick={onClick}
            id={"close-button"}
            compact
        >
            {buttonName != null ? buttonName : 'Close'}
        </Button>
    );
}

export default CloseButton;
