import React, { ReactNode } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import BaseModal from 'src/components/modals/BaseModal/BaseModal';

interface InformationModalProps extends FunctionComponentProps {
    description: ReactNode;
    isOpen: boolean;
    title: string;
    onClose: () => void;
}

function InformationModal(
    {
        description,
        isOpen,
        title,
        onClose,
        children
    }: InformationModalProps
): FunctionComponentReturnType {
    return (
        <BaseModal
            description={description}
            isOpen={isOpen}
            title={title}
            onClose={onClose}
            children={children}
        />
    );
}

export default InformationModal;
