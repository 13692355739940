import { HasNameAndCode } from 'src/types/shared';
import { Partner } from 'src/types/partner';
import { User } from 'src/types/user';

export function isDuplicateCode(targetCode: string | null, values: HasNameAndCode[]): boolean {
    if (!targetCode) {
        return false;
    }

    return Boolean(
        values.find(x => {
            return x.code.trim().toLowerCase() === targetCode.trim().toLowerCase();
        })
    );
}

export function isDuplicateName(targetName: string | null, values: HasNameAndCode[]): boolean {
    if (!targetName) {
        return false;
    }

    return Boolean(
        values.find(x => {
            return x.name.trim().toLowerCase() === targetName.trim().toLowerCase();
        })
    );
}

export function isDuplicateUser(targetUserId: string | null, users: User[]): boolean {
    if (!targetUserId) {
        return false;
    }

    return Boolean(
        users.find(x => {
            return x.userId.trim().toLowerCase() === targetUserId.trim().toLowerCase();
        })
    );
}

export function isDuplicatePartner(
    targetAccessTypeCode: string | null,
    targetPartnerCode: string | null,
    partners: Partner[]
): boolean {
    if (!targetAccessTypeCode || !targetPartnerCode) {
        return false;
    }

    return Boolean(
        partners.find(x => {
            return (x.code.trim().toLowerCase() === targetPartnerCode.trim().toLowerCase()) &&
                (x.type.trim().toLowerCase() === targetAccessTypeCode.trim().toLowerCase());
        })
    );
}
