import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import clsx from 'clsx';
import GravityIcon, { getIconNotAccessibleErrorMessage } from 'src/components/icons/GravityIcon/GravityIcon';
import 'src/components/icons/Icon/Icon.css';

export interface DecorativeIconProps extends FunctionComponentProps {
    className?: string;
    type: string;
    role?: 'img' | 'presentation';
    alt?: string;
    titleText?: string;
}

function DecorativeIcon({ className, type, role, alt, titleText }: DecorativeIconProps): FunctionComponentReturnType {
    const nullSafeRole = role ? role : 'presentation';
    const nullSafeAlt = alt ? alt.trim() : '';
    const isPurelyDecorative: boolean = nullSafeRole === 'presentation';

    if ((!isPurelyDecorative && !nullSafeAlt) || (isPurelyDecorative && nullSafeAlt)) {
        throw new Error(getIconNotAccessibleErrorMessage({ type, role: nullSafeRole, alt: nullSafeAlt }));
    }

    return (
        <span className={clsx('icon', className)} aria-hidden={isPurelyDecorative ? 'true' : 'false'}>
            <GravityIcon type={type} role={nullSafeRole} alt={nullSafeAlt} titleText={titleText}/>
        </span>
    );
}

export default DecorativeIcon;
