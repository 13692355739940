import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { CLIENT_PATHS } from 'src/utils/routing/routes';
import LinkedStepper from 'src/components/LinkedStepper/LinkedStepper';

export interface ManageMarketingStepperProps extends FunctionComponentProps {
    selectedPartner?: string | null;
}
function ManageMarketingStepper(
    {
        selectedPartner,
    }: ManageMarketingStepperProps
): FunctionComponentReturnType {
    const selectedLabels: string[] = [];

    if (selectedPartner) {
        selectedLabels.push(`(${selectedPartner})`);
    }

    return (
        <LinkedStepper
            steps={[
                CLIENT_PATHS.MARKETING_SELECT_PARTNER_PAGE,
                CLIENT_PATHS.MANAGE_MARKETING_CONTENT_PAGE,
            ]}
            optionalLabels={selectedLabels}
        />
    );
}

export default ManageMarketingStepper;
