import { HasLabelAndValue, HasNameAndCode } from 'src/types/shared';

export function convertNameAndCodeToLabelAndValue<T>(item: HasNameAndCode<T>): HasLabelAndValue<T> {
    return {
        label: item.name,
        value: item.code
    };
}

export function convertLabelAndValueToNameAndCode<T>(item: HasLabelAndValue<T>): HasNameAndCode<T> {
    return {
        name: item.label,
        code: item.value
    };
}

export function convertCurrencyStringToNumber(value: string): number {
    // Converts '$40.29' into 40.29
    return Number(value
        .replace('/$,/g', '')
        .replace(/[^0-9.-]+/g, '')
    );
}

export function convertNumberToCurrencyString(value: number): string {
    // Converts a number into currency string
    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    });
}

export function convertToCurrencyString(value: string): string {
    // Handles any string into proper formatted currency string
    const num = convertCurrencyStringToNumber(value);
    return convertNumberToCurrencyString(num);
}

export function convertArrayBufferToJson<T>(buffer: ArrayBuffer): T {
    const decoder = new TextDecoder('utf-8');
    return JSON.parse(decoder.decode(new Uint8Array(buffer)));
}