import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { Button } from '@c1/gravity-react';
import './TextButton.css';
import clsx from 'clsx';

interface TextButtonProps extends FunctionComponentProps {
    className?: string;
    onClick: () => void;
}

function TextButton({ children, className, onClick }: TextButtonProps): FunctionComponentReturnType {
    return (
        <Button
            type="text"
            className={clsx(className, 'text-button')}
            onClick={onClick}
            id={'copp-button-text'}
        >
            {/*// @ts-ignore*/}
            {children}
        </Button>
    );
}

export default TextButton;
