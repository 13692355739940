import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import TenantSelection from 'src/components/TenantSelection/TenantSelection';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { GetTenantResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import TenantStepper from 'src/components/TenantStepper/TenantStepper';
import { AccessType } from 'src/types/accessType';
import { Partner } from 'src/types/partner';
import { redirectTo } from '@reach/router';
import Link from 'src/components/Link/Link';
import { getFromApi } from 'src/utils/getFromApi';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';
import { navigate } from '@reach/router';

function SelectTenantPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetTenantResponse[] | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getTenants();
    function handleData(data: GetTenantResponse[]): void {
        data.sort((a, b) => {
            return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
        }).map((tenant) => {
            tenant.displayName = tenant.displayName.replace("& Capital One Partner Portal", "").trim();
        });
        if (data.length === 1) {
            navigate(routeBuilder.client.toManageSaasUsersPage({tenantId: data[0].tenantId}))
            return;
        }
        setData(data);
    }

    useEffect(getFromApi(apiRoute, handleData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    return (
        <>
            <TenantStepper/>
            {
                data && (
                    data.length ?
                        <TenantSelection
                            data={data}
                        /> :
                        <NoResultsFound
                            specificError={
                                <p>
                                    {
                                        'This may be because you do not have access to any Tenants. ' +
                                        'If you do not have access and would like to request access, please use the '
                                    }
                                    <Link to={routeBuilder.client.toReportAnIssuePage()}>
                                        {'Report an Issue'}
                                    </Link>
                                    {' form and provide the necessary information.'}
                                </p>
                            }
                        />
                )
            }
        </>
    );
}

export default SelectTenantPage;
