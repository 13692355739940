export interface Column {
    dataIndex: string;
    key: string;
    title: any;
}

export enum GrvTypographyClass {
    textSizeSmall = "grv-text--small",
    textSizeNormal = "grv-text--normal",
    textSizeMedium = "grv-text--medium",
    textSizeLarge = "grv-text--large",
    textSizeXLarge = "grv-text--xlarge",

    // will be deprecated in gravity-core@3.0.0
    headingLarge = "grv-text__heading--large",
    headingMedium = "grv-text__heading--medium",
    headingNormal= "grv-text__heading--normal",


    bodyMedium = "grv-text__body--medium",
    bodyNormal = "grv-text__body--normal",
    bodySmall = "grv-text__body--small",
    bodyTiny = "grv-text__body--tiny",
}
