import React from 'react';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import 'src/pages/NotAuthorizedPage/NotAuthorizedPage.css';

function NotAuthorizedPage(): FunctionComponentReturnType {
    return (
        <div className="not-authorized-page">
            <h2>{'Access to this place or content is restricted.'}</h2>
            <p>
                {'If you think this is a mistake, please contact your administrator or the person who directed you here.'}
            </p>
        </div>
    );
}

export default NotAuthorizedPage;
