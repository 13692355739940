import React, { MouseEvent, useRef, useState } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { HasLabelAndValue } from 'src/types/shared';
import { Checkbox, Tooltip } from '@c1/gravity-react';
import { ReportCategory } from 'src/types/reportCategory';
import { FrequencyType, FrequencyTypeCode } from 'src/types/frequencyType';
import { convertLabelAndValueToNameAndCode } from 'src/utils/typeConversions';
import Icon from 'src/components/icons/Icon/Icon';
import ErrorMessage from 'src/components/errors/ErrorMessage';
import 'src/components/AddReportCategoryCheckboxGroup/AddReportCategoryCheckboxGroup.css';
import Fieldset from 'src/components/formElements/Fieldset/Fieldset';
import FormModal from '../modals/FormModal/FormModal';
import ManageReportAlertsForm from '../ManageReportAlertsForm/ManageReportAlertsForm';
import { ScheduledReportMonitorDTO } from 'src/types/scheduledReportMonitor';
import UpdateDeleteModal from '../modals/UpdateDeleteModal/UpdateDeleteModal';

export interface AddReportCategoryCheckboxGroupProps extends FunctionComponentProps {
    reportCategory: ReportCategory;
    monitor?: ScheduledReportMonitorDTO | null;
    addMonitorToCreate?: (monitor: ScheduledReportMonitorDTO) => void;
    addMonitorToUpdate?: (monitor: ScheduledReportMonitorDTO) => void;
    addMonitorToDelete?: (monitor: ScheduledReportMonitorDTO) => void;
    options: HasLabelAndValue<FrequencyTypeCode>[];
    onChange: (newValue: ReportCategory) => void;
    onRemove: (reportCategoryCode: string) => void;
    isExternal: boolean;
}

function AddReportCategoryCheckboxGroup(
    {
        options,
        reportCategory,
        monitor,
        addMonitorToCreate,
        addMonitorToUpdate,
        addMonitorToDelete,
        onChange,
        onRemove,
        isExternal
    }: AddReportCategoryCheckboxGroupProps
): FunctionComponentReturnType {
    function isChecked(optionValue: string): boolean {
        return Boolean(
            reportCategory
                .frequencyTypes
                .find((x: FrequencyType): boolean => x.code === optionValue)
        );
    }

    function handleChange(frequencyType: HasLabelAndValue<FrequencyTypeCode>): void {
        onChange(
            reportCategoryHasFrequencyType(frequencyType) ?
                getReportCategoryWithoutFrequencyType(frequencyType) :
                getReportCategoryWithFrequencyType(frequencyType)
        );
    }

    function handleRemoveReportClick(event: MouseEvent<HTMLButtonElement>): void {
        event.preventDefault();
        onRemove(reportCategory.code);
    }

    function reportCategoryHasFrequencyType(frequencyType: HasLabelAndValue<FrequencyTypeCode>): boolean {
        return Boolean(
            reportCategory
                .frequencyTypes
                .find((x: FrequencyType): boolean => x.code === frequencyType.value)
        );
    }

    function getReportCategoryWithoutFrequencyType(frequencyType: HasLabelAndValue<FrequencyTypeCode>): ReportCategory {
        return {
            ...reportCategory,
            frequencyTypes: reportCategory.frequencyTypes
                .filter((x: FrequencyType): boolean => x.code !== frequencyType.value)
        };
    }

    function getReportCategoryWithFrequencyType(frequencyType: HasLabelAndValue<FrequencyTypeCode>): ReportCategory {
        return {
            ...reportCategory,
            frequencyTypes: reportCategory.frequencyTypes
                .concat(convertLabelAndValueToNameAndCode(frequencyType))
        };
    }

    function handleShowReportAlertsModal(event: MouseEvent<HTMLButtonElement>, _isNewReportAlert: boolean) {
        event.preventDefault();
        setIsNewReportAlert(_isNewReportAlert);
        setShowReportAlertsModal(true);
    }

    function handleCancelReportAlertsModal() {
        setShowReportAlertsModal(false);
    }

    function handleSubmitReportAlertsModal() {
        setScheduledReportMonitorExists(true);
        setShowReportAlertsModal(false);
    }

    async function handleDeleteReportAlertsModal(_reportMonitorId: string) {
        const monitorToDelete: ScheduledReportMonitorDTO = {
            reportMonitorId: _reportMonitorId,
            partnerCode: '',
            categoryCode: '',
            frequencyDay: '',
            frequencyNumber: '',
            frequencyTypeCode: ''
        };
        addMonitorToDelete(monitorToDelete);
        setShowReportAlertsModal(false);
        setScheduledReportMonitorExists(false);
    }

    const [isNewReportAlert, setIsNewReportAlert] = useState<boolean>(false);
    const [showReportAlertsModal, setShowReportAlertsModal] = useState<boolean>(false);
    const [scheduledReportMonitorExists, setScheduledReportMonitorExists] = useState<boolean>(monitor !== null);

    return (
        <div className="checkbox-group">
            <Fieldset
                className="checkbox-group-fieldset"
                legendText={reportCategory.name}
                displayLegendAsLabel
            >
                <div className="checkbox-tray">
                    {
                        isExternal && (
                            scheduledReportMonitorExists ?
                                <div className="manage-report-category-alerts">
                                    <Tooltip
                                        content={`Update Alert for ${reportCategory.name}`}
                                        placement="top-left"
                                    >
                                        <Icon
                                            type="BellFilled"
                                            onClick={e => handleShowReportAlertsModal(e, false)}
                                            alt={`Update Alert for ${reportCategory.name}`}
                                        />
                                    </Tooltip>
                                </div>
                                :
                                <div className="manage-report-category-alerts">
                                    <Tooltip
                                        content={`Create Alert for ${reportCategory.name}`}
                                        placement="top-left"
                                    >
                                        <Icon
                                            type="BellLined"
                                            onClick={e => handleShowReportAlertsModal(e, true)}
                                            alt={`Create Alert for ${reportCategory.name}`}
                                        />
                                    </Tooltip>
                                </div>
                        )
                    }
                    {
                        options.map((option: HasLabelAndValue<FrequencyTypeCode>) => (
                            <Checkbox
                                key={`${option.label}-${option.value}`}
                                name={`add-report-category-checkbox-group-${reportCategory.code}`}
                                label={option.label}
                                value={option.value}
                                checked={isChecked(option.value)}
                                onChange={() => handleChange(option)}
                            />
                        ))
                    }
                    <div className="remove-report-category-icon">
                        <Icon
                            type="MinusFilled"
                            onClick={handleRemoveReportClick}
                            alt={`Remove Report Category ${reportCategory.name} (${reportCategory.code})`}
                        />
                    </div>
                </div>
            </Fieldset>
            {
                !reportCategory.frequencyTypes.length &&
                <ErrorMessage>
                    {'At least one option must be selected.'}
                </ErrorMessage>
            }

            {
                isNewReportAlert ?
                    <FormModal
                        active={showReportAlertsModal}
                        title={`Create Report Alert`}
                        formId={`report-alerts-form-manage-${reportCategory.code}`}
                        hideCancel
                        onCancel={handleCancelReportAlertsModal}
                    >
                        {
                            showReportAlertsModal && <ManageReportAlertsForm
                                formId={`report-alerts-form-manage-${reportCategory.code}`}
                                reportCategory={reportCategory}
                                isNew={isNewReportAlert}
                                monitor={monitor}
                                addMonitorToCreate={addMonitorToCreate}
                                addMonitorToUpdate={addMonitorToUpdate}
                                ownerEmails={reportCategory.ownerEmails}
                                onSuccess={handleSubmitReportAlertsModal}
                            />
                        }
                    </FormModal> :
                    <UpdateDeleteModal
                        active={showReportAlertsModal}
                        title={`Update Report Alert`}
                        formId={`report-alerts-form-manage-${reportCategory.code}`}
                        onCancel={handleCancelReportAlertsModal}
                        onDelete={() => handleDeleteReportAlertsModal(monitor.reportMonitorId)}
                        deleteIsLoading={false}
                    >
                        {
                            showReportAlertsModal && <ManageReportAlertsForm
                                formId={`report-alerts-form-manage-${reportCategory.code}`}
                                reportCategory={reportCategory}
                                isNew={isNewReportAlert}
                                monitor={monitor}
                                addMonitorToCreate={addMonitorToCreate}
                                addMonitorToUpdate={addMonitorToUpdate}
                                ownerEmails={reportCategory.ownerEmails}
                                onSuccess={handleSubmitReportAlertsModal}
                            />
                        }
                    </UpdateDeleteModal>
            }
        </div>
    );
}

export default AddReportCategoryCheckboxGroup;
