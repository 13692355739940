import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { CLIENT_PATHS } from 'src/utils/routing/routes';
import LinkedStepper from 'src/components/LinkedStepper/LinkedStepper';

export interface ReportStepperProps extends FunctionComponentProps {
    selectedAccessType?: string | null;
    selectedPartner?: string | null;
    selectedReportCategory?: string | null;
}

function ReportStepper(
    {
        selectedAccessType,
        selectedPartner,
        selectedReportCategory
    }: ReportStepperProps
): FunctionComponentReturnType {
    const selectedLabels: string[] = [];

    if (selectedPartner && selectedAccessType) {
        selectedLabels.push(`${selectedPartner} (${selectedAccessType})`);
    } else if (selectedPartner) {
        selectedLabels.push(selectedPartner);
    }

    if (selectedPartner && selectedReportCategory) {
        selectedLabels.push(selectedReportCategory);
    }

    return (
        <LinkedStepper
            steps={[
                CLIENT_PATHS.SELECT_PARTNER_PAGE,
                CLIENT_PATHS.SELECT_REPORT_CATEGORY_PAGE,
                CLIENT_PATHS.DOWNLOAD_REPORTS_PAGE,
            ]}
            optionalLabels={selectedLabels}
        />
    );
}

export default ReportStepper;
