import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import DashboardStepper from 'src/components/DashboardStepper/DashboardStepper';
import Link from 'src/components/Link/Link';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';
import PartnerSelection from 'src/components/PartnerSelection/PartnerSelection';
import { AccessType } from 'src/types/accessType';
import { GetPartnersResponse } from 'src/types/apiResponse';
import { Partner } from 'src/types/partner';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { getFromApi } from 'src/utils/getFromApi';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { useAuthenticationContext } from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';

function DashboardSelectPartnerPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetPartnersResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const [hasCheckedAuthentication, setHasCheckedAuthentication] = useState<boolean>(false);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const { user } = useAuthenticationContext();
    useEffect(()=> {
        if(user != null && user.partnerAccess != null) {
            const accessTypes: AccessType[] = user.partnerAccess.accessTypes;
            const partner: Partner[] | undefined | false = accessTypes.length === 1 && user.partnerAccess.partners[accessTypes[0].code];
            // If user only has access to one partner, redirect to that partner's dashboard
            if (accessTypes.length === 1 && partner && partner.length === 1) {
                window.location.replace(routeBuilder.client.toDashboardSelectionPage({
                    accessTypeCode: accessTypes[0].code,
                    partnerCode: partner[0].code
                }));
                return;
            } else {
                setData(user.partnerAccess);
                setHasCheckedAuthentication(true);
            }
        }

    }, [user])

    if (error) throw error;

    return hasCheckedAuthentication && (
        <>
            <DashboardStepper/>
            {
                user.partnerAccess && (
                    user.partnerAccess.accessTypes.length ?
                        <PartnerSelection
                            accessTypes={user.partnerAccess.accessTypes}
                            partners={user.partnerAccess.partners}
                            clientBuilder={routeBuilder.client.toDashboardSelectionPage}
                        /> :
                        <NoResultsFound
                            specificError={
                                <p>
                                    {
                                        'This may be because you do not have access to any partners. ' +
                                        'If you do not have access and would like to request access, please use the '
                                    }
                                    <Link to={routeBuilder.client.toReportAnIssuePage()}>
                                        {'Report an Issue'}
                                    </Link>
                                    {' form and provide the necessary information.'}
                                </p>
                            }
                        />
                )
            }
        </>
    );
}

export default DashboardSelectPartnerPage;
