import React from 'react';
import { Report } from 'src/types/report';
import { formatDate, parseDate } from 'src/utils/datetime';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { Datepicker } from '@c1/gravity-react';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import Icon from 'src/components/icons/Icon/Icon';
import 'src/components/ReportsDateSelector/ReportsDateSingleSelector/ReportsDateSingleSelector.css';

interface ReportsDateSingleSelectorProps extends FunctionComponentProps {
    className?: string;
    label: string;
    value: DateTime;
    onChange: (newDate: DateTime) => void;
    reports: Report[];
}

function ReportsDateSingleSelector(
    {
        className,
        label,
        value,
        onChange,
        reports
    }: ReportsDateSingleSelectorProps
): FunctionComponentReturnType {
    const reportDates: number[] = reports.map(report => report.reportDate);

    function isUnavailable(date: Date): boolean {
        return !reportDates.includes(parseDate(date).toSeconds());
    }

    return (
        <div className={clsx('date-selector', className)}>
            <Datepicker
                className="date-picker"
                label={label}
                value={new Date(formatDate(value))}
                unavailable={isUnavailable}
                onSelect={(date: Date) => onChange(parseDate(date))}
            />
            <Icon
                type="CalendarLined"
                className="date-selector-calendar-icon"
            />
        </div>
    );
}

export default ReportsDateSingleSelector;
