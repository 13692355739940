import React, { useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import Form from 'src/components/formElements/Form/Form';
import TextInput from 'src/components/formElements/TextInput/TextInput';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { navigate } from '@reach/router';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import FormActionTray from 'src/components/formElements/FormActionTray/FormActionTray';
import Tile from 'src/containers/Tile/Tile';
import { FrequentlyAskedQuestion } from 'src/types/frequentlyAskedQuestion';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import _ from 'lodash';

export interface FrequentlyAskedQuestionFormProps extends FunctionComponentProps {
    frequentlyAskedQuestions: FrequentlyAskedQuestion[];
    frequentlyAskedQuestion?: FrequentlyAskedQuestion;
}

function isDuplicateQuestion(targetQuestion: string | null, frequentlyAskedQuestions: FrequentlyAskedQuestion[]): boolean {
    if (!targetQuestion) {
        return false;
    }

    return Boolean(
        frequentlyAskedQuestions.find(x => {
            return x.question.trim().toLowerCase() === targetQuestion.trim().toLowerCase();
        })
    );
}

function FrequentlyAskedQuestionForm(
    {
        frequentlyAskedQuestions,
        frequentlyAskedQuestion
    }: FrequentlyAskedQuestionFormProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [question, setQuestion] =
        useState<string | null>(frequentlyAskedQuestion ? frequentlyAskedQuestion.question : null);
    const [answer, setAnswer] =
        useState<string | null>(frequentlyAskedQuestion ? frequentlyAskedQuestion.answer : null);

    const submitButtonText = frequentlyAskedQuestion ?
        'Edit Frequently Asked Question' :
        'Add Frequently Asked Question';

    const isDuplicate: boolean = frequentlyAskedQuestion ?
        false :
        isDuplicateQuestion(question, frequentlyAskedQuestions);

    async function handleSubmit(): Promise<void> {
        const trimmedQuestion = question && question.trim();
        const trimmedAnswer = answer && answer.trim();

        if (isDuplicate || !trimmedQuestion || !trimmedAnswer) {
            setQuestion(trimmedQuestion || '');
            setAnswer(trimmedAnswer || '');
            return;
        }

        const newFrequentlyAskedQuestion: Partial<FrequentlyAskedQuestion> = {
            ...frequentlyAskedQuestion,
            question: trimmedQuestion,
            answer: trimmedAnswer,
        };

        const upsert: Promise<AxiosResponse> = frequentlyAskedQuestion ?
            axios.put(routeBuilder.api.editFrequentlyAskedQuestion(), newFrequentlyAskedQuestion) :
            axios.post(routeBuilder.api.createFrequentlyAskedQuestion(), newFrequentlyAskedQuestion);

        await upsert
            .then(() => navigate(routeBuilder.client.toManageFrequentlyAskedQuestionsPage()))
            .catch(console.error);
    }

    return (
        <Tile>
            <Form onSubmit={handleSubmit} name={_.camelCase(submitButtonText)}>
                <TextInput
                    label="Question"
                    value={question}
                    onChange={setQuestion}
                    isDuplicate={isDuplicate}
                    required
                />
                <TextInput
                    label="Answer"
                    value={answer}
                    onChange={setAnswer}
                    textarea
                    required
                />
                <FormActionTray
                    cancelButtonLinkTo={routeBuilder.client.toManageFrequentlyAskedQuestionsPage()}
                    submitButtonText={submitButtonText}
                />
            </Form>
        </Tile>
    );
}

export default FrequentlyAskedQuestionForm;
