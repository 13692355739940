import React, { useEffect, useState, useCallback } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import UserForm from 'src/components/UserForm/UserForm';
import { GetUsersAdminResponse } from 'src/types/apiResponse';
import { User } from 'src/types/user';
import { getFromApi } from 'src/utils/getFromApi';
import axios, { AxiosError } from 'axios';
import LinkedButton from 'src/components/buttons/LinkedButton/LinkedButton';
import { useAuthenticationContext } from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';
import TextInput from 'src/components/formElements/TextInput/TextInput';
import Button from '@c1/gravity-react/lib/Button';
import { Spinner } from '@c1/gravity-react';
import AlertBanner from 'src/components/AlertBanner/AlertBanner';
import 'src/pages/MyAccountPage/MyAccountPage.css';

function MyAccountPage(): FunctionComponentReturnType {
    const [bannerType, setBannerType] = useState<("success" | "error")>("success");
    const [bannerMessage, setBannerMessage] = useState<string>("");
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const [data, setData] = useState<boolean | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<AxiosError | null>(null);
    const [isEditing, setEditing] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const { user } = useAuthenticationContext();
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.updateCurrentSaasUser();



    useEffect(()=>{
        setFirstName(user?.firstName ? user?.firstName : "")
        setLastName(user?.lastName ? user?.lastName : "")
    }, [user, setFirstName, setLastName])

    let reset = useCallback(()=>{
        setFirstName(user?.firstName ? user?.firstName : "")
       setLastName(user?.lastName ? user?.lastName : "")
       setEditing(false)
    }, [user, setFirstName, setLastName, setEditing])

    let submitUserEdit = useCallback(()=>{
        setLoading(true);
        axios.post(apiRoute, {firstName, lastName})
        .then(() => {
            setEditing(false);
            setLoading(false);
            setBannerType("success")
            setBannerMessage("Updated");
            setShowBanner(true);
        })
        .catch(e => {
            setBannerType("error")
            setBannerMessage("Failed to update");
            setShowBanner(true);
            setLoading(false);
            console.error(e);
        });
    }, [firstName, lastName, setEditing, setLoading])

    return (
        <>
            <AlertBanner
                type={bannerType}
                message={bannerMessage}
                active={showBanner}
            />
            <div>
                <div className="right-justify">
                    {user?.userId === user?.email ? <Button id={'copp-button-edit'} onClick={()=>{setEditing(true)}} disabled={isEditing}>
                        Edit
                    </Button> : null }
                </div>
            </div>
            <div>
                <div className="inline-form-fields">
                    <TextInput
                        label="First Name"
                        value={firstName}
                        onChange={setFirstName}
                        disabled={!isEditing}
                    />
                    <TextInput
                        label="Last Name"
                        value={lastName}
                        onChange={setLastName}
                        disabled={!isEditing}
                    />
                </div>
                <div className="inline-form-fields">
                    <TextInput
                        label="User Id"
                        value={user?.userId ? user?.userId : ""}
                        onChange={()=>{}}
                        disabled={true}
                    />
                    <TextInput
                        label="Email"
                        value={user?.email ? user?.email : ""}
                        onChange={()=>{}}
                        disabled={true}
                    />
                </div>

                {isEditing?
                <div className="right-justify"><div className="display-flex">
                    <div className="button-margin"><Button id={'copp-button-cancel'} onClick={reset} disabled={!isEditing} className="grv-button--regressive">
                        Cancel
                    </Button></div>
                    <div className="button-margin"><Button id={'copp-button-submit'} onClick={submitUserEdit} disabled={!isEditing}>
                        Submit
                    </Button></div>
                </div></div> : null }
            </div>
            <Spinner active={isLoading} />
        </>
    )
}

export default MyAccountPage;
