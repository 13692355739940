import { Report } from 'src/types/report';
import { DateTime } from 'luxon';

export const STANDARD_DATE_FORMAT: 'MM/dd/yyyy' = 'MM/dd/yyyy';

export function formatDate(date: DateTime | number): string {
    return parseDate(date).toFormat(STANDARD_DATE_FORMAT);
}

export function isReportInDateRange(report: Report, date1: DateTime, date2: DateTime): boolean {
    const isDate1First: boolean = date1.toSeconds() < date2.toSeconds();
    const startDate: DateTime = isDate1First ? date1 : date2;
    const endDate: DateTime = isDate1First ? date2 : date1;

    return report.reportDate >= startDate.toSeconds() && report.reportDate <= endDate.toSeconds();
}

export function parseDate(date: DateTime | Date | string | number | null): DateTime {
    if (!date) {
        return DateTime.local();
    }

    if (DateTime.isDateTime(date)) {
        return date;
    }

    if (date instanceof Date) {
        return DateTime.fromFormat(
            date.toDateString(),
            'EEE MMM dd yyyy',
            { zone: 'UTC' }
        );
    }

    if (typeof date === 'string') {
        return DateTime.fromFormat(
            new Date(date).toDateString(),
            'EEE MMM dd yyyy',
            { zone: 'UTC' }
        );
    }

    return DateTime.fromSeconds(date, { zone: 'UTC' });
}

export function futureDateUnavailable(date: Date): boolean {
    return date.getTime() > Date.now();
}
