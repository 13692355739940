import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import Link from 'src/components/Link/Link';
import React, { ReactElement } from 'react';
import { useNavigationPaneContext } from 'src/contextProviders/NavigationPaneProvider/NavigationPaneContext';
import AccordionItem from '@c1/gravity-react/lib/Accordion/AccordionItem';
import NavMenuItemTitle, { MobileMenuItemTitle } from 'src/components/NavigationPane/NavMenuItemTitle';
import clsx from 'clsx';
import { PageMetadata } from 'src/utils/routing/PageMetadata';

export interface NavMenuItemProps extends FunctionComponentProps {
    menuItem: MenuItem;
    parentMenuItemIcon?: string;
}

export interface MobileMenuItemProps extends FunctionComponentProps {
    menuItem: MenuItem;
    isSubMenu: boolean;
}

export interface MenuItem {
    metadata: PageMetadata;
    menuItems?: MenuItem[];
}

function NavMenuItem({ menuItem, parentMenuItemIcon }: NavMenuItemProps): FunctionComponentReturnType {
    const { activeKeys, toggleActiveKey } = useNavigationPaneContext();

    const menuItemTitle: ReactElement = (
        <NavMenuItemTitle
            menuItem={menuItem}
            parentMenuItemIcon={parentMenuItemIcon}
        />
    );

    const menuItemName: string = menuItem.metadata.menuItemName;

    return menuItem.menuItems ? (
        <AccordionItem
            // @ts-ignore
            title={menuItemTitle}
            className="nav-menu-item"
            active={activeKeys.includes(menuItemName)}
            toggleActive={() => toggleActiveKey(menuItemName)}
            key={menuItemName}
        >
            {
                menuItem.menuItems.map(x => (
                    <NavMenuItem
                        key={x.metadata.menuItemName}
                        menuItem={x}
                        parentMenuItemIcon={menuItem.metadata.menuIcon}
                    />
                ))
            }
        </AccordionItem>
    ) : (
        <Link
            key={menuItemName}
            to={menuItem.metadata.path}
            className="grv-accordion__item nav-menu-item nav-menu-item-link"
            id={'copp-button-side-menu-' + menuItemName.split(' ').join('-')}
        >
            <div className="grv-accordion__title">
                {menuItemTitle}
            </div>
        </Link>
    );
}

export function MobileMenuItem({ menuItem, isSubMenu }: MobileMenuItemProps): FunctionComponentReturnType {
    const { activeKeys, toggleActiveKey, toggleMobileMenu } = useNavigationPaneContext();

    const menuItemTitle: ReactElement = (
        <MobileMenuItemTitle
            menuItem={menuItem}
        />
    );

    const menuItemName: string = menuItem.metadata.menuItemName;

    return menuItem.menuItems ? (
        <AccordionItem
            // @ts-ignore
            title={menuItemTitle}
            className="nav-menu-item"
            active={activeKeys.includes(menuItemName)}
            toggleActive={() => toggleActiveKey(menuItemName)}
            key={menuItemName}
        >
            {
                menuItem.menuItems.map(x => (
                    <MobileMenuItem
                        key={x.metadata.menuItemName}
                        menuItem={x}
                        isSubMenu
                    />
                ))
            }
        </AccordionItem>
    ) : (
        <Link
            key={menuItemName}
            to={menuItem.metadata.path}
            onClick={toggleMobileMenu}
            className={clsx(
                'grv-accordion__item',
                'nav-menu-item-link',
                'nav-menu-item'
            )}
        >
            <div className={clsx('grv-accordion__title', isSubMenu ? 'nav-sub-menu-item' : '')}>
                {menuItemTitle}
            </div>
        </Link>
    );
}


export default NavMenuItem;
