import { Column } from 'src/types/gravity';
import FilterableColumnHeader from 'src/components/tables/FilterableColumnHeader/FilterableColumnHeader';
import React, { Dispatch, SetStateAction } from 'react';
import { TableFiltersType } from 'src/types/shared';
import { Dictionary } from 'ts-essentials';
import SortableColumnHeader from 'src/components/tables/SortableColumnHeader/SortableColumnHeader';
import SortableAndFilterableColumnHeader
    from 'src/components/tables/SortableColumnHeader/SortableAndFilterableColumnHeader';
import { SaasUserRole } from 'src/types/saas';
import PlainColumnHeader from 'src/components/tables/PlainColumnHeader/PlainColumnHeader';

export function createSortableAndFilterableColumn<ItemType extends Dictionary<any>>(
    title: string,
    dataIndex: Exclude<keyof ItemType, number | symbol>,
    sortIndex: Exclude<keyof ItemType, number | symbol>,
    sortBy: Exclude<keyof ItemType, number | symbol> | null,
    sortDirection: number,
    filters: TableFiltersType<ItemType>,
    setSortBy: Dispatch<SetStateAction<Exclude<keyof ItemType, number | symbol> | null>>,
    setSortDirection: Dispatch<SetStateAction<number>>,
    setFilters: Dispatch<SetStateAction<TableFiltersType<ItemType>>>
): Column {
    return {
        title: (
            <SortableAndFilterableColumnHeader
                title={title}
                dataIndex={dataIndex}
                sortIndex={sortIndex}
                sortBy={sortBy}
                sortDirection={sortDirection}
                setSortBy={setSortBy}
                setSortDirection={setSortDirection}
                filters={filters}
                setFilters={setFilters}
            />
        ),
        dataIndex,
        key: dataIndex
    };
}

export function createFilterableColumn<ItemType extends Dictionary<any>>(
    title: string,
    dataIndex: Exclude<keyof ItemType, number | symbol>,
    filters: TableFiltersType<ItemType>,
    setFilters: Dispatch<SetStateAction<TableFiltersType<ItemType>>>
): Column {
    return {
        title: (
            <FilterableColumnHeader
                title={title}
                dataIndex={dataIndex}
                filters={filters}
                setFilters={setFilters}
            />
        ),
        dataIndex,
        key: dataIndex
    };
}

export function createPlainColumn<ItemType extends Dictionary<any>>(
    title: string,
    dataIndex: Exclude<keyof ItemType, number | symbol>
): Column {
    return {
        title: (
            <PlainColumnHeader
                title={title}
                dataIndex={dataIndex}
            />
        ),
        dataIndex,
        key: dataIndex
    }
}

export function filterItem<ItemType extends Dictionary<any>>(
    item: ItemType,
    filters: TableFiltersType<ItemType>
): boolean {
    for (const key in filters) {
        if (!filters.hasOwnProperty(key)) {
            continue;
        }
        // @ts-ignore
        const filter: string | undefined = filters[key];

        if (
            filter &&
            filter.trim() &&
            (
                !item.hasOwnProperty(key) ||
                !item[key] ||
                !itemContainsFilter(item[key], filter)
            )
        ) {
            return false;
        }
    }

    return true;
}

function itemContainsFilter(
    itemValue: any,
    filter: string
): boolean {
    const itemIsString: boolean = typeof itemValue === 'string';
    const trimmedItemValue: string = itemIsString ? itemValue.trim() : itemValue;
    const itemIsNumber: boolean = itemIsString && !Number.isNaN(parseInt(trimmedItemValue));
    const trimmedFilter = filter.trim();
    const itemIsObject: boolean = itemValue instanceof Object;

    if (itemIsObject) {
        let reducedField: string = reduceListForObject(itemValue);
        return Boolean(reducedField.toLowerCase().includes(trimmedFilter.toLowerCase()));
    }
    if (!itemIsNumber) {
        return Boolean(
            trimmedItemValue
                .toLowerCase()
                .includes(trimmedFilter.toLowerCase())
        );
    }

    const filterIsNumber: boolean = !Number.isNaN(parseInt(trimmedFilter));
    if (itemIsNumber && filterIsNumber) {
        return Boolean(trimmedItemValue.includes(trimmedFilter));
    }

    return false;
}

function reduceListForObject(itemValue: any[]): string {
    if (Array.isArray(itemValue)) {
        if (itemValue.length == 0) {
            return "";
        }

        const isSaasUserRole = Object.keys(itemValue[0]).includes("roleDescription")
        if (isSaasUserRole) {
            return itemValue.map(x => (x as SaasUserRole).roleDescription).join(" ");   
        }    
    }
}

export function createSortableColumn<ItemType extends Dictionary<any>>(
    title: string,
    dataIndex: Exclude<keyof ItemType, number | symbol>,
    sortIndex: Exclude<keyof ItemType, number | symbol>,
    sortBy: Exclude<keyof ItemType, number | symbol> | null,
    sortDirection: number,
    setSortBy: Dispatch<SetStateAction<Exclude<keyof ItemType, number | symbol> | null>>,
    setSortDirection: Dispatch<SetStateAction<number>>
): Column {
    return {
        title: (
            <SortableColumnHeader
                title={title}
                dataIndex={dataIndex}
                sortIndex={sortIndex}
                sortBy={sortBy}
                sortDirection={sortDirection}
                setSortBy={setSortBy}
                setSortDirection={setSortDirection}
            />
        ),
        dataIndex,
        key: dataIndex
    };
}
