import React, { useEffect, useRef, useState } from 'react';
import Tile from 'src/containers/Tile/Tile';
import { GrvTypographyClass } from 'src/types/gravity';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { Button, Input, Spinner } from '@c1/gravity-react';
import '../ManageMarketingContentPage/ManageMarketingContentPage.css';
import InformationModal from 'src/components/modals/InformationModal/InformationModal';
import AlertBanner, { AlertBannerType } from 'src/components/AlertBanner/AlertBanner';
import axios, { AxiosError } from 'axios';
import ManageMarketingStepper from 'src/components/ManageMarketingStepper/ManageMarketingStepper';
import { useParams } from '@reach/router';

function ManageMarketingContentPage(): FunctionComponentReturnType {

    const routeBuilder: RouteBuilder = useRouteBuilder();
    const { partnerCode } = useParams();
    const apiLatestMarketing: string = routeBuilder.api.getLatestMarketingImage(partnerCode);
    const [showCurrentMarketingModal, setShowCurrentMarketingModal] = useState<boolean>(false);
    const [showNewMarketingModal, setShowNewMarketingModal] = useState<boolean>(false);
    const [currentMarketingImage, setCurrentMarketingImage] = useState();
    const [selectedFile, setSelectedFile] = useState<File>();
    const inputFileRef = useRef<HTMLInputElement>(null);

    const [bannerType, setBannerType] = useState<AlertBannerType>("success");
    const [bannerMessage, setBannerMessage] = useState<string>("");
    const [showBanner, setShowBanner] = useState<boolean>(false);

    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const openInputFileDialog = () => {
        if (inputFileRef.current != null) {
            inputFileRef.current.click();
        }
    };

    const inputFileOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null) {
            const allowedFileTypes: string[] = [ "jpeg", "jpg", "png" ];
            const fileName = event.target.files[0].name;
            const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);

            if (allowedFileTypes.includes(fileExtension)) {
                setSelectedFile(event.target.files[0]);
            }
            else {
                setSelectedFile(undefined);
                setBannerType("error");
                setBannerMessage(`Invalid file type (${fileExtension}) -- Please upload a PNG image.`);
                setShowBanner(true);
                setTimeout(() => {
                    setShowBanner(false);
                }, 5000);
            }
        }
    };

    const uploadImage = () => {
        if (selectedFile == null) return;
        setIsUploading(true);
        let uploadEndpoint = routeBuilder.api.uploadMarketingImage(partnerCode);

        const formData = new FormData();
        formData.append('File', selectedFile);
        axios.post(uploadEndpoint, formData)
            .then((res) => {
                setIsLoading(true);
                setSelectedFile(undefined);
                setBannerType("success");
                setBannerMessage(`Upload success! Refreshing...`);
                setShowBanner(true);
                setTimeout(() => {
                    setShowBanner(false);
                    setIsLoading(false);
                    window.location.reload();
                }, 3000);
            })
            .catch((e) => {
                setSelectedFile(undefined);
                setBannerType("error");
                setBannerMessage(`Error uploading new Marketing image`);
                setShowBanner(true);
                setTimeout(() => {
                    setShowBanner(false);
                }, 5000);
            })
            .finally(() => {
                setIsUploading(false);
            });
    }

    useEffect(() => {
        setIsLoading(true);
        axios.get(apiLatestMarketing, { responseType: 'blob' })
            .then((res) => {
                setCurrentMarketingImage(res.data);
                setIsLoading(false);
            })
            .catch(async (error: AxiosError) => {
                if (error.response.status === 404) {
                    setBannerType("informational");
                    setBannerMessage(`No Marketing Content has been uploaded yet - Please upload below.`);
                    setShowBanner(true);
                    setIsLoading(false);
                    setTimeout(() => {
                        setShowBanner(false);
                    }, 5000);
                } else {
                    setBannerType("error");
                    setBannerMessage(`Unknown error - Please contact support team.`);
                    setShowBanner(true);
                    setIsLoading(false);
                    setTimeout(() => {
                        setShowBanner(false);
                    }, 5000);
                }
            })
    }, [apiLatestMarketing]);

    return (
        <>
            <ManageMarketingStepper
                selectedPartner={partnerCode}
            />
            <Tile className="manage-marketing-container">
                <Spinner active={isLoading} />
                <i>Preview existing marketing content and upload new material on this page.</i>
                <i>Marketing is shown on the Dashboard Selection page for {partnerCode}</i>
                {
                    currentMarketingImage &&
                    <div className="manage-marketing-preview">
                        <img className="manage-marketing-preview-image" src={URL.createObjectURL(currentMarketingImage)} alt="Current Marketing Promotion" />
                        <p className={GrvTypographyClass.bodySmall}>Current Marketing Promo</p>
                    </div>
                }
                <div className="manage-marketing-action-tray">
                    <div className="manage-marketing-label">
                        <Button id={'copp-button-preview-current'} type="progressive" onClick={() => {setShowCurrentMarketingModal(true)}}>
                            Preview Current
                        </Button>
                    </div>
                    <Button
                        type="action"
                        id={'copp-button-new-file'}
                        onClick={openInputFileDialog}
                    >
                        + New File
                    </Button>
                </div>
                {
                    selectedFile &&
                        <div className="manage-marketing-preview">
                            <img className="manage-marketing-preview-image" src={URL.createObjectURL(selectedFile)} alt="Pending Marketing Promotion" />
                            <p className={GrvTypographyClass.bodySmall}>Preview of Selected Image</p>
                            <div className="manage-marketing-action-tray">
                                <Button
                                    id={'copp-button-cancel'}
                                    type="destructive"
                                    compact
                                    onClick={() => {
                                        setSelectedFile(undefined);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    id={'copp-button-preview'}
                                    type="progressive"
                                    compact
                                    onClick={() => { setShowNewMarketingModal(true) }}
                                >
                                    Preview
                                </Button>
                                <Button
                                    id={'copp-button-upload'}
                                    type="action"
                                    compact
                                    onClick={uploadImage}
                                    loading={isUploading}
                                >
                                    Upload
                                </Button>
                            </div>
                            <InformationModal
                                description=""
                                isOpen={showNewMarketingModal}
                                title=" "
                                onClose={() => setShowNewMarketingModal(false)}
                            >
                                <img src={URL.createObjectURL(selectedFile)} alt="Preview Marketing Promotion" />
                            </InformationModal>
                        </div>

                }
                <AlertBanner
                    type={bannerType}
                    message={bannerMessage}
                    active={showBanner}
                />
                <Input
                    type="file"
                    ref={inputFileRef}
                    onChange={inputFileOnChangeHandler}
                    className="manage-marketing-input-file-hidden"
                />
                {
                    currentMarketingImage &&
                    <InformationModal
                            description=""
                            isOpen={showCurrentMarketingModal}
                            title=" "
                            onClose={() => setShowCurrentMarketingModal(false)}
                        >
                            <img src={URL.createObjectURL(currentMarketingImage)} alt="Latest Marketing Promotion" />
                    </InformationModal>
                }
            </Tile>
        </>
    );
}

export default ManageMarketingContentPage;

/*
    There is limited styling for <Input type="file" /> within Gravity.
    Instead, we are triggering the "Select File" dialog using a button and useRef.
*/
