import { Report } from 'src/types/report';

const VIEWABLE_FILE_TYPES: string[] = [
    'csv',
    'xls',
    'xlsx'
];

export function isReportViewable(report: Report): boolean {
    return VIEWABLE_FILE_TYPES.includes(getFileType(report));
}

function getFileType(report: Report): string {
    return report.fileName.substring(report.fileName.lastIndexOf('.') + 1);
}
