import React from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import DeleteButton from 'src/components/buttons/DeleteButton/DeleteButton';
import 'src/components/tables/MonitorTableActionTray/MonitorTableActionTray.css';

export interface MonitorTableActionTrayProps extends FunctionComponentProps {
    onDelete: () => void;
}

function MonitorTableActionTray(
    {
        onDelete,
    }: MonitorTableActionTrayProps
): FunctionComponentReturnType {

    return (
        <ActionTray className="monitor">
            <DeleteButton
                onClick={onDelete}
                marginSize="tiny"
            />
        </ActionTray>
    );
}

export default MonitorTableActionTray;
