import React, { useEffect, useState } from 'react';
import PartnerForm from 'src/components/PartnerForm/PartnerForm';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { GetPartnersAdminResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';

function CreatePartnerPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetPartnersAdminResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getAllPartnersAdmin();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    return data && <PartnerForm
        accessTypes={data.accessTypes}
        frequencyTypes={data.frequencyTypes}
        partners={data.partners}
        reportCategories={data.categories}
    />;
}

export default CreatePartnerPage;
