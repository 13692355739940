import React, { useState } from 'react';
import PartnerCard from 'src/components/PartnerCard/PartnerCard';
import { Partner, PartnersByAccessTypeCode } from 'src/types/partner';
import TabList from 'src/components/buttons/TabList/TabList';
import { GetTenantResponse } from 'src/types/apiResponse';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import CenteredWrapper from 'src/containers/CenteredWrapper/CenteredWrapper';
import { PathParameters } from 'src/utils/routing/RouteBuilder';
import SelectableCard from 'src/components/SelectableCard/SelectableCard';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { HasNameAndCode } from 'src/types/shared';

type PartnerLinkClientBuilder = ({
    accessTypeCode,
    partnerCode
}: PathParameters) => string;

interface TenantSelectionProps extends FunctionComponentProps {
    data: GetTenantResponse[]
}

function TenantSelection(
    {
        data
    }: TenantSelectionProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const id = 'partner-cards-wrapper';

    return (
        <>
            <CenteredWrapper id={id}>
                {
                    data?.map((tenant, i) => {
                        return <SelectableCard
                           item={{name: tenant.displayName, code: tenant.tenantId}}
                           linkTo={routeBuilder.client.toManageSaasUsersPage({tenantId: tenant.tenantId})}
                           imageUrl={tenant.branding.logoUrl}
                           defaultIconType="HomeLined"
                       />;
                    })
                }
            </CenteredWrapper>
        </>
    );
}

export default TenantSelection;
