import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import PartnerSelection from 'src/components/PartnerSelection/PartnerSelection';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { GetPartnersResponse } from 'src/types/apiResponse';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import ReportStepper from 'src/components/ReportStepper/ReportStepper';
import { AccessType } from 'src/types/accessType';
import { Partner } from 'src/types/partner';
import { redirectTo } from '@reach/router';
import Link from 'src/components/Link/Link';
import { getFromApi } from 'src/utils/getFromApi';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';

function SelectTenantPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetPartnersResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getAllPartners();

    function handleData(data: GetPartnersResponse): void {
        const accessTypes: AccessType[] = data.accessTypes;
        const partner: Partner[] | undefined | false = accessTypes.length === 1 && data.partners[accessTypes[0].code];

        if (accessTypes.length === 1 && partner && partner.length === 1) {
            redirectTo(routeBuilder.client.toReportCategorySelectionPage({
                accessTypeCode: accessTypes[0].code,
                partnerCode: partner[0].code
            }));
            return;
        }

        setData(data);
    }

    useEffect(getFromApi(apiRoute, handleData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    return (
        <>
            <ReportStepper/>
            {
                data && (
                    data.accessTypes.length ?
                        <PartnerSelection
                            accessTypes={data.accessTypes}
                            partners={data.partners}
                            clientBuilder={routeBuilder.client.toReportCategorySelectionPage}
                        /> :
                        <NoResultsFound
                            specificError={
                                <p>
                                    {
                                        'This may be because you do not have access to any partners. ' +
                                        'If you do not have access and would like to request access, please use the '
                                    }
                                    <Link to={routeBuilder.client.toReportAnIssuePage()}>
                                        {'Report an Issue'}
                                    </Link>
                                    {' form and provide the necessary information.'}
                                </p>
                            }
                        />
                )
            }
        </>
    );
}

export default SelectTenantPage;
