import React, { ReactElement } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import clsx from 'clsx';
import { HasLabelAndValue } from 'src/types/shared';
import { Select, Tooltip } from '@c1/gravity-react';
import RequiredFieldError from 'src/components/errors/RequiredFieldError';
import { Falsy } from 'src/types/falsy';
import _ from 'lodash';
import 'src/components/formElements/DropdownSelect/DropdownSelect.css';

interface DropdownSelectProps extends FunctionComponentProps {
    className?: string;
    label: string;
    options: HasLabelAndValue[];
    placeholder?: string;
    value: string | null;
    onChange: (newValue: string) => void;
    errors?: (ReactElement | Falsy)[];
    required?: boolean;
    tooltip?: string;
}

function DropdownSelect(
    {
        className,
        label,
        options,
        placeholder,
        value,
        onChange,
        errors = [],
        required = false,
        tooltip
    }: DropdownSelectProps
): FunctionComponentReturnType {
    const isRequiredFieldError = required && value?.trim() === '';

    const parsedErrors = [
        isRequiredFieldError && <RequiredFieldError key={'required-field-error'}/>,
        ...errors
    ].filter(x => x);

    function handleChange(e: React.ChangeEvent<HTMLSelectElement>): void {
        onChange(e.target.value);
    }

    function createTooltipLabel(): JSX.Element {
        return (
            <Tooltip
                content={tooltip}
                info
                placement="bottom-left"
            >{label}</Tooltip>
        );
    }

    return (
        <>
            <Select
                className={clsx('dropdown-select', className)}
                label={tooltip ? createTooltipLabel() : label}
                name={_.camelCase(label)}
                options={options}
                placeholder={placeholder}
                value={value}
                error={parsedErrors.length ? parsedErrors : null}
                onChange={handleChange}
            />
        </>
    );
}

export default DropdownSelect;
