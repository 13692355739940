import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import ActionTray from 'src/containers/ActionTray/ActionTray';
import LinkedButtonWithIcon from 'src/components/buttons/ButtonWithIcon/LinkedButtonWithIcon';
import AddItemLinkedButton from 'src/components/buttons/AddItemLinkedButton/AddItemLinkedButton';
import React, { useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { User } from 'src/types/user';
import { GetUsersAdminResponse } from 'src/types/apiResponse';

interface ManageUsersPageActionTrayProps extends FunctionComponentProps {
    data: GetUsersAdminResponse | null;
}

function ManageUsersPageActionTray(
    {
        data
    }: ManageUsersPageActionTrayProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [downloadLink, setDownloadLink] = useState<string | null>(null);

    useEffect(() => {
        if (!data) return;

        const dataAsCsvString: string = 'User Id,First Name,Last Name,User Type,Email,Phone\n' +
            data.users
                .map((user: User): string => {
                    return [
                        user.userId,
                        user.firstName,
                        user.lastName,
                        user.isExternalUser ? 'External' : 'Internal',
                        user.email,
                        user.phone ?? ''
                    ].join(',');
                })
                .join('\n');

        const dataAsBlob: Blob = new Blob([dataAsCsvString], { type: 'text/csv' });
        const blobUrl = (window.URL && window.URL.createObjectURL) ?
            window.URL.createObjectURL(dataAsBlob) :
            window.webkitURL.createObjectURL(dataAsBlob);

        setDownloadLink(blobUrl);

        return () => {
            window.URL.revokeObjectURL(blobUrl);
        };
    }, [data]);

    return (
        <ActionTray>
            {
                downloadLink &&
                <LinkedButtonWithIcon
                    iconType="LinkDownloadLined"
                    buttonType="action"
                    download="copp_users.csv"
                    linkTo={downloadLink}
                >
                    {'Download All Users'}
                </LinkedButtonWithIcon>
            }
            <AddItemLinkedButton linkTo={routeBuilder.client.toCreateUserPage()}>
                {'Add External User'}
            </AddItemLinkedButton>
        </ActionTray>
    );
}

export default ManageUsersPageActionTray;
