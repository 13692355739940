import React, { useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { getFromApi } from 'src/utils/getFromApi';
import { AxiosError } from 'axios';
import Tile from 'src/containers/Tile/Tile';
import Link from 'src/components/Link/Link';
import LinkedButton from 'src/components/buttons/LinkedButton/LinkedButton';
import SaasUserForm from 'src/components/SaasUserForm/SaasUserForm';
import { SaasUser } from 'src/types/saas';
import { GetSaasUsersAdminResponse } from 'src/types/apiResponse';

function EditSaasUserPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetSaasUsersAdminResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getAllSaasUsers();

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    if (error) {
        throw error;
    }

    let user: SaasUser | null = null;

    if (data && data.users) {
        user = data.users.find(x => {
            let decodedUserIdFromRoute = decodeURIComponent(routeBuilder.getUserId() ?? '');
            return x.userId === decodedUserIdFromRoute;
        }) || null;
    }

    return data &&
        (user ?
            <SaasUserForm userToEdit={user}/>
        :
            <UserNotFound />
        );
}

export default EditSaasUserPage;

const UserNotFound = () => {
    const routeBuilder: RouteBuilder = useRouteBuilder();

    return (
        <Tile className="user-not-found">
            <h2>{'User ID was not found.'}</h2>
            <p>{'If you believe you are receiving this message by mistake, please try reloading the page.'}</p>
            <p>{'If the problem persists, please '}
                <Link to={routeBuilder.client.toReportAnIssuePage()}>
                    {'report an issue'}
                </Link>
                {' with details on how to reproduce this issue.'}
            </p>
            <br />
            <br />
            <LinkedButton
                className="form-go-back-button"
                linkTo={routeBuilder.client.toManageSaasUsersPage()}
                buttonType="regressive"
            >
                {'Go Back'}
            </LinkedButton>
        </Tile>
    )
}
