import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import TextInput from 'src/components/formElements/TextInput/TextInput';
import { TableFiltersType } from 'src/types/shared';
import { Dictionary } from 'ts-essentials';
import 'src/components/tables/FilterableColumnHeader/FilterableColumnHeader.css';

export interface PlainColumnHeaderProps<ItemType extends Dictionary<any>> extends FunctionComponentProps {
    title: ReactNode;
    dataIndex: Exclude<keyof ItemType, number | symbol>;
}

function PlainColumnHeader<ItemType extends Dictionary<any>>(
    {
        title,
        dataIndex,
    }: PlainColumnHeaderProps<ItemType>
): FunctionComponentReturnType {

    return (
        <>
            {
                (typeof title) === 'string' || (typeof title) === 'number' ?
                    <div>{title}</div> :
                    title
            }
            <TextInput
                className="column-header-filter"
                label={'Filter'}
                value={""}
                placeholder=""
                onChange={()=>{}}
                hideLabel
                disabled
            />
        </>
    );
}

export default PlainColumnHeader;
