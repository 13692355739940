import React, { MouseEventHandler } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { Button } from '@c1/gravity-react';
import clsx from 'clsx';
import { Spacing } from 'src/types/styles';

export interface DeleteButtonProps extends FunctionComponentProps {
    className?: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
    marginSize?: Spacing;
}

function DeleteButton({ onClick, className, marginSize }: DeleteButtonProps): FunctionComponentReturnType {
    return (
        <Button
            className={clsx(
                className,
                marginSize && `grv-margin--${marginSize}`
            )}
            type="destructive"
            onClick={onClick}
            id={'copp-button-delete'}
            compact
        >
            {'Delete'}
        </Button>
    );
}

export default DeleteButton;
