import React, { useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import Form from 'src/components/formElements/Form/Form';
import TextInput from 'src/components/formElements/TextInput/TextInput';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { navigate } from '@reach/router';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { NewUser, User } from 'src/types/user';
import FormActionTray from 'src/components/formElements/FormActionTray/FormActionTray';
import Tile from 'src/containers/Tile/Tile';
import { isDuplicateUser } from 'src/utils/duplicationValidation';
import FormField from 'src/components/formElements/FormField/FormField';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import 'src/components/UserForm/UserForm.css';
import _ from 'lodash';
import { Checkbox } from '@c1/gravity-react';

interface UserFormProps extends FunctionComponentProps {
    users: User[];
    user?: User;
}

function UserForm(
    {
        users,
        user
    }: UserFormProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [userId, setUserId] = useState<string | null>(user ? user.userId : null);
    const [firstName, setFirstName] = useState<string | null>(user ? user.firstName : null);
    const [lastName, setLastName] = useState<string | null>(user ? user.lastName : null);
    const [phone, setPhone] = useState<string | null>(user ? user.phone || null : null);
    const [email, setEmail] = useState<string | null>(user ? user.email : null);

    const submitButtonText = user ? 'Edit User' : 'Add User';

    const isDuplicate: boolean = user ? false : isDuplicateUser(userId, users);

    async function handleSubmit(): Promise<void> {
        const trimmedUserId = userId && userId.trim();
        const trimmedFirstName = firstName && firstName.trim();
        const trimmedLastName = lastName && lastName.trim();
        const trimmedPhone = phone && phone.trim();
        const trimmedEmail = email && email.trim();

        if (isDuplicate || !trimmedUserId || !trimmedFirstName || !trimmedLastName || !trimmedEmail) {
            setUserId(trimmedUserId || '');
            setFirstName(trimmedFirstName || '');
            setLastName(trimmedLastName || '');
            setPhone(trimmedPhone || '');
            setEmail(trimmedEmail || '');
            return;
        }

        const newUser: NewUser = {
            userId: trimmedUserId,
            firstName: trimmedFirstName,
            lastName: trimmedLastName,
            phone: trimmedPhone,
            email: trimmedEmail
        };

        const upsert: Promise<AxiosResponse> = user ?
            axios.put(routeBuilder.api.editUser(), newUser) :
            axios.post(routeBuilder.api.createUser(), newUser);

        await upsert
            .then(() => navigate(routeBuilder.client.toManageExternalUsersPage()))
            .catch(console.error);
    }

    return (
        <Tile>
            <Form onSubmit={handleSubmit} name={_.camelCase(submitButtonText)}>
                <TextInput
                    label="User Id"
                    value={userId}
                    onChange={setUserId}
                    isDuplicate={isDuplicate}
                    disabled={Boolean(user)}
                    required
                />
                <FormField className="inline-form-fields">
                    <TextInput
                        label="First Name"
                        value={firstName}
                        onChange={setFirstName}
                        required
                    />
                    <TextInput
                        label="Last Name"
                        value={lastName}
                        onChange={setLastName}
                        required
                    />
                </FormField>
                <FormField className="inline-form-fields">
                    <TextInput
                        label="Email"
                        value={email}
                        onChange={setEmail}
                        required
                    />
                    <TextInput
                        label="Phone Number"
                        value={phone}
                        onChange={setPhone}
                    />
                </FormField>
                <FormActionTray
                    cancelButtonLinkTo={routeBuilder.client.toManageExternalUsersPage()}
                    submitButtonText={submitButtonText}
                />
            </Form>
        </Tile>
    );
}

export default UserForm;
